<template>
  <section class="bandhooClients">
    <div class="carouselContainer">
      <div class="carouselTrack" ref="track">
        <div
          v-for="(client, index) in clients"
          :key="`original-${index}`"
          class="bandhooClient"
        >
          <span class="statsBorderTop statsBorder"></span>
          <div class="bandhooClientImgContainer">
            <a href="">
              <img
                style="max-width: 150px;"
                :src="require(`../assets/img/clients/${client.directory}/${client.image}`)"
                :alt="client.name"
                loading="lazy"
                @load="handleImageLoad"
              />
            </a>
          </div>
          <span class="statsBorderBottom statsBorder"></span>
        </div>

        <div
          v-for="(client, index) in clients"
          :key="`clone-${index}`"
          class="bandhooClient"
        >
          <span class="statsBorderTop statsBorder"></span>
          <div class="bandhooClientImgContainer">
            <a href="">
              <img
                style="max-width: 150px;"
                :src="require(`../assets/img/clients/${client.directory}/${client.image}`)"
                :alt="client.name"
                loading="lazy"
                @load="handleImageLoad"
              />
            </a>
          </div>
          <span class="statsBorderBottom statsBorder"></span>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
export default {
  name: 'InfiniteScroll',
  props: {
    clients: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      scrollPosition: 0,
      animationFrame: null,
      isReady: false,
      loadedImages: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.loadedImages === this.clients.length * 2) {
        this.startScrolling()
      }
    })
  },
  beforeDestroy() {
    this.stopScrolling()
  },
  methods: {
    handleImageLoad() {
      this.loadedImages++
      if (this.loadedImages === this.clients.length * 2) {
        this.startScrolling()
      }
    },
    startScrolling() {
      if (!this.$refs.track) return

      const animate = () => {
        if (!this.$refs.track) return

        this.scrollPosition += 1.5
        const track = this.$refs.track

        if (this.scrollPosition >= track.scrollWidth / 2) {
          this.scrollPosition = 0
        }

        track.style.transform = `translateX(-${this.scrollPosition}px)`
        this.animationFrame = requestAnimationFrame(animate)
      }

      this.animationFrame = requestAnimationFrame(animate)
    },
    stopScrolling() {
      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame)
      }
    }
  }
}
</script>
  
  <style scoped>
  .carouselContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .carouselTrack {
    display: flex;
    align-items: center;
    will-change: transform;
  }
  
  .bandhooClient {
    flex: 0 0 auto;
    padding: 0 1rem;
  }
  
  @media screen and (max-width: 550px) {
    .bandhooClient {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 730px) and (min-width:550px) {
    .bandhooClient {
      width: 100%;
    }
  }
  </style>