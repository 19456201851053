export const navLinks = [
    {
        name: "Home",
        url: "/",
        gtag: "website_home",
    },
    {
        name: "Solutions",
        url: "/solutions",
        gtag: "website_solutions",
    },
    {
        name: "Clients",
        url: "/clients",
        gtag: "website_clients",
    },
    {
        name: "About Us",
        url: "/about-us",
        gtag: "website_aboutus",
    },
    {
        name: "FAQs",
        url: "/faqs",
        gtag: "website_faqs",
    },
    {
        name: "DigitalShramik",
        url: "/digital-shramik",
        gtag: "website_digitalshramik",
    },
    {
        name: "Contact Us",
        url: "/contact-us",
        gtag: "website_contactus",
    }
]
export const contractorSkills ={
    //images: will be computed based on imgSkills
   "AL_GLASS_WORK": {
        name: 'Aluminium Glass',
        value: 'AL_GLASS_WORK',
        imgSkills:['AL_GLASS_WORK'],
        images:[],
        numDirectImages:4
    },
    "CARPENTER":{
        name: 'Carpenter',
        value: 'CARPENTER',
        imgSkills:['CARPENTER'],
        images:[],
        numDirectImages:4
    },
    "ELECTRICIAN":{
        name: 'Electrician',
        value: 'ELECTRICIAN',
        imgSkills:['ELECTRICIAN'],
        images:[],
        numDirectImages:4
    },
    "EXCAVATION":{
        name: 'Excavation and Earthworks',
        value: 'EXCAVATION',
        imgSkills:['EXCAVATION'],
        images:[],
        numDirectImages:4
    },
    "FABRICATOR":{
        name: 'Fabricator',
        value: 'FABRICATOR',
        imgSkills:['FABRICATOR', 'WELDER'],
        images:[],
        numDirectImages:4
    },
    "FIRE_FIGHTING":{
        name: 'Fire Fighting',
        value: 'FIRE_FIGHTING',
        imgSkills:['PLUMBER', 'FIRE_FIGHTING', 'WATER_PROOFING', 'WATER_AND_WASTEWATER_TREATMENT_PLANTS'],
        images:[],
        numDirectImages:0
    },
    "FACILITY_MANAGEMENT_SERVICES":{
        name: 'Facility Management Service',
        value: 'FACILITY_MANAGEMENT_SERVICES',
        imgSkills:['FACILITY_MANAGEMENT_SERVICES'],
        images:[],
        numDirectImages:4,
        disabled: true
    },
    "FLOORING":{
        name: 'Flooring',
        value: 'FLOORING',
        imgSkills:['FLOORING', 'STONE_CUTTER'],
        images:[],
        numDirectImages:4
    },
    "HVAC": {
        name: 'HVAC installation/work',
        value: 'HVAC',
        numImages:4,
        imgSkills:['HVAC', 'OFFICE_FIT_OUTS', 'OFFICE_FIT_OUTS_HVAC'],
        images:[],
        numDirectImages:4
    },
    "OFFICE_FIT_OUTS":{
        name: 'Office Fitouts',
        value: 'OFFICE_FIT_OUTS',
        imgSkills:['HVAC', 'OFFICE_FIT_OUTS', 'OFFICE_FIT_OUTS_HVAC'],
        images:[],
        numDirectImages:0
    },
    "OFFICE_FIT_OUTS_HVAC":{
        name: 'Office Fitouts HVAC',
        value: 'OFFICE_FIT_OUTS_HVAC',
        imgSkills:['HVAC', 'OFFICE_FIT_OUTS', 'OFFICE_FIT_OUTS_HVAC'],
        images:[],
        numDirectImages:0,
        disabled: true
    },
    "MASON":{
        name: 'Mason',
        value: 'MASON',
        imgSkills:['CIVILWORK_AND_FINISHING', 'MASONRY_AND_MEP', 'PILING', 'MASON'],
        images:[],
        numDirectImages:4
    },
    "MASONRY_AND_MEP":{
        name: 'Masonry and MEP',
        value: 'MASONRY_AND_MEP',
        imgSkills:['CIVILWORK_AND_FINISHING', 'MASONRY_AND_MEP', 'PILING', 'MASON'],
        images:[],
        numDirectImages:0,
        disabled: true
    },
    "PAINTER":{
        name: 'Painter',
        value: 'PAINTER',
        imgSkills:['POP_FALSE_CEILING'],
        images:[],
        numDirectImages:20
    },
    "PILING":{
        name: 'Pilling',
        value: 'PILING',
        imgSkills:['CIVILWORK_AND_FINISHING', 'MASONRY_AND_MEP', 'PILING', 'MASON'],
        images:[],
        numDirectImages:0
    },
    "PLUMBER":{
        name: 'Plumber',
        value: 'PLUMBER',
        imgSkills:['PLUMBER', 'FIRE_FIGHTING', 'WATER_PROOFING', 'WATER_AND_WASTEWATER_TREATMENT_PLANTS'],
        images:[],
        numDirectImages:20
    },
    "SCAFFOLDER":{
        name: 'Scaffolder',
        value: 'SCAFFOLDER',
        imgSkills:['SCAFFOLDER'],
        images:[],
        numDirectImages:4
    },
    "SHUTTERING":{
        name: 'Carpenter Shuttering',
        value: 'SHUTTERING',
        imgSkills:['STEEL_WORKER', 'SHUTTERING'],
        images:[],
        numDirectImages:4
    },
    "STEEL_WORKER":{
        name: 'Barbending',
        value: 'STEEL_WORKER',
        imgSkills:['SHUTTERING', 'STEEL_WORKER'],
        images:[],
        numDirectImages:4
    },
    "STONE_CUTTER":{
        name: 'Stone Work',
        value: 'STONE_CUTTER',
        imgSkills:['FLOORING', 'STONE_CUTTER'],
        images:[],
        numDirectImages:0
    },
    "WATER_PROOFING":{
        name: 'Water Proofing',
        value: 'WATER_PROOFING',
        imgSkills:['PLUMBER', 'FIRE_FIGHTING', 'WATER_PROOFING', 'WATER_AND_WASTEWATER_TREATMENT_PLANTS'],
        images:[],
        numDirectImages:0
    },
    "WELDER":{
        name: 'Welder',
        value: 'WELDER',
        imgSkills:['FABRICATOR', 'WELDER'],
        images:[],
        numDirectImages:0
    },
    "POP_FALSE_CEILING":{
        name: 'POP/False ceiling',
        value: 'POP_FALSE_CEILING',
        imgSkills:['POP_FALSE_CEILING', 'PAINTER'],
        images:[],
        numDirectImages:4
    },
    "CIVILWORK_AND_FINISHING":{
        name: 'Civil Work and Finishing',
        value: 'CIVILWORK_AND_FINISHING',
        imgSkills:['CIVILWORK_AND_FINISHING', 'MASONRY_AND_MEP', 'PILING', 'MASON', 'FINISHING'],
        images:[],
        numDirectImages:4, 
        disabled: true
    },
    "CSR":{
        name: 'Civil Works or RCC',
        value: 'CSR',
        imgSkills:['CIVILWORK_AND_FINISHING', 'MASONRY_AND_MEP', 'PILING', 'MASON', 'FINISHING'],
        images:[],
        numDirectImages:0
    },
   "FINISHING": {
        name: 'Finishing',
        value: 'FINISHING',
        imgSkills:['CIVILWORK_AND_FINISHING', 'FINISHING'],
        images:[],
        numDirectImages:0
    },
    "SOLAR_PLANTS":{
        name: 'Solar Plants',
        value: 'SOLAR_PLANTS',
        imgSkills:['SOLAR_PLANTS'],
        images:[],
        numDirectImages:4
    },

    "WATER_AND_WASTEWATER_TREATMENT_PLANTS":{
        name: 'Water and Wastewater Treatment Plants',
        value: 'WATER_AND_WASTEWATER_TREATMENT_PLANTS',
        imgSkills:['PLUMBER', 'FIRE_FIGHTING', 'WATER_PROOFING', 'WATER_AND_WASTEWATER_TREATMENT_PLANTS'],
        images:[],
        numDirectImages:0
    },
    "Other":{
        name: 'Other',
        value: 'Other',
        imgSkills:['PLUMBER'],
        images:[],
        numDirectImages:0
    }
}

export const materialSkills ={
    //images: will be computed based on imgSkills
   "AdminHousekeeping": {
        name: 'Admin and Housekeeping',
        value: 'AdminHousekeeping',
        imgSkills:['AdminHousekeeping'],
        images:[],
        numDirectImages:4
    },
    "AluminumSections":{
        name: 'Aluminum Sections',
        value: 'AluminumSections',
        imgSkills:['DoorWindowsVentilators', 'Steel'],
        images:[],
        numDirectImages:0
    },
    "BitumenRoadTarAsphaltInDrums":{
        name: 'Bitumen, Road Tar and related',
        value: 'BitumenRoadTarAsphaltInDrums',
        imgSkills:['BitumenRoadTarAsphaltInDrums'],
        images:[],
        numDirectImages:4
    },
    "Boards":{
        name: 'Boards',
        value: 'Boards',
        imgSkills:['Boards'],
        images:[],
        numDirectImages:4
    },
    "BricksAndBlocks":{
        name: 'Bricks and Blocks',
        value: 'BricksAndBlocks',
        imgSkills:['BricksAndBlocks'],
        images:[],
        numDirectImages:4
    },
    "Cement":{
        name: 'Cement',
        value: 'Cement',
        imgSkills:['Cement'],
        images:[],
        numDirectImages:4
    },
    "ChemicalsAndAdhesives":{
        name: 'Chemicals and Adhesives',
        value: 'ChemicalsAndAdhesives',
        imgSkills:['ChemicalsAndAdhesives'],
        images:[],
        numDirectImages:4
    },
    "DoorWindowsVentilators":{
        name: 'Door Windows Ventilators',
        value: 'DoorWindowsVentilators',
        imgSkills:['DoorWindowsVentilators'],
        images:[],
        numDirectImages:4
    },
    "ElectricalFittings": {
        name: 'Electrical Fittings',
        value: 'ElectricalFittings',
        imgSkills:['ElectricalFittings'],
        images:[],
        numDirectImages:4
    },
    "FabricatedSteelStructures":{
        name: 'Fabricated Steel Structures',
        value: 'FabricatedSteelStructures',
        imgSkills:['FabricatedSteelStructures'],
        images:[],
        numDirectImages:4
    },
    "FirefightingItems":{
        name: 'Firefighting Items',
        value: 'FirefightingItems',
        imgSkills:['FirefightingItems'],
        images:[],
        numDirectImages:4
    },
    "FuelEnergyDGSets":{
        name: 'Fuel, Energy, DG Sets',
        value: 'FuelEnergyDGSets',
        imgSkills:['FuelEnergyDGSets'],
        images:[],
        numDirectImages:4
    },
    "FurnitureAndWardrobes":{
        name: 'Furniture and Wardrobes',
        value: 'FurnitureAndWardrobes',
        imgSkills:['FurnitureAndWardrobes'],
        images:[],
        numDirectImages:4
    },
    "GlassSheets":{
        name: 'Glass Sheets',
        value: 'GlassSheets',
        imgSkills:['GlassSheets'],
        images:[],
        numDirectImages:4
    },
    "IT_TelecomEquipment":{
        name: 'IT Telecom Equipment',
        value: 'IT_TelecomEquipment',
        imgSkills:['IT_TelecomEquipment'],
        images:[],
        numDirectImages:4
    },
    "LandscapeAndHorticulture":{
        name: 'Landscape And Horticulture',
        value: 'LandscapeAndHorticulture',
        imgSkills:['LandscapeAndHorticulture'],
        images:[],
        numDirectImages:4
    },
    "LiftsEscalatorsAndRelated":{
        name: 'Lifts, Escalators and Related',
        value: 'LiftsEscalatorsAndRelated',
        imgSkills:['LiftsEscalatorsAndRelated'],
        images:[],
        numDirectImages:4
    },
    "ModularKitchensAndAppliances":{
        name: 'Modular Kitchens and Appliances',
        value: 'ModularKitchensAndAppliances',
        imgSkills:['ModularKitchensAndAppliances'],
        images:[],
        numDirectImages:4
    },
    "Paints":{
        name: 'Paints',
        value: 'Paints',
        imgSkills:['Paints'],
        images:[],
        numDirectImages:4
    },
    "PlumbingMaterials":{
        name: 'Plumbing Materials',
        value: 'PlumbingMaterials',
        imgSkills:['PlumbingMaterials'],
        images:[],
        numDirectImages:4
    },
    "RMC":{
        name: 'RMC',
        value: 'RMC',
        imgSkills:['RMC'],
        images:[],
        numDirectImages:4
    },
    "RoofingSheets":{
        name: 'Roofing Sheets',
        value: 'RoofingSheets',
        imgSkills:['RoofingSheets'],
        images:[],
        numDirectImages:4
    },
    "SanitaryAppliances":{
        name: 'Sanitary Appliances',
        value: 'SanitaryAppliances',
        imgSkills:['SanitaryAppliances'],
        images:[],
        numDirectImages:4
    },
    "SecurityFittings":{
        name: 'Security Fittings',
        value: 'SecurityFittings',
        imgSkills:['SecurityFittings'],
        images:[],
        numDirectImages:4
    },
   "SolarModulesAndRelated": {
        name: 'Solar Modules and Related',
        value: 'SolarModulesAndRelated',
        imgSkills:['SolarModulesAndRelated'],
        images:[],
        numDirectImages:4
    },
    "Steel":{
        name: 'Steel',
        value: 'Steel',
        imgSkills:['Steel'],
        images:[],
        numDirectImages:4
    },
    "StoneAndAggregates":{
        name: 'Stone and Aggregates',
        value: 'StoneAndAggregates',
        imgSkills:['StoneAndAggregates'],
        images:[],
        numDirectImages:4
    },
    "Tiles":{
        name: 'Tiles',
        value: 'Tiles',
        imgSkills:['Tiles'],
        images:[],
        numDirectImages:4
    },
    "Adjustments":{
        name: 'Miscellaneous',
        value: 'Adjustments',
        imgSkills:['Tiles', 'StoneAndAggregates'],
        images:[],
        numDirectImages:0
    },
    "Other":{
        name: 'Other',
        value: 'Other',
        imgSkills:['Other'],
        images:[],
        numDirectImages:4
    }
}

export const workerSkills = [
    {
        name: 'Aluminium Glass',
        value: 'AL_GLASS_WORK'
    },
    {
        name: 'Carpenter',
        value: 'CARPENTER'
    },
    {
        name: 'Coolie',
        value: 'COOLIE'
    },
    {
        name: 'Electrician',
        value: 'ELECTRICIAN'
    },
    {
        name: 'Fabricator',
        value: 'FABRICATOR'
    },
    {
        name: 'Factory Worker',
        value: 'FACTORY_WORKER'
    },
    {
        name: 'Flooring',
        value: 'FLOORING'
    },
    {
        name: 'Helper Civil',
        value: 'HELPER'
    },
    {
        name: 'Helper Electrician',
        value: 'HELPER_ELECTRICIAN'
    },
    {
        name: 'Helper Carpenter',
        value: 'HELPER_CARPENTER'
    },
    {
        name: 'Helper Plumber',
        value: 'HELPER_PLUMBER'
    },
    {
        name: 'Mason',
        value: 'MASON'
    },
    {
        name: 'JCB Operator',
        value: 'JCB_OPERATOR'
    },
    {
        name: 'Painter',
        value: 'PAINTER'
    },
    {
        name: 'Plumber',
        value: 'PLUMBER'
    },
    {
        name: 'Scaffolder',
        value: 'SCAFFOLDER'
    },
    {
        name: 'RMC Machine Operator',
        value: 'RMC_MACHINE_OPERATOR'
    },
    {
        name: 'Carpenter Shuttering',
        value: 'SHUTTERING'
    },
    {
        name: 'Barbending',
        value: 'STEEL_WORKER'
    },
    {
        name: 'Stone Work',
        value: 'STONE_CUTTER'
    },
    {
        name: 'Crane Operator',
        value: 'TOWER_CRANE_OPERATOR'
    },
    {
        name: 'Welder',
        value: 'WELDER'
    }
]
export const requestDemo = {
    name: "Request Demo",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSdQditzMzSBuq2J2urqc2OR28cjAFdTitdSHlOyXdHp6N2rfQ/viewform",
}
export const register = {
    name: "Register Now",
    url: "https://api.whatsapp.com/send/?phone=918296747530&text=Hello%2C+%0D%0AWelcome+to+Bandhoo&type=phone_number",
}
export const rolesInfo = [
    {
        role: "Developers",
        url: "/solutions/developer",
        gtag: "website_developer",
        description:
            "How Bandhoo’s PLM helps you digitally run your Procurement and Projects",
    },
    {
        role: "Contractors",
        url: "/solutions/contractor",
        gtag: "website_contractor",
        description:
            "As a Contractor, you can get many benefits by using Bandhoo’s solution and services",
    },
    {
        role: "ठेकेदार",
        url: "/solutions/thekedar",
        gtag: "website_thekedar",
        description:
            "बंधू ऐप पर ठेकेदार आइटम रेट के टेंडरों का काम ले सकते हैं |",
    },
    {
        role: "श्रमिक / वर्कर",
        url: "/solutions/worker",
        gtag: "website_worker",
        description:
            "श्रमिक बंधू ऐप पर अपने मन मुताबिक दिहाड़ी का काम प्राप्त कर सकते हैं |",
    },
    // {
    //     role: "Material Suppliers",
    //     url: "/solutions/material-supplier",
    //     gtag: "webstite_materialsupplier",
    //     description:
    //         "Get access to material supply RFQs (Tenders) from clients",
    // },
    // {
    //     role: "Home Owners",
    //     url: "/solutions/home-owner",
    //     gtag: "website_homeowner",
    //     description:
    //         "You can find good quality contractors to build your dream home",
    // },
    {
      role: "AI Project monitoring",
      url: "/solutions/ai_project_monitoring",
      gtag: "ai_project_monitoring",
      description:"Full cycle construction management"
  },
]
export const bandhooStats = [
    {
        role: "Developers",
        number: "15",
    },
    {
        role: "Contractors",
        number: "116900",
    },
    {
        role: "Workers",
        number: "279800",
    },
    {
        role: "Suppliers",
        number: "10000",
    },
]
export const bandhooContacts = [
    {
        name: "Call",
        icon: "call",
        contactInfo: "+91-6364-432-123",
        url: 'tel:+91-6364-432-123'
    },
    {
        name: "WhatsApp",
        icon: "whatsappwhite.png",
        contactInfo: "+91 8296747530",
        url: 'https://wa.me/918296747530'
    },
    {
        name: "Mail",
        icon: "mail",
        contactInfo: "info@bandhoo.com",
        url: 'mailto:info@bandhoo.com'
    },
    {
        name: "Website",
        icon: "language",
        contactInfo: "www.bandhoo.com",
        url: '/'
    },
]
export const bandhooClients = [
  {
    name: "Transrail",
    image: "transrail.png",
    directory: "contractors",
  },
  {
    name: "Prithu",
    image: "prithu5.jpg",
    directory: "contractors",
  },
  {
    name: "L&T",
    image: "lt.jpg",
    directory: "contractors",
  },
  {
    name: "Sobha",
    image: "shbha2.jpg",
    directory: "contractors",
  },
  {
    name: "The house of Abhinandan LODHA",
    image: "thehouseofabhinandan.png",
    directory: "developers",
  },
  {
    name: "Brigade",
    image: "brigade.png",
    directory: "developers",
  },
  {
    name: "Gera",
    image: "gera.png",
    directory: "developers",
  },
  {
    name: "Prestige Group",
    image: "prestigeGroup.png",
    directory: "developers",
  },
  {
    name: "Brookfield",
    image: "brookfield.png",
    directory: "developers",
  },
  {
    name: "Bagmane",
    image: "bagmane.jpg",
    directory: "developers",
  },
  {
    name: "Shapoorji Pallonji",
    image: "shapoorjiPallonji.png",
    directory: "developers",
  },
  {
    name: "Kolte Patil",
    image: "koltePatil.png",
    directory: "developers",
  },
  {
    name: "Experion",
    image: "experion.png",
    directory: "developers",
  },
  {
    name: "Lodha",
    image: "lodhaTransparent.png",
    directory: "developers",
  },
  {
    name: "Piramal Realty",
    image: "piramalRealtyTransparent.png",
    directory: "developers",
  },
  {
    name: "Godrej Properties",
    image: "godrejPropertiesWhite.png",
    directory: "developers",
  },
  {
    name: "Saifee Burhani",
    image: "saifee.png",
    directory: "developers",
  },
  {
    name: "Emaar",
    image: "emaar.png",
    directory: "developers",
  },
  {
    name: "JLL",
    image: "jllWhite.png",
    directory: "developers",
  },
  {
    name: "Eldeco",
    image: "eldeco.png",
    directory: "developers",
  },
  {
    name: "TVS Emerald",
    image: "tvs.png",
    directory: "developers",
  },
  {
    name: "Embassy",
    image: "Embassy.png",
    directory: "developers",
  },
  {
    name: "Adarsh Groups",
    image: "adarshGroups.png",
    directory: "developers",
  },
  {
    name: "L&T Realty",
    image: "lt.png",
    directory: "developers",
  },
  {
    name: "Savills",
    image: "savills.png",
    directory: "developers",
  },
  {
    name: "Maia",
    image: "maia.png",
    directory: "developers",
  },
  {
    name: "Birla Estate",
    image: "birla.png",
    directory: "developers",
  }
];

export const quickLinks = [
    {
        linkName: "Home Owners",
        url: "/solutions/home-owner",
        gtag: "website_homeowners"
    },
    {
        linkName: "Developers",
        url: "/solutions/developer",
        gtag: "website_developer"
    },
    {
        linkName: "Contractors",
        url: "/solutions/contractor",
        gtag: "website_contractor"
    },
    {
        linkName: "ठेकेदार",
        url: "/solutions/thekedar",
        gtag: "website_thekedar"
    },
    {
        linkName: "श्रमिक / वर्कर",
        url: "/solutions/worker",
        gtag: "website_worker"
    },
    {
        linkName: "Material Suppliers",
        url: "/solutions/material-supplier",
        gtag: "website_materialsupplier"
    },
]

export const aboutUs = {
    images: [
        {
            src: "about1b.jpg"
        },
        {
            src: "about2b.jpg"
        }
    ],
    certificates: [
        {
            src: "soc.png"
        },
        {
            src: "iso.png"
        }
    ],
    bandhooLogo: "logom.png",
    topDescription:
        "Bandhoo’s mission is to help increase income opportunities for construction Workers and Contractors.India has nearly 70 million construction workers – masons, painters, welders, electricians, plumbers, crane operators, JCB operators, bar benders – doing short gigs across a wide spectrum of projects. Many sectors use them – Real Estate, Factory construction, Power Plants, Infrastructure, etc. More than 30% of these workers are migrant and nomadic.",
    bottomDescription:
        "Bandhoo’s mission is to help make this labour market transparent and efficient, thereby helping Contractors find workers more easily, and workers find jobs more conveniently. This venture has the potential to deliver significant social impact and that remains a driving motivation for the Bandhoo team.",
    purposeDescription: "Save costs on your materials procurement, find thekedars and labour contractors, hire workers, manage your projects digitally and remotely - all of this and much more is now possible with Bandhoo's technology. Get a free demo and trial immediately.",
}
export const appUtils = [
    {

        downloadLink: "https://play.google.com/store/apps/details?id=com.bandhoo.app&pli=1",
        image: "playstore2.png",
        gtag: 'website_playstore'
    },
    {

        downloadLink: "https://apps.apple.com/us/app/bandhoo/id1506602855?ls=1",
        image: "appstore.png",
        gtag: 'website_appstore'
    }
]


export const clientsLogo = {
    developers: [
        {
            src: 'adani.png'
        },
        {
            src: 'birla.png'
        },
        {
            src: 'piramalRealty.png'
        },
        {
            src: 'maxEstates.png'
        },
        {
            src: 'emaar.png'
        },
        {
            src: 'Embassy.png'
        },
        {
            src: 'experion.png'
        },
        {
            src: 'gleeds.png'
        },
        {
            src: 'godrej.png'
        },
        {
            src: 'jll.png'
        },
        {
            src: 'lodha.png'
        },
        {
            src: 'lt.png'
        },
        {
            src: 'saifee.png'
        },
        {
            src: 'tvs.png'
        },
        {
          src:'eldeco.png'
        },
        {
          src: 'godrejproperties.png'
        },
        {
          src: 'savills.png'
        },
        {
          src: 'brigade.png'
        },
        {
          src: 'cbre.png'
        },
        {
          src: 'century.png'
        },
        {
          src: 'maia.png'
        },
        {
          src: 'royalorchid.png'
        },
        {
          src: 'thehouseofabhinandan.png'
        }
    ],
    contractors: [
        {
            src: 'dynacon.jpg'
        },
        {
            src: 'kec.jpg'
        },
        {
            src: 'lt.jpg'
        },
        {
            src: 'nkg.jpg'
        },
        {
            src: 'prithu5.jpg'
        },
        {
            src: 'quess.jpg'
        },
        {
            src: 'shbha2.jpg'
        },
        {
            src: 'shobhaprojects.jpg'
        },
        {
            src: 'tataprojects.jpg'
        },
        {
            src: 'vensa.jpg'
        },
        {
            src: 'victoryone2.jpg'
        },
        {
          src: 'transrail.png'
        }
    ]
}

export const bandhooCompanyInfo =
{
    "company_name": "BANDHOO CONSTRUCTION AND PMC PRIVATE LIMITED", "company_bandhoo_status": [true, true], "company_profile_additional_info": "",
    "company_logo": [null, null, "BCPL"], "company_details": ["PRIVATE", "Bengaluru", "10-30", "1-10 cr", "29AALCB3825G1ZJ"], "company_skills": ["Flooring", "Painter", "Stone Work", "Cement", "Fuel, Energy, DG Sets"],
    "company_works": [{ "url": "bandhoo/img/2020-06-08 DigitalShramik.jpg", "name": "workName" }, { "url": "bandhoo/img/2020-07-04 DigitalShramik.jpg", "name": "workName" }], "company_testimonials": [{ "clientName": "santosh ", "clientPhone": "+919811829003", "jobDescription": "abc", "response": "null", "comments": null, "requestedDateTimeSeconds": 0, "responseDateTimeSeconds": 0, "skill": "Plumber" },], "company_testimonials_videos": ["D1jFoWFzLxM", "3lIeDxAEjvg"],
    "owner_details": [{ "img": { "url": "img/placeholder/member.jpg", "description": "Member image" }, "name": "Sudhanshu Varma", "description": "Proprietor", "mobile": "+917411835938", "email": "", "address": {} }]
}

export const popularCities =[
  {
    "name": "Delhi",
    "pincode": 110001
  },
  {
    "name": "Mumbai",
    "pincode": 400001
  },
  {
    "name": "Gurgaon",
    "pincode": 122001
  },
  {
    "name": "Bengaluru",
    "pincode": 560001
  },
  {
    "name": "Hyderabad",
    "pincode": 500005
  },
  {
    "name": "Chennai",
    "pincode": 600001
  },
  {
    "name": "Pune",
    "pincode": 410301
  },
  {
    "name": "Ahmedabad",
    "pincode": 380001
  },
  {
    "name": "Kolkata",
    "pincode": 700001
  },
  {
    "name": "Jaipur",
    "pincode": 302001
  },
  {
    "name": "Lucknow",
    "pincode": 226001
  },
  {
    "name": "Thane",
    "pincode": 400080
  },
  {
    "name": "Noida",
    "pincode": 201301
  },
  {
    "name": "Surat",
    "pincode": 382260
  },
  {
    "name": "Indore",
    "pincode": 452001
  },
  {
    "name": "Kanpur",
    "pincode": 208001
  },
  {
    "name": "Bhopal",
    "pincode": 462001
  },
  {
    "name": "Meerut",
    "pincode": 250001
  },
  {
    "name": "Ranchi",
    "pincode": 834001
  },
  {
    "name": "Nashik",
    "pincode": 422006
  },
  {
    "name": "Gwalior",
    "pincode": 474001
  },
  {
    "name": "Guwahati",
    "pincode": 781001
  },
  {
    "name": "Rajkot",
    "pincode": 360001
  },
  {
    "name": "Agra",
    "pincode": 282001
  },
  {
    "name": "Dehradun",
    "pincode": 248001
  },
  {
    "name": "Ghaziabad",
    "pincode": 201003
  },
  {
    "name": "Udaipur",
    "pincode": 313001
  }
]

export const pincodeList = [
  {
    "district": "South Andaman",
    "state": "A & N Islands",
    "pincode": "804041"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "803009"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "803010"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "594760"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "803007"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "803012"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "803011"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "594956"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "595357"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "594864"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "595448"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "803006"
  },
  {
    "district": "Anantapur",
    "state": "Andhra Pradesh",
    "pincode": "803008"
  },
  {
    "district": "Chittoor",
    "state": "Andhra Pradesh",
    "pincode": "803019"
  },
  {
    "district": "Chittoor",
    "state": "Andhra Pradesh",
    "pincode": "803015"
  },
  {
    "district": "Chittoor",
    "state": "Andhra Pradesh",
    "pincode": "803016"
  },
  {
    "district": "Chittoor",
    "state": "Andhra Pradesh",
    "pincode": "803020"
  },
  {
    "district": "Chittoor",
    "state": "Andhra Pradesh",
    "pincode": "803018"
  },
  {
    "district": "Chittoor",
    "state": "Andhra Pradesh",
    "pincode": "803017"
  },
  {
    "district": "Chittoor",
    "state": "Andhra Pradesh",
    "pincode": "803013"
  },
  {
    "district": "Chittoor",
    "state": "Andhra Pradesh",
    "pincode": "803014"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802958"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "587337"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802955"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802956"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "587758"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802951"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802954"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802952"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802957"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802953"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802950"
  },
  {
    "district": "East Godavari",
    "state": "Andhra Pradesh",
    "pincode": "587062"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "580425"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802984"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802980"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802981"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802973"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802976"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802979"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802974"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802983"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802985"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802977"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802975"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802982"
  },
  {
    "district": "Guntur",
    "state": "Andhra Pradesh",
    "pincode": "802978"
  },
  {
    "district": "Krishna",
    "state": "Andhra Pradesh",
    "pincode": "802970"
  },
  {
    "district": "Krishna",
    "state": "Andhra Pradesh",
    "pincode": "802967"
  },
  {
    "district": "Krishna",
    "state": "Andhra Pradesh",
    "pincode": "802972"
  },
  {
    "district": "Krishna",
    "state": "Andhra Pradesh",
    "pincode": "588883"
  },
  {
    "district": "Krishna",
    "state": "Andhra Pradesh",
    "pincode": "802968"
  },
  {
    "district": "Krishna",
    "state": "Andhra Pradesh",
    "pincode": "802971"
  },
  {
    "district": "Krishna",
    "state": "Andhra Pradesh",
    "pincode": "588968"
  },
  {
    "district": "Krishna",
    "state": "Andhra Pradesh",
    "pincode": "802969"
  },
  {
    "district": "Krishna",
    "state": "Andhra Pradesh",
    "pincode": "589560"
  },
  {
    "district": "Kurnool",
    "state": "Andhra Pradesh",
    "pincode": "803003"
  },
  {
    "district": "Kurnool",
    "state": "Andhra Pradesh",
    "pincode": "594542"
  },
  {
    "district": "Kurnool",
    "state": "Andhra Pradesh",
    "pincode": "593982"
  },
  {
    "district": "Kurnool",
    "state": "Andhra Pradesh",
    "pincode": "803005"
  },
  {
    "district": "Kurnool",
    "state": "Andhra Pradesh",
    "pincode": "593877"
  },
  {
    "district": "Kurnool",
    "state": "Andhra Pradesh",
    "pincode": "802992"
  },
  {
    "district": "Kurnool",
    "state": "Andhra Pradesh",
    "pincode": "803002"
  },
  {
    "district": "Kurnool",
    "state": "Andhra Pradesh",
    "pincode": "593932"
  },
  {
    "district": "Kurnool",
    "state": "Andhra Pradesh",
    "pincode": "803004"
  },
  {
    "district": "Kurnool",
    "state": "Andhra Pradesh",
    "pincode": "803001"
  },
  {
    "district": "Prakasam",
    "state": "Andhra Pradesh",
    "pincode": "590769"
  },
  {
    "district": "Prakasam",
    "state": "Andhra Pradesh",
    "pincode": "591081"
  },
  {
    "district": "Prakasam",
    "state": "Andhra Pradesh",
    "pincode": "802987"
  },
  {
    "district": "Prakasam",
    "state": "Andhra Pradesh",
    "pincode": "591202"
  },
  {
    "district": "Prakasam",
    "state": "Andhra Pradesh",
    "pincode": "802989"
  },
  {
    "district": "Prakasam",
    "state": "Andhra Pradesh",
    "pincode": "591289"
  },
  {
    "district": "Prakasam",
    "state": "Andhra Pradesh",
    "pincode": "802986"
  },
  {
    "district": "Prakasam",
    "state": "Andhra Pradesh",
    "pincode": "802988"
  },
  {
    "district": "Sri Potti Sriramulu Nellore",
    "state": "Andhra Pradesh",
    "pincode": "900038"
  },
  {
    "district": "Sri Potti Sriramulu Nellore",
    "state": "Andhra Pradesh",
    "pincode": "802990"
  },
  {
    "district": "Sri Potti Sriramulu Nellore",
    "state": "Andhra Pradesh",
    "pincode": "579552"
  },
  {
    "district": "Sri Potti Sriramulu Nellore",
    "state": "Andhra Pradesh",
    "pincode": "802991"
  },
  {
    "district": "Sri Potti Sriramulu Nellore",
    "state": "Andhra Pradesh",
    "pincode": "592766"
  },
  {
    "district": "Sri Potti Sriramulu Nellore",
    "state": "Andhra Pradesh",
    "pincode": "802993"
  },
  {
    "district": "Srikakulam",
    "state": "Andhra Pradesh",
    "pincode": "802941"
  },
  {
    "district": "Srikakulam",
    "state": "Andhra Pradesh",
    "pincode": "802939"
  },
  {
    "district": "Srikakulam",
    "state": "Andhra Pradesh",
    "pincode": "580777"
  },
  {
    "district": "Srikakulam",
    "state": "Andhra Pradesh",
    "pincode": "802938"
  },
  {
    "district": "Srikakulam",
    "state": "Andhra Pradesh",
    "pincode": "802940"
  },
  {
    "district": "Srikakulam",
    "state": "Andhra Pradesh",
    "pincode": "802942"
  },
  {
    "district": "Visakhapatnam",
    "state": "Andhra Pradesh",
    "pincode": "802947"
  },
  {
    "district": "Visakhapatnam",
    "state": "Andhra Pradesh",
    "pincode": "585801"
  },
  {
    "district": "Visakhapatnam",
    "state": "Andhra Pradesh",
    "pincode": "586424"
  },
  {
    "district": "Vizianagaram",
    "state": "Andhra Pradesh",
    "pincode": "802944"
  },
  {
    "district": "Vizianagaram",
    "state": "Andhra Pradesh",
    "pincode": "583164"
  },
  {
    "district": "Vizianagaram",
    "state": "Andhra Pradesh",
    "pincode": "802943"
  },
  {
    "district": "Vizianagaram",
    "state": "Andhra Pradesh",
    "pincode": "802945"
  },
  {
    "district": "Vizianagaram",
    "state": "Andhra Pradesh",
    "pincode": "802946"
  },
  {
    "district": "West Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802964"
  },
  {
    "district": "West Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802962"
  },
  {
    "district": "West Godavari",
    "state": "Andhra Pradesh",
    "pincode": "588177"
  },
  {
    "district": "West Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802959"
  },
  {
    "district": "West Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802965"
  },
  {
    "district": "West Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802960"
  },
  {
    "district": "West Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802966"
  },
  {
    "district": "West Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802961"
  },
  {
    "district": "West Godavari",
    "state": "Andhra Pradesh",
    "pincode": "802963"
  },
  {
    "district": "Y.S.R.",
    "state": "Andhra Pradesh",
    "pincode": "802994"
  },
  {
    "district": "Y.S.R.",
    "state": "Andhra Pradesh",
    "pincode": "802996"
  },
  {
    "district": "Y.S.R.",
    "state": "Andhra Pradesh",
    "pincode": "802998"
  },
  {
    "district": "Y.S.R.",
    "state": "Andhra Pradesh",
    "pincode": "592933"
  },
  {
    "district": "Y.S.R.",
    "state": "Andhra Pradesh",
    "pincode": "802995"
  },
  {
    "district": "Y.S.R.",
    "state": "Andhra Pradesh",
    "pincode": "802997"
  },
  {
    "district": "Y.S.R.",
    "state": "Andhra Pradesh",
    "pincode": "803000"
  },
  {
    "district": "Y.S.R.",
    "state": "Andhra Pradesh",
    "pincode": "802999"
  },
  {
    "district": "Y.S.R.",
    "state": "Andhra Pradesh",
    "pincode": "593289"
  },
  {
    "district": "Anjaw",
    "state": "Arunachal Pradesh",
    "pincode": "801449"
  },
  {
    "district": "Changlang",
    "state": "Arunachal Pradesh",
    "pincode": "801437"
  },
  {
    "district": "Changlang",
    "state": "Arunachal Pradesh",
    "pincode": "801438"
  },
  {
    "district": "Changlang",
    "state": "Arunachal Pradesh",
    "pincode": "801439"
  },
  {
    "district": "Dibang Valley",
    "state": "Arunachal Pradesh",
    "pincode": "801445"
  },
  {
    "district": "East Kameng",
    "state": "Arunachal Pradesh",
    "pincode": "801427"
  },
  {
    "district": "East Siang",
    "state": "Arunachal Pradesh",
    "pincode": "801434"
  },
  {
    "district": "East Siang",
    "state": "Arunachal Pradesh",
    "pincode": "263801"
  },
  {
    "district": "East Siang",
    "state": "Arunachal Pradesh",
    "pincode": "801435"
  },
  {
    "district": "Kurung Kumey",
    "state": "Arunachal Pradesh",
    "pincode": "801444"
  },
  {
    "district": "Kurung Kumey",
    "state": "Arunachal Pradesh",
    "pincode": "265171"
  },
  {
    "district": "Lohit",
    "state": "Arunachal Pradesh",
    "pincode": "801448"
  },
  {
    "district": "Lohit",
    "state": "Arunachal Pradesh",
    "pincode": "801447"
  },
  {
    "district": "Lower Dibang Valley",
    "state": "Arunachal Pradesh",
    "pincode": "801446"
  },
  {
    "district": "Lower Subansiri",
    "state": "Arunachal Pradesh",
    "pincode": "265135"
  },
  {
    "district": "Lower Subansiri",
    "state": "Arunachal Pradesh",
    "pincode": "801443"
  },
  {
    "district": "Papum Pare",
    "state": "Arunachal Pradesh",
    "pincode": "262507"
  },
  {
    "district": "Papum Pare",
    "state": "Arunachal Pradesh",
    "pincode": "801428"
  },
  {
    "district": "Papum Pare",
    "state": "Arunachal Pradesh",
    "pincode": "262748"
  },
  {
    "district": "Papum Pare",
    "state": "Arunachal Pradesh",
    "pincode": "801429"
  },
  {
    "district": "Papum Pare",
    "state": "Arunachal Pradesh",
    "pincode": "801430"
  },
  {
    "district": "Tawang",
    "state": "Arunachal Pradesh",
    "pincode": "801424"
  },
  {
    "district": "Tirap",
    "state": "Arunachal Pradesh",
    "pincode": "801440"
  },
  {
    "district": "Tirap",
    "state": "Arunachal Pradesh",
    "pincode": "801441"
  },
  {
    "district": "Tirap",
    "state": "Arunachal Pradesh",
    "pincode": "801442"
  },
  {
    "district": "Upper Siang",
    "state": "Arunachal Pradesh",
    "pincode": "264027"
  },
  {
    "district": "Upper Siang",
    "state": "Arunachal Pradesh",
    "pincode": "801436"
  },
  {
    "district": "Upper Subansiri",
    "state": "Arunachal Pradesh",
    "pincode": "801431"
  },
  {
    "district": "Upper Subansiri",
    "state": "Arunachal Pradesh",
    "pincode": "263203"
  },
  {
    "district": "West Kameng",
    "state": "Arunachal Pradesh",
    "pincode": "801426"
  },
  {
    "district": "West Kameng",
    "state": "Arunachal Pradesh",
    "pincode": "801425"
  },
  {
    "district": "West Siang",
    "state": "Arunachal Pradesh",
    "pincode": "801432"
  },
  {
    "district": "West Siang",
    "state": "Arunachal Pradesh",
    "pincode": "801433"
  },
  {
    "district": "Baksa",
    "state": "Assam",
    "pincode": "304411"
  },
  {
    "district": "Barpeta",
    "state": "Assam",
    "pincode": "801557"
  },
  {
    "district": "Barpeta",
    "state": "Assam",
    "pincode": "801555"
  },
  {
    "district": "Barpeta",
    "state": "Assam",
    "pincode": "801558"
  },
  {
    "district": "Barpeta",
    "state": "Assam",
    "pincode": "283322"
  },
  {
    "district": "Barpeta",
    "state": "Assam",
    "pincode": "801560"
  },
  {
    "district": "Barpeta",
    "state": "Assam",
    "pincode": "801556"
  },
  {
    "district": "Barpeta",
    "state": "Assam",
    "pincode": "801559"
  },
  {
    "district": "Biswanath",
    "state": "Assam",
    "pincode": "900160"
  },
  {
    "district": "Bongaigaon",
    "state": "Assam",
    "pincode": "801621"
  },
  {
    "district": "Bongaigaon",
    "state": "Assam",
    "pincode": "801620"
  },
  {
    "district": "Cachar",
    "state": "Assam",
    "pincode": "801615"
  },
  {
    "district": "Cachar",
    "state": "Assam",
    "pincode": "801614"
  },
  {
    "district": "Cachar",
    "state": "Assam",
    "pincode": "900032"
  },
  {
    "district": "Chirang",
    "state": "Assam",
    "pincode": "801622"
  },
  {
    "district": "Chirang",
    "state": "Assam",
    "pincode": "801623"
  },
  {
    "district": "Chirang",
    "state": "Assam",
    "pincode": "900034"
  },
  {
    "district": "Darrang",
    "state": "Assam",
    "pincode": "801631"
  },
  {
    "district": "Darrang",
    "state": "Assam",
    "pincode": "801630"
  },
  {
    "district": "Dhemaji",
    "state": "Assam",
    "pincode": "801579"
  },
  {
    "district": "Dhemaji",
    "state": "Assam",
    "pincode": "801580"
  },
  {
    "district": "Dhubri",
    "state": "Assam",
    "pincode": "801551"
  },
  {
    "district": "Dhubri",
    "state": "Assam",
    "pincode": "801552"
  },
  {
    "district": "Dhubri",
    "state": "Assam",
    "pincode": "801549"
  },
  {
    "district": "Dhubri",
    "state": "Assam",
    "pincode": "801548"
  },
  {
    "district": "Dhubri",
    "state": "Assam",
    "pincode": "280885"
  },
  {
    "district": "Dhubri",
    "state": "Assam",
    "pincode": "801550"
  },
  {
    "district": "Dibrugarh",
    "state": "Assam",
    "pincode": "801587"
  },
  {
    "district": "Dibrugarh",
    "state": "Assam",
    "pincode": "801586"
  },
  {
    "district": "Dibrugarh",
    "state": "Assam",
    "pincode": "801588"
  },
  {
    "district": "Dibrugarh",
    "state": "Assam",
    "pincode": "292421"
  },
  {
    "district": "Dima Hasao",
    "state": "Assam",
    "pincode": "801611"
  },
  {
    "district": "Dima Hasao",
    "state": "Assam",
    "pincode": "801612"
  },
  {
    "district": "Dima Hasao",
    "state": "Assam",
    "pincode": "801613"
  },
  {
    "district": "Dima Hasao",
    "state": "Assam",
    "pincode": "801610"
  },
  {
    "district": "Goalpara",
    "state": "Assam",
    "pincode": "801554"
  },
  {
    "district": "Goalpara",
    "state": "Assam",
    "pincode": "801553"
  },
  {
    "district": "Golaghat",
    "state": "Assam",
    "pincode": "801603"
  },
  {
    "district": "Golaghat",
    "state": "Assam",
    "pincode": "801599"
  },
  {
    "district": "Golaghat",
    "state": "Assam",
    "pincode": "294445"
  },
  {
    "district": "Golaghat",
    "state": "Assam",
    "pincode": "801600"
  },
  {
    "district": "Golaghat",
    "state": "Assam",
    "pincode": "801601"
  },
  {
    "district": "Golaghat",
    "state": "Assam",
    "pincode": "801602"
  },
  {
    "district": "Hailakandi",
    "state": "Assam",
    "pincode": "801618"
  },
  {
    "district": "Hailakandi",
    "state": "Assam",
    "pincode": "801619"
  },
  {
    "district": "Jorhat",
    "state": "Assam",
    "pincode": "801595"
  },
  {
    "district": "Jorhat",
    "state": "Assam",
    "pincode": "801598"
  },
  {
    "district": "Jorhat",
    "state": "Assam",
    "pincode": "801596"
  },
  {
    "district": "Jorhat",
    "state": "Assam",
    "pincode": "801597"
  },
  {
    "district": "Kamrup",
    "state": "Assam",
    "pincode": "801625"
  },
  {
    "district": "Kamrup",
    "state": "Assam",
    "pincode": "801624"
  },
  {
    "district": "Kamrup Metropolitan",
    "state": "Assam",
    "pincode": "801627"
  },
  {
    "district": "Kamrup Metropolitan",
    "state": "Assam",
    "pincode": "801626"
  },
  {
    "district": "Karbi Anglong",
    "state": "Assam",
    "pincode": "295449"
  },
  {
    "district": "Karbi Anglong",
    "state": "Assam",
    "pincode": "801607"
  },
  {
    "district": "Karbi Anglong",
    "state": "Assam",
    "pincode": "801606"
  },
  {
    "district": "Karbi Anglong",
    "state": "Assam",
    "pincode": "801609"
  },
  {
    "district": "Karbi Anglong",
    "state": "Assam",
    "pincode": "801605"
  },
  {
    "district": "Karbi Anglong",
    "state": "Assam",
    "pincode": "801604"
  },
  {
    "district": "Karbi Anglong",
    "state": "Assam",
    "pincode": "801608"
  },
  {
    "district": "Karbi Anglong",
    "state": "Assam",
    "pincode": "297793"
  },
  {
    "district": "Karimganj",
    "state": "Assam",
    "pincode": "801617"
  },
  {
    "district": "Karimganj",
    "state": "Assam",
    "pincode": "801616"
  },
  {
    "district": "Kokrajhar",
    "state": "Assam",
    "pincode": "801546"
  },
  {
    "district": "Kokrajhar",
    "state": "Assam",
    "pincode": "801547"
  },
  {
    "district": "Lakhimpur",
    "state": "Assam",
    "pincode": "801576"
  },
  {
    "district": "Lakhimpur",
    "state": "Assam",
    "pincode": "801578"
  },
  {
    "district": "Lakhimpur",
    "state": "Assam",
    "pincode": "801575"
  },
  {
    "district": "Lakhimpur",
    "state": "Assam",
    "pincode": "801577"
  },
  {
    "district": "Morigaon",
    "state": "Assam",
    "pincode": "801561"
  },
  {
    "district": "Nagaon",
    "state": "Assam",
    "pincode": "801562"
  },
  {
    "district": "Nagaon",
    "state": "Assam",
    "pincode": "801567"
  },
  {
    "district": "Nagaon",
    "state": "Assam",
    "pincode": "801566"
  },
  {
    "district": "Nagaon",
    "state": "Assam",
    "pincode": "801565"
  },
  {
    "district": "Nagaon",
    "state": "Assam",
    "pincode": "801569"
  },
  {
    "district": "Nagaon",
    "state": "Assam",
    "pincode": "801568"
  },
  {
    "district": "Nagaon",
    "state": "Assam",
    "pincode": "900033"
  },
  {
    "district": "Nagaon",
    "state": "Assam",
    "pincode": "801563"
  },
  {
    "district": "Nagaon",
    "state": "Assam",
    "pincode": "801564"
  },
  {
    "district": "Nalbari",
    "state": "Assam",
    "pincode": "801629"
  },
  {
    "district": "Nalbari",
    "state": "Assam",
    "pincode": "801628"
  },
  {
    "district": "Sivasagar",
    "state": "Assam",
    "pincode": "801590"
  },
  {
    "district": "Sivasagar",
    "state": "Assam",
    "pincode": "900035"
  },
  {
    "district": "Sivasagar",
    "state": "Assam",
    "pincode": "801594"
  },
  {
    "district": "Sivasagar",
    "state": "Assam",
    "pincode": "801591"
  },
  {
    "district": "Sivasagar",
    "state": "Assam",
    "pincode": "801592"
  },
  {
    "district": "Sivasagar",
    "state": "Assam",
    "pincode": "801589"
  },
  {
    "district": "Sivasagar",
    "state": "Assam",
    "pincode": "801593"
  },
  {
    "district": "Sonitpur",
    "state": "Assam",
    "pincode": "801573"
  },
  {
    "district": "Sonitpur",
    "state": "Assam",
    "pincode": "801570"
  },
  {
    "district": "Sonitpur",
    "state": "Assam",
    "pincode": "801574"
  },
  {
    "district": "Sonitpur",
    "state": "Assam",
    "pincode": "287311"
  },
  {
    "district": "Sonitpur",
    "state": "Assam",
    "pincode": "801571"
  },
  {
    "district": "Sonitpur",
    "state": "Assam",
    "pincode": "801572"
  },
  {
    "district": "Tinsukia",
    "state": "Assam",
    "pincode": "290058"
  },
  {
    "district": "Tinsukia",
    "state": "Assam",
    "pincode": "801584"
  },
  {
    "district": "Tinsukia",
    "state": "Assam",
    "pincode": "801581"
  },
  {
    "district": "Tinsukia",
    "state": "Assam",
    "pincode": "801582"
  },
  {
    "district": "Tinsukia",
    "state": "Assam",
    "pincode": "801585"
  },
  {
    "district": "Tinsukia",
    "state": "Assam",
    "pincode": "801583"
  },
  {
    "district": "Udalguri",
    "state": "Assam",
    "pincode": "801632"
  },
  {
    "district": "Udalguri",
    "state": "Assam",
    "pincode": "801633"
  },
  {
    "district": "Araria",
    "state": "Bihar",
    "pincode": "801307"
  },
  {
    "district": "Araria",
    "state": "Bihar",
    "pincode": "801306"
  },
  {
    "district": "Araria",
    "state": "Bihar",
    "pincode": "801305"
  },
  {
    "district": "Arwal",
    "state": "Bihar",
    "pincode": "801415"
  },
  {
    "district": "Aurangabad",
    "state": "Bihar",
    "pincode": "801401"
  },
  {
    "district": "Aurangabad",
    "state": "Bihar",
    "pincode": "801399"
  },
  {
    "district": "Aurangabad",
    "state": "Bihar",
    "pincode": "801402"
  },
  {
    "district": "Aurangabad",
    "state": "Bihar",
    "pincode": "801400"
  },
  {
    "district": "Banka",
    "state": "Bihar",
    "pincode": "801355"
  },
  {
    "district": "Banka",
    "state": "Bihar",
    "pincode": "801356"
  },
  {
    "district": "Begusarai",
    "state": "Bihar",
    "pincode": "801347"
  },
  {
    "district": "Begusarai",
    "state": "Bihar",
    "pincode": "801348"
  },
  {
    "district": "Begusarai",
    "state": "Bihar",
    "pincode": "801346"
  },
  {
    "district": "Begusarai",
    "state": "Bihar",
    "pincode": "801345"
  },
  {
    "district": "Begusarai",
    "state": "Bihar",
    "pincode": "801344"
  },
  {
    "district": "Bhagalpur",
    "state": "Bihar",
    "pincode": "801354"
  },
  {
    "district": "Bhagalpur",
    "state": "Bihar",
    "pincode": "240013"
  },
  {
    "district": "Bhagalpur",
    "state": "Bihar",
    "pincode": "801351"
  },
  {
    "district": "Bhagalpur",
    "state": "Bihar",
    "pincode": "801353"
  },
  {
    "district": "Bhojpur",
    "state": "Bihar",
    "pincode": "801385"
  },
  {
    "district": "Bhojpur",
    "state": "Bihar",
    "pincode": "801387"
  },
  {
    "district": "Bhojpur",
    "state": "Bihar",
    "pincode": "801388"
  },
  {
    "district": "Bhojpur",
    "state": "Bihar",
    "pincode": "801386"
  },
  {
    "district": "Bhojpur",
    "state": "Bihar",
    "pincode": "801389"
  },
  {
    "district": "Bhojpur",
    "state": "Bihar",
    "pincode": "801384"
  },
  {
    "district": "Buxar",
    "state": "Bihar",
    "pincode": "801391"
  },
  {
    "district": "Buxar",
    "state": "Bihar",
    "pincode": "801390"
  },
  {
    "district": "Darbhanga",
    "state": "Bihar",
    "pincode": "801320"
  },
  {
    "district": "Darbhanga",
    "state": "Bihar",
    "pincode": "801319"
  },
  {
    "district": "Gaya",
    "state": "Bihar",
    "pincode": "801406"
  },
  {
    "district": "Gaya",
    "state": "Bihar",
    "pincode": "801404"
  },
  {
    "district": "Gaya",
    "state": "Bihar",
    "pincode": "801405"
  },
  {
    "district": "Gaya",
    "state": "Bihar",
    "pincode": "801403"
  },
  {
    "district": "Gopalganj",
    "state": "Bihar",
    "pincode": "801328"
  },
  {
    "district": "Gopalganj",
    "state": "Bihar",
    "pincode": "801327"
  },
  {
    "district": "Gopalganj",
    "state": "Bihar",
    "pincode": "801325"
  },
  {
    "district": "Gopalganj",
    "state": "Bihar",
    "pincode": "801326"
  },
  {
    "district": "Jamui",
    "state": "Bihar",
    "pincode": "801411"
  },
  {
    "district": "Jamui",
    "state": "Bihar",
    "pincode": "801412"
  },
  {
    "district": "Jehanabad",
    "state": "Bihar",
    "pincode": "801413"
  },
  {
    "district": "Jehanabad",
    "state": "Bihar",
    "pincode": "801414"
  },
  {
    "district": "Kaimur (Bhabua)",
    "state": "Bihar",
    "pincode": "801392"
  },
  {
    "district": "Kaimur (Bhabua)",
    "state": "Bihar",
    "pincode": "249311"
  },
  {
    "district": "Katihar",
    "state": "Bihar",
    "pincode": "801314"
  },
  {
    "district": "Katihar",
    "state": "Bihar",
    "pincode": "801315"
  },
  {
    "district": "Khagaria",
    "state": "Bihar",
    "pincode": "801350"
  },
  {
    "district": "Khagaria",
    "state": "Bihar",
    "pincode": "801349"
  },
  {
    "district": "Kishanganj",
    "state": "Bihar",
    "pincode": "801309"
  },
  {
    "district": "Kishanganj",
    "state": "Bihar",
    "pincode": "801310"
  },
  {
    "district": "Kishanganj",
    "state": "Bihar",
    "pincode": "801308"
  },
  {
    "district": "Lakhisarai",
    "state": "Bihar",
    "pincode": "801360"
  },
  {
    "district": "Lakhisarai",
    "state": "Bihar",
    "pincode": "801361"
  },
  {
    "district": "Madhepura",
    "state": "Bihar",
    "pincode": "801316"
  },
  {
    "district": "Madhepura",
    "state": "Bihar",
    "pincode": "801317"
  },
  {
    "district": "Madhubani",
    "state": "Bihar",
    "pincode": "801301"
  },
  {
    "district": "Madhubani",
    "state": "Bihar",
    "pincode": "801298"
  },
  {
    "district": "Madhubani",
    "state": "Bihar",
    "pincode": "801300"
  },
  {
    "district": "Madhubani",
    "state": "Bihar",
    "pincode": "801299"
  },
  {
    "district": "Munger",
    "state": "Bihar",
    "pincode": "801358"
  },
  {
    "district": "Munger",
    "state": "Bihar",
    "pincode": "801359"
  },
  {
    "district": "Munger",
    "state": "Bihar",
    "pincode": "801357"
  },
  {
    "district": "Muzaffarpur",
    "state": "Bihar",
    "pincode": "801323"
  },
  {
    "district": "Muzaffarpur",
    "state": "Bihar",
    "pincode": "801322"
  },
  {
    "district": "Muzaffarpur",
    "state": "Bihar",
    "pincode": "801324"
  },
  {
    "district": "Muzaffarpur",
    "state": "Bihar",
    "pincode": "801321"
  },
  {
    "district": "Nalanda",
    "state": "Bihar",
    "pincode": "801364"
  },
  {
    "district": "Nalanda",
    "state": "Bihar",
    "pincode": "801365"
  },
  {
    "district": "Nalanda",
    "state": "Bihar",
    "pincode": "801366"
  },
  {
    "district": "Nalanda",
    "state": "Bihar",
    "pincode": "801367"
  },
  {
    "district": "Nalanda",
    "state": "Bihar",
    "pincode": "801368"
  },
  {
    "district": "Nawada",
    "state": "Bihar",
    "pincode": "801409"
  },
  {
    "district": "Nawada",
    "state": "Bihar",
    "pincode": "801407"
  },
  {
    "district": "Nawada",
    "state": "Bihar",
    "pincode": "801408"
  },
  {
    "district": "Pashchim Champaran",
    "state": "Bihar",
    "pincode": "801279"
  },
  {
    "district": "Pashchim Champaran",
    "state": "Bihar",
    "pincode": "801281"
  },
  {
    "district": "Pashchim Champaran",
    "state": "Bihar",
    "pincode": "801280"
  },
  {
    "district": "Pashchim Champaran",
    "state": "Bihar",
    "pincode": "801278"
  },
  {
    "district": "Pashchim Champaran",
    "state": "Bihar",
    "pincode": "801277"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801381"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801382"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801377"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801370"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801379"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801372"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801380"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801369"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801378"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801383"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801376"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801373"
  },
  {
    "district": "Patna",
    "state": "Bihar",
    "pincode": "801374"
  },
  {
    "district": "Purba Champaran",
    "state": "Bihar",
    "pincode": "801286"
  },
  {
    "district": "Purba Champaran",
    "state": "Bihar",
    "pincode": "801288"
  },
  {
    "district": "Purba Champaran",
    "state": "Bihar",
    "pincode": "801284"
  },
  {
    "district": "Purba Champaran",
    "state": "Bihar",
    "pincode": "801287"
  },
  {
    "district": "Purba Champaran",
    "state": "Bihar",
    "pincode": "801291"
  },
  {
    "district": "Purba Champaran",
    "state": "Bihar",
    "pincode": "801285"
  },
  {
    "district": "Purba Champaran",
    "state": "Bihar",
    "pincode": "801289"
  },
  {
    "district": "Purba Champaran",
    "state": "Bihar",
    "pincode": "801282"
  },
  {
    "district": "Purba Champaran",
    "state": "Bihar",
    "pincode": "801283"
  },
  {
    "district": "Purnia",
    "state": "Bihar",
    "pincode": "801311"
  },
  {
    "district": "Purnia",
    "state": "Bihar",
    "pincode": "801313"
  },
  {
    "district": "Purnia",
    "state": "Bihar",
    "pincode": "801312"
  },
  {
    "district": "Rohtas",
    "state": "Bihar",
    "pincode": "801394"
  },
  {
    "district": "Rohtas",
    "state": "Bihar",
    "pincode": "801398"
  },
  {
    "district": "Rohtas",
    "state": "Bihar",
    "pincode": "801393"
  },
  {
    "district": "Rohtas",
    "state": "Bihar",
    "pincode": "250726"
  },
  {
    "district": "Rohtas",
    "state": "Bihar",
    "pincode": "801395"
  },
  {
    "district": "Rohtas",
    "state": "Bihar",
    "pincode": "801396"
  },
  {
    "district": "Rohtas",
    "state": "Bihar",
    "pincode": "801397"
  },
  {
    "district": "Saharsa",
    "state": "Bihar",
    "pincode": "801318"
  },
  {
    "district": "Saharsa",
    "state": "Bihar",
    "pincode": "226636"
  },
  {
    "district": "Samastipur",
    "state": "Bihar",
    "pincode": "801341"
  },
  {
    "district": "Samastipur",
    "state": "Bihar",
    "pincode": "801342"
  },
  {
    "district": "Samastipur",
    "state": "Bihar",
    "pincode": "801340"
  },
  {
    "district": "Saran",
    "state": "Bihar",
    "pincode": "801333"
  },
  {
    "district": "Saran",
    "state": "Bihar",
    "pincode": "801335"
  },
  {
    "district": "Saran",
    "state": "Bihar",
    "pincode": "233335"
  },
  {
    "district": "Saran",
    "state": "Bihar",
    "pincode": "801334"
  },
  {
    "district": "Saran",
    "state": "Bihar",
    "pincode": "233325"
  },
  {
    "district": "Saran",
    "state": "Bihar",
    "pincode": "801332"
  },
  {
    "district": "Saran",
    "state": "Bihar",
    "pincode": "801336"
  },
  {
    "district": "Sheikhpura",
    "state": "Bihar",
    "pincode": "801362"
  },
  {
    "district": "Sheikhpura",
    "state": "Bihar",
    "pincode": "801363"
  },
  {
    "district": "Sheohar",
    "state": "Bihar",
    "pincode": "801292"
  },
  {
    "district": "Sitamarhi",
    "state": "Bihar",
    "pincode": "801293"
  },
  {
    "district": "Sitamarhi",
    "state": "Bihar",
    "pincode": "801294"
  },
  {
    "district": "Sitamarhi",
    "state": "Bihar",
    "pincode": "801296"
  },
  {
    "district": "Sitamarhi",
    "state": "Bihar",
    "pincode": "801297"
  },
  {
    "district": "Sitamarhi",
    "state": "Bihar",
    "pincode": "801295"
  },
  {
    "district": "Siwan",
    "state": "Bihar",
    "pincode": "801330"
  },
  {
    "district": "Siwan",
    "state": "Bihar",
    "pincode": "801331"
  },
  {
    "district": "Siwan",
    "state": "Bihar",
    "pincode": "801329"
  },
  {
    "district": "Supaul",
    "state": "Bihar",
    "pincode": "801303"
  },
  {
    "district": "Supaul",
    "state": "Bihar",
    "pincode": "801302"
  },
  {
    "district": "Supaul",
    "state": "Bihar",
    "pincode": "801304"
  },
  {
    "district": "Vaishali",
    "state": "Bihar",
    "pincode": "801338"
  },
  {
    "district": "Vaishali",
    "state": "Bihar",
    "pincode": "801337"
  },
  {
    "district": "Vaishali",
    "state": "Bihar",
    "pincode": "801339"
  },
  {
    "district": "Vaishali",
    "state": "Bihar",
    "pincode": "235252"
  },
  {
    "district": "Chandigarh",
    "state": "Chandigarh",
    "pincode": "800286"
  },
  {
    "district": "Bastar",
    "state": "Chhattisgarh",
    "pincode": "802065"
  },
  {
    "district": "Bastar",
    "state": "Chhattisgarh",
    "pincode": "802063"
  },
  {
    "district": "Bastar",
    "state": "Chhattisgarh",
    "pincode": "802064"
  },
  {
    "district": "Bastar",
    "state": "Chhattisgarh",
    "pincode": "802060"
  },
  {
    "district": "Bastar",
    "state": "Chhattisgarh",
    "pincode": "802062"
  },
  {
    "district": "Bijapur",
    "state": "Chhattisgarh",
    "pincode": "802077"
  },
  {
    "district": "Bijapur",
    "state": "Chhattisgarh",
    "pincode": "802075"
  },
  {
    "district": "Bijapur",
    "state": "Chhattisgarh",
    "pincode": "802076"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801975"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801980"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801979"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801965"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801968"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801967"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801978"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801970"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801971"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801966"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801969"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801974"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801972"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801977"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801973"
  },
  {
    "district": "Bilaspur",
    "state": "Chhattisgarh",
    "pincode": "801976"
  },
  {
    "district": "Dakshin Bastar Dantewada",
    "state": "Chhattisgarh",
    "pincode": "802068"
  },
  {
    "district": "Dakshin Bastar Dantewada",
    "state": "Chhattisgarh",
    "pincode": "802069"
  },
  {
    "district": "Dakshin Bastar Dantewada",
    "state": "Chhattisgarh",
    "pincode": "802067"
  },
  {
    "district": "Dakshin Bastar Dantewada",
    "state": "Chhattisgarh",
    "pincode": "802072"
  },
  {
    "district": "Dakshin Bastar Dantewada",
    "state": "Chhattisgarh",
    "pincode": "802070"
  },
  {
    "district": "Dakshin Bastar Dantewada",
    "state": "Chhattisgarh",
    "pincode": "802071"
  },
  {
    "district": "Dakshin Bastar Dantewada",
    "state": "Chhattisgarh",
    "pincode": "802073"
  },
  {
    "district": "Dhamtari",
    "state": "Chhattisgarh",
    "pincode": "802051"
  },
  {
    "district": "Dhamtari",
    "state": "Chhattisgarh",
    "pincode": "802048"
  },
  {
    "district": "Dhamtari",
    "state": "Chhattisgarh",
    "pincode": "802052"
  },
  {
    "district": "Dhamtari",
    "state": "Chhattisgarh",
    "pincode": "802049"
  },
  {
    "district": "Dhamtari",
    "state": "Chhattisgarh",
    "pincode": "802050"
  },
  {
    "district": "Dhamtari",
    "state": "Chhattisgarh",
    "pincode": "802053"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802004"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802012"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802015"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "801997"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802002"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802007"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802008"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802017"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802016"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802014"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "801999"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802003"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802018"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802009"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802013"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802019"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802006"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802005"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "801996"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "801995"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802000"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802011"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "801998"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802001"
  },
  {
    "district": "Durg",
    "state": "Chhattisgarh",
    "pincode": "802010"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801963"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801951"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801952"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801955"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801962"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801961"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801964"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801960"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801950"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801953"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801958"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801959"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801957"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801956"
  },
  {
    "district": "Janjgir - Champa",
    "state": "Chhattisgarh",
    "pincode": "801954"
  },
  {
    "district": "Jashpur",
    "state": "Chhattisgarh",
    "pincode": "801931"
  },
  {
    "district": "Jashpur",
    "state": "Chhattisgarh",
    "pincode": "801932"
  },
  {
    "district": "Jashpur",
    "state": "Chhattisgarh",
    "pincode": "801934"
  },
  {
    "district": "Jashpur",
    "state": "Chhattisgarh",
    "pincode": "434068"
  },
  {
    "district": "Jashpur",
    "state": "Chhattisgarh",
    "pincode": "801933"
  },
  {
    "district": "Jashpur",
    "state": "Chhattisgarh",
    "pincode": "802074"
  },
  {
    "district": "Kabeerdham",
    "state": "Chhattisgarh",
    "pincode": "801983"
  },
  {
    "district": "Kabeerdham",
    "state": "Chhattisgarh",
    "pincode": "801981"
  },
  {
    "district": "Kabeerdham",
    "state": "Chhattisgarh",
    "pincode": "801985"
  },
  {
    "district": "Kabeerdham",
    "state": "Chhattisgarh",
    "pincode": "801986"
  },
  {
    "district": "Kabeerdham",
    "state": "Chhattisgarh",
    "pincode": "801982"
  },
  {
    "district": "Kabeerdham",
    "state": "Chhattisgarh",
    "pincode": "801984"
  },
  {
    "district": "Korba",
    "state": "Chhattisgarh",
    "pincode": "801946"
  },
  {
    "district": "Korba",
    "state": "Chhattisgarh",
    "pincode": "801947"
  },
  {
    "district": "Korba",
    "state": "Chhattisgarh",
    "pincode": "801945"
  },
  {
    "district": "Korba",
    "state": "Chhattisgarh",
    "pincode": "801949"
  },
  {
    "district": "Korba",
    "state": "Chhattisgarh",
    "pincode": "801948"
  },
  {
    "district": "Koriya",
    "state": "Chhattisgarh",
    "pincode": "801911"
  },
  {
    "district": "Koriya",
    "state": "Chhattisgarh",
    "pincode": "801916"
  },
  {
    "district": "Koriya",
    "state": "Chhattisgarh",
    "pincode": "801914"
  },
  {
    "district": "Koriya",
    "state": "Chhattisgarh",
    "pincode": "801913"
  },
  {
    "district": "Koriya",
    "state": "Chhattisgarh",
    "pincode": "801912"
  },
  {
    "district": "Koriya",
    "state": "Chhattisgarh",
    "pincode": "801915"
  },
  {
    "district": "Koriya",
    "state": "Chhattisgarh",
    "pincode": "801910"
  },
  {
    "district": "Mahasamund",
    "state": "Chhattisgarh",
    "pincode": "802047"
  },
  {
    "district": "Mahasamund",
    "state": "Chhattisgarh",
    "pincode": "802042"
  },
  {
    "district": "Mahasamund",
    "state": "Chhattisgarh",
    "pincode": "802045"
  },
  {
    "district": "Mahasamund",
    "state": "Chhattisgarh",
    "pincode": "802046"
  },
  {
    "district": "Mahasamund",
    "state": "Chhattisgarh",
    "pincode": "802043"
  },
  {
    "district": "Mahasamund",
    "state": "Chhattisgarh",
    "pincode": "802044"
  },
  {
    "district": "Narayanpur",
    "state": "Chhattisgarh",
    "pincode": "802066"
  },
  {
    "district": "Raigarh",
    "state": "Chhattisgarh",
    "pincode": "801944"
  },
  {
    "district": "Raigarh",
    "state": "Chhattisgarh",
    "pincode": "801935"
  },
  {
    "district": "Raigarh",
    "state": "Chhattisgarh",
    "pincode": "801937"
  },
  {
    "district": "Raigarh",
    "state": "Chhattisgarh",
    "pincode": "801941"
  },
  {
    "district": "Raigarh",
    "state": "Chhattisgarh",
    "pincode": "801938"
  },
  {
    "district": "Raigarh",
    "state": "Chhattisgarh",
    "pincode": "801936"
  },
  {
    "district": "Raigarh",
    "state": "Chhattisgarh",
    "pincode": "801940"
  },
  {
    "district": "Raigarh",
    "state": "Chhattisgarh",
    "pincode": "801939"
  },
  {
    "district": "Raigarh",
    "state": "Chhattisgarh",
    "pincode": "801942"
  },
  {
    "district": "Raigarh",
    "state": "Chhattisgarh",
    "pincode": "801943"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802030"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802029"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802022"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802021"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802027"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802028"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802033"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802041"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802036"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802040"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802031"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802026"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802039"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802032"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802023"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802035"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802024"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802034"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802037"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802020"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802038"
  },
  {
    "district": "Raipur",
    "state": "Chhattisgarh",
    "pincode": "802025"
  },
  {
    "district": "Rajnandgaon",
    "state": "Chhattisgarh",
    "pincode": "801994"
  },
  {
    "district": "Rajnandgaon",
    "state": "Chhattisgarh",
    "pincode": "801988"
  },
  {
    "district": "Rajnandgaon",
    "state": "Chhattisgarh",
    "pincode": "801992"
  },
  {
    "district": "Rajnandgaon",
    "state": "Chhattisgarh",
    "pincode": "801993"
  },
  {
    "district": "Rajnandgaon",
    "state": "Chhattisgarh",
    "pincode": "801990"
  },
  {
    "district": "Rajnandgaon",
    "state": "Chhattisgarh",
    "pincode": "801987"
  },
  {
    "district": "Rajnandgaon",
    "state": "Chhattisgarh",
    "pincode": "801989"
  },
  {
    "district": "Rajnandgaon",
    "state": "Chhattisgarh",
    "pincode": "801991"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801927"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801918"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801925"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801924"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801921"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801922"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801928"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801920"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801926"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801929"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801917"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801930"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801923"
  },
  {
    "district": "Surguja",
    "state": "Chhattisgarh",
    "pincode": "801919"
  },
  {
    "district": "Uttar Bastar Kanker",
    "state": "Chhattisgarh",
    "pincode": "802058"
  },
  {
    "district": "Uttar Bastar Kanker",
    "state": "Chhattisgarh",
    "pincode": "802055"
  },
  {
    "district": "Uttar Bastar Kanker",
    "state": "Chhattisgarh",
    "pincode": "802054"
  },
  {
    "district": "Uttar Bastar Kanker",
    "state": "Chhattisgarh",
    "pincode": "802056"
  },
  {
    "district": "Uttar Bastar Kanker",
    "state": "Chhattisgarh",
    "pincode": "802057"
  },
  {
    "district": "Uttar Bastar Kanker",
    "state": "Chhattisgarh",
    "pincode": "802059"
  },
  {
    "district": "D & N Haveli",
    "state": "D & N Haveli",
    "pincode": "802639"
  },
  {
    "district": "Daman",
    "state": "Daman & Diu",
    "pincode": "802638"
  },
  {
    "district": "Diu",
    "state": "Daman & Diu",
    "pincode": "802637"
  },
  {
    "district": "North Goa",
    "state": "Goa",
    "pincode": "803244"
  },
  {
    "district": "North Goa",
    "state": "Goa",
    "pincode": "803242"
  },
  {
    "district": "North Goa",
    "state": "Goa",
    "pincode": "803243"
  },
  {
    "district": "North Goa",
    "state": "Goa",
    "pincode": "803241"
  },
  {
    "district": "North Goa",
    "state": "Goa",
    "pincode": "803247"
  },
  {
    "district": "North Goa",
    "state": "Goa",
    "pincode": "803245"
  },
  {
    "district": "North Goa",
    "state": "Goa",
    "pincode": "803246"
  },
  {
    "district": "South Goa",
    "state": "Goa",
    "pincode": "803254"
  },
  {
    "district": "South Goa",
    "state": "Goa",
    "pincode": "803250"
  },
  {
    "district": "South Goa",
    "state": "Goa",
    "pincode": "803252"
  },
  {
    "district": "South Goa",
    "state": "Goa",
    "pincode": "803249"
  },
  {
    "district": "South Goa",
    "state": "Goa",
    "pincode": "803248"
  },
  {
    "district": "South Goa",
    "state": "Goa",
    "pincode": "803251"
  },
  {
    "district": "South Goa",
    "state": "Goa",
    "pincode": "803253"
  },
  {
    "district": "Ahmadabad",
    "state": "Gujarat",
    "pincode": "802484"
  },
  {
    "district": "Ahmadabad",
    "state": "Gujarat",
    "pincode": "802485"
  },
  {
    "district": "Ahmadabad",
    "state": "Gujarat",
    "pincode": "802488"
  },
  {
    "district": "Ahmadabad",
    "state": "Gujarat",
    "pincode": "802487"
  },
  {
    "district": "Ahmadabad",
    "state": "Gujarat",
    "pincode": "511685"
  },
  {
    "district": "Ahmadabad",
    "state": "Gujarat",
    "pincode": "802489"
  },
  {
    "district": "Ahmadabad",
    "state": "Gujarat",
    "pincode": "802486"
  },
  {
    "district": "Ahmadabad",
    "state": "Gujarat",
    "pincode": "802482"
  },
  {
    "district": "Ahmadabad",
    "state": "Gujarat",
    "pincode": "802481"
  },
  {
    "district": "Amreli",
    "state": "Gujarat",
    "pincode": "802542"
  },
  {
    "district": "Amreli",
    "state": "Gujarat",
    "pincode": "802539"
  },
  {
    "district": "Amreli",
    "state": "Gujarat",
    "pincode": "802543"
  },
  {
    "district": "Amreli",
    "state": "Gujarat",
    "pincode": "802544"
  },
  {
    "district": "Amreli",
    "state": "Gujarat",
    "pincode": "802541"
  },
  {
    "district": "Amreli",
    "state": "Gujarat",
    "pincode": "802546"
  },
  {
    "district": "Amreli",
    "state": "Gujarat",
    "pincode": "802540"
  },
  {
    "district": "Amreli",
    "state": "Gujarat",
    "pincode": "802547"
  },
  {
    "district": "Amreli",
    "state": "Gujarat",
    "pincode": "802545"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802562"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802570"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802560"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802568"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802564"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802567"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802561"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802566"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802558"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802559"
  },
  {
    "district": "Anand",
    "state": "Gujarat",
    "pincode": "802563"
  },
  {
    "district": "Banas Kantha",
    "state": "Gujarat",
    "pincode": "802452"
  },
  {
    "district": "Banas Kantha",
    "state": "Gujarat",
    "pincode": "802451"
  },
  {
    "district": "Banas Kantha",
    "state": "Gujarat",
    "pincode": "802449"
  },
  {
    "district": "Banas Kantha",
    "state": "Gujarat",
    "pincode": "802450"
  },
  {
    "district": "Banas Kantha",
    "state": "Gujarat",
    "pincode": "802453"
  },
  {
    "district": "Banas Kantha",
    "state": "Gujarat",
    "pincode": "802448"
  },
  {
    "district": "Bharuch",
    "state": "Gujarat",
    "pincode": "802605"
  },
  {
    "district": "Bharuch",
    "state": "Gujarat",
    "pincode": "802608"
  },
  {
    "district": "Bharuch",
    "state": "Gujarat",
    "pincode": "802607"
  },
  {
    "district": "Bharuch",
    "state": "Gujarat",
    "pincode": "802604"
  },
  {
    "district": "Bhavnagar",
    "state": "Gujarat",
    "pincode": "802551"
  },
  {
    "district": "Bhavnagar",
    "state": "Gujarat",
    "pincode": "802548"
  },
  {
    "district": "Bhavnagar",
    "state": "Gujarat",
    "pincode": "802550"
  },
  {
    "district": "Bhavnagar",
    "state": "Gujarat",
    "pincode": "802553"
  },
  {
    "district": "Bhavnagar",
    "state": "Gujarat",
    "pincode": "802557"
  },
  {
    "district": "Bhavnagar",
    "state": "Gujarat",
    "pincode": "802554"
  },
  {
    "district": "Bhavnagar",
    "state": "Gujarat",
    "pincode": "802552"
  },
  {
    "district": "Bhavnagar",
    "state": "Gujarat",
    "pincode": "802555"
  },
  {
    "district": "Bhavnagar",
    "state": "Gujarat",
    "pincode": "802549"
  },
  {
    "district": "Dohad",
    "state": "Gujarat",
    "pincode": "802591"
  },
  {
    "district": "Dohad",
    "state": "Gujarat",
    "pincode": "802590"
  },
  {
    "district": "Dohad",
    "state": "Gujarat",
    "pincode": "802589"
  },
  {
    "district": "Gandhinagar",
    "state": "Gujarat",
    "pincode": "802480"
  },
  {
    "district": "Gandhinagar",
    "state": "Gujarat",
    "pincode": "802479"
  },
  {
    "district": "Gandhinagar",
    "state": "Gujarat",
    "pincode": "802475"
  },
  {
    "district": "Gandhinagar",
    "state": "Gujarat",
    "pincode": "802477"
  },
  {
    "district": "Gandhinagar",
    "state": "Gujarat",
    "pincode": "802478"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802520"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802517"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802510"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802521"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802516"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802518"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802512"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802509"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802519"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802511"
  },
  {
    "district": "Jamnagar",
    "state": "Gujarat",
    "pincode": "802513"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802526"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802533"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802529"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802531"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802537"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802527"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802532"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802536"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802534"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802538"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802528"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802535"
  },
  {
    "district": "Junagadh",
    "state": "Gujarat",
    "pincode": "802530"
  },
  {
    "district": "Kachchh",
    "state": "Gujarat",
    "pincode": "802444"
  },
  {
    "district": "Kachchh",
    "state": "Gujarat",
    "pincode": "802443"
  },
  {
    "district": "Kachchh",
    "state": "Gujarat",
    "pincode": "802445"
  },
  {
    "district": "Kachchh",
    "state": "Gujarat",
    "pincode": "802447"
  },
  {
    "district": "Kachchh",
    "state": "Gujarat",
    "pincode": "802446"
  },
  {
    "district": "Kachchh",
    "state": "Gujarat",
    "pincode": "802442"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802572"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802577"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802581"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802578"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802571"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802573"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802575"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802579"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802574"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802576"
  },
  {
    "district": "Kheda",
    "state": "Gujarat",
    "pincode": "802580"
  },
  {
    "district": "Mahesana",
    "state": "Gujarat",
    "pincode": "802465"
  },
  {
    "district": "Mahesana",
    "state": "Gujarat",
    "pincode": "802459"
  },
  {
    "district": "Mahesana",
    "state": "Gujarat",
    "pincode": "802464"
  },
  {
    "district": "Mahesana",
    "state": "Gujarat",
    "pincode": "802460"
  },
  {
    "district": "Mahesana",
    "state": "Gujarat",
    "pincode": "802462"
  },
  {
    "district": "Mahesana",
    "state": "Gujarat",
    "pincode": "802463"
  },
  {
    "district": "Mahesana",
    "state": "Gujarat",
    "pincode": "802461"
  },
  {
    "district": "Narmada",
    "state": "Gujarat",
    "pincode": "802603"
  },
  {
    "district": "Navsari",
    "state": "Gujarat",
    "pincode": "802617"
  },
  {
    "district": "Navsari",
    "state": "Gujarat",
    "pincode": "802616"
  },
  {
    "district": "Navsari",
    "state": "Gujarat",
    "pincode": "802614"
  },
  {
    "district": "Navsari",
    "state": "Gujarat",
    "pincode": "802615"
  },
  {
    "district": "Panch Mahals",
    "state": "Gujarat",
    "pincode": "802585"
  },
  {
    "district": "Panch Mahals",
    "state": "Gujarat",
    "pincode": "802588"
  },
  {
    "district": "Panch Mahals",
    "state": "Gujarat",
    "pincode": "802586"
  },
  {
    "district": "Panch Mahals",
    "state": "Gujarat",
    "pincode": "802583"
  },
  {
    "district": "Panch Mahals",
    "state": "Gujarat",
    "pincode": "802582"
  },
  {
    "district": "Panch Mahals",
    "state": "Gujarat",
    "pincode": "802584"
  },
  {
    "district": "Patan",
    "state": "Gujarat",
    "pincode": "802458"
  },
  {
    "district": "Patan",
    "state": "Gujarat",
    "pincode": "802457"
  },
  {
    "district": "Patan",
    "state": "Gujarat",
    "pincode": "802456"
  },
  {
    "district": "Patan",
    "state": "Gujarat",
    "pincode": "802454"
  },
  {
    "district": "Patan",
    "state": "Gujarat",
    "pincode": "802455"
  },
  {
    "district": "Porbandar",
    "state": "Gujarat",
    "pincode": "802523"
  },
  {
    "district": "Porbandar",
    "state": "Gujarat",
    "pincode": "802525"
  },
  {
    "district": "Porbandar",
    "state": "Gujarat",
    "pincode": "802522"
  },
  {
    "district": "Porbandar",
    "state": "Gujarat",
    "pincode": "802524"
  },
  {
    "district": "Rajkot",
    "state": "Gujarat",
    "pincode": "802505"
  },
  {
    "district": "Rajkot",
    "state": "Gujarat",
    "pincode": "802507"
  },
  {
    "district": "Rajkot",
    "state": "Gujarat",
    "pincode": "802504"
  },
  {
    "district": "Rajkot",
    "state": "Gujarat",
    "pincode": "802503"
  },
  {
    "district": "Rajkot",
    "state": "Gujarat",
    "pincode": "802508"
  },
  {
    "district": "Rajkot",
    "state": "Gujarat",
    "pincode": "802498"
  },
  {
    "district": "Rajkot",
    "state": "Gujarat",
    "pincode": "802499"
  },
  {
    "district": "Rajkot",
    "state": "Gujarat",
    "pincode": "802501"
  },
  {
    "district": "Rajkot",
    "state": "Gujarat",
    "pincode": "802506"
  },
  {
    "district": "Rajkot",
    "state": "Gujarat",
    "pincode": "802500"
  },
  {
    "district": "Sabar Kantha",
    "state": "Gujarat",
    "pincode": "802473"
  },
  {
    "district": "Sabar Kantha",
    "state": "Gujarat",
    "pincode": "802469"
  },
  {
    "district": "Sabar Kantha",
    "state": "Gujarat",
    "pincode": "802468"
  },
  {
    "district": "Sabar Kantha",
    "state": "Gujarat",
    "pincode": "802466"
  },
  {
    "district": "Sabar Kantha",
    "state": "Gujarat",
    "pincode": "802472"
  },
  {
    "district": "Sabar Kantha",
    "state": "Gujarat",
    "pincode": "802470"
  },
  {
    "district": "Sabar Kantha",
    "state": "Gujarat",
    "pincode": "802471"
  },
  {
    "district": "Sabar Kantha",
    "state": "Gujarat",
    "pincode": "802467"
  },
  {
    "district": "Surat",
    "state": "Gujarat",
    "pincode": "802634"
  },
  {
    "district": "Surat",
    "state": "Gujarat",
    "pincode": "524261"
  },
  {
    "district": "Surat",
    "state": "Gujarat",
    "pincode": "802633"
  },
  {
    "district": "Surat",
    "state": "Gujarat",
    "pincode": "802628"
  },
  {
    "district": "Surat",
    "state": "Gujarat",
    "pincode": "524208"
  },
  {
    "district": "Surat",
    "state": "Gujarat",
    "pincode": "802629"
  },
  {
    "district": "Surat",
    "state": "Gujarat",
    "pincode": "802627"
  },
  {
    "district": "Surendranagar",
    "state": "Gujarat",
    "pincode": "802496"
  },
  {
    "district": "Surendranagar",
    "state": "Gujarat",
    "pincode": "802491"
  },
  {
    "district": "Surendranagar",
    "state": "Gujarat",
    "pincode": "802490"
  },
  {
    "district": "Surendranagar",
    "state": "Gujarat",
    "pincode": "802497"
  },
  {
    "district": "Surendranagar",
    "state": "Gujarat",
    "pincode": "802492"
  },
  {
    "district": "Surendranagar",
    "state": "Gujarat",
    "pincode": "802493"
  },
  {
    "district": "Surendranagar",
    "state": "Gujarat",
    "pincode": "802495"
  },
  {
    "district": "Surendranagar",
    "state": "Gujarat",
    "pincode": "802494"
  },
  {
    "district": "Tapi",
    "state": "Gujarat",
    "pincode": "802635"
  },
  {
    "district": "Tapi",
    "state": "Gujarat",
    "pincode": "802636"
  },
  {
    "district": "The Dangs",
    "state": "Gujarat",
    "pincode": "802613"
  },
  {
    "district": "Vadodara",
    "state": "Gujarat",
    "pincode": "802599"
  },
  {
    "district": "Vadodara",
    "state": "Gujarat",
    "pincode": "802600"
  },
  {
    "district": "Vadodara",
    "state": "Gujarat",
    "pincode": "802602"
  },
  {
    "district": "Vadodara",
    "state": "Gujarat",
    "pincode": "802601"
  },
  {
    "district": "Vadodara",
    "state": "Gujarat",
    "pincode": "802592"
  },
  {
    "district": "Vadodara",
    "state": "Gujarat",
    "pincode": "802596"
  },
  {
    "district": "Valsad",
    "state": "Gujarat",
    "pincode": "802620"
  },
  {
    "district": "Valsad",
    "state": "Gujarat",
    "pincode": "802621"
  },
  {
    "district": "Valsad",
    "state": "Gujarat",
    "pincode": "802625"
  },
  {
    "district": "Valsad",
    "state": "Gujarat",
    "pincode": "802618"
  },
  {
    "district": "Valsad",
    "state": "Gujarat",
    "pincode": "802622"
  },
  {
    "district": "Ambala",
    "state": "Haryana",
    "pincode": "800365"
  },
  {
    "district": "Ambala",
    "state": "Haryana",
    "pincode": "57652"
  },
  {
    "district": "Ambala",
    "state": "Haryana",
    "pincode": "800364"
  },
  {
    "district": "Bhiwani",
    "state": "Haryana",
    "pincode": "800408"
  },
  {
    "district": "Bhiwani",
    "state": "Haryana",
    "pincode": "800409"
  },
  {
    "district": "Bhiwani",
    "state": "Haryana",
    "pincode": "800412"
  },
  {
    "district": "Bhiwani",
    "state": "Haryana",
    "pincode": "800411"
  },
  {
    "district": "Bhiwani",
    "state": "Haryana",
    "pincode": "800410"
  },
  {
    "district": "Faridabad",
    "state": "Haryana",
    "pincode": "800436"
  },
  {
    "district": "Fatehabad",
    "state": "Haryana",
    "pincode": "60415"
  },
  {
    "district": "Fatehabad",
    "state": "Haryana",
    "pincode": "800398"
  },
  {
    "district": "Fatehabad",
    "state": "Haryana",
    "pincode": "800396"
  },
  {
    "district": "Fatehabad",
    "state": "Haryana",
    "pincode": "800397"
  },
  {
    "district": "Gurgaon",
    "state": "Haryana",
    "pincode": "800430"
  },
  {
    "district": "Gurgaon",
    "state": "Haryana",
    "pincode": "800429"
  },
  {
    "district": "Gurgaon",
    "state": "Haryana",
    "pincode": "800427"
  },
  {
    "district": "Gurgaon",
    "state": "Haryana",
    "pincode": "800428"
  },
  {
    "district": "Gurgaon",
    "state": "Haryana",
    "pincode": "800431"
  },
  {
    "district": "Hisar",
    "state": "Haryana",
    "pincode": "800404"
  },
  {
    "district": "Hisar",
    "state": "Haryana",
    "pincode": "800407"
  },
  {
    "district": "Hisar",
    "state": "Haryana",
    "pincode": "800405"
  },
  {
    "district": "Hisar",
    "state": "Haryana",
    "pincode": "800406"
  },
  {
    "district": "Hisar",
    "state": "Haryana",
    "pincode": "61014"
  },
  {
    "district": "Jhajjar",
    "state": "Haryana",
    "pincode": "800418"
  },
  {
    "district": "Jhajjar",
    "state": "Haryana",
    "pincode": "800417"
  },
  {
    "district": "Jhajjar",
    "state": "Haryana",
    "pincode": "800419"
  },
  {
    "district": "Jind",
    "state": "Haryana",
    "pincode": "800393"
  },
  {
    "district": "Jind",
    "state": "Haryana",
    "pincode": "800394"
  },
  {
    "district": "Jind",
    "state": "Haryana",
    "pincode": "800391"
  },
  {
    "district": "Jind",
    "state": "Haryana",
    "pincode": "800395"
  },
  {
    "district": "Jind",
    "state": "Haryana",
    "pincode": "800392"
  },
  {
    "district": "Kaithal",
    "state": "Haryana",
    "pincode": "800374"
  },
  {
    "district": "Kaithal",
    "state": "Haryana",
    "pincode": "800375"
  },
  {
    "district": "Kaithal",
    "state": "Haryana",
    "pincode": "800376"
  },
  {
    "district": "Kaithal",
    "state": "Haryana",
    "pincode": "800377"
  },
  {
    "district": "Kaithal",
    "state": "Haryana",
    "pincode": "58927"
  },
  {
    "district": "Karnal",
    "state": "Haryana",
    "pincode": "800383"
  },
  {
    "district": "Karnal",
    "state": "Haryana",
    "pincode": "800384"
  },
  {
    "district": "Karnal",
    "state": "Haryana",
    "pincode": "800380"
  },
  {
    "district": "Karnal",
    "state": "Haryana",
    "pincode": "800381"
  },
  {
    "district": "Karnal",
    "state": "Haryana",
    "pincode": "800378"
  },
  {
    "district": "Karnal",
    "state": "Haryana",
    "pincode": "800382"
  },
  {
    "district": "Karnal",
    "state": "Haryana",
    "pincode": "800379"
  },
  {
    "district": "Kurukshetra",
    "state": "Haryana",
    "pincode": "800373"
  },
  {
    "district": "Kurukshetra",
    "state": "Haryana",
    "pincode": "800371"
  },
  {
    "district": "Kurukshetra",
    "state": "Haryana",
    "pincode": "800370"
  },
  {
    "district": "Kurukshetra",
    "state": "Haryana",
    "pincode": "800372"
  },
  {
    "district": "Mahendragarh",
    "state": "Haryana",
    "pincode": "800422"
  },
  {
    "district": "Mahendragarh",
    "state": "Haryana",
    "pincode": "800420"
  },
  {
    "district": "Mahendragarh",
    "state": "Haryana",
    "pincode": "800421"
  },
  {
    "district": "Mahendragarh",
    "state": "Haryana",
    "pincode": "62334"
  },
  {
    "district": "Mahendragarh",
    "state": "Haryana",
    "pincode": "800423"
  },
  {
    "district": "Mewat",
    "state": "Haryana",
    "pincode": "800434"
  },
  {
    "district": "Mewat",
    "state": "Haryana",
    "pincode": "800433"
  },
  {
    "district": "Mewat",
    "state": "Haryana",
    "pincode": "800435"
  },
  {
    "district": "Mewat",
    "state": "Haryana",
    "pincode": "800432"
  },
  {
    "district": "Palwal",
    "state": "Haryana",
    "pincode": "800438"
  },
  {
    "district": "Palwal",
    "state": "Haryana",
    "pincode": "800440"
  },
  {
    "district": "Palwal",
    "state": "Haryana",
    "pincode": "800437"
  },
  {
    "district": "Panchkula",
    "state": "Haryana",
    "pincode": "800363"
  },
  {
    "district": "Panipat",
    "state": "Haryana",
    "pincode": "800385"
  },
  {
    "district": "Panipat",
    "state": "Haryana",
    "pincode": "800386"
  },
  {
    "district": "Rewari",
    "state": "Haryana",
    "pincode": "800426"
  },
  {
    "district": "Rewari",
    "state": "Haryana",
    "pincode": "800424"
  },
  {
    "district": "Rewari",
    "state": "Haryana",
    "pincode": "800425"
  },
  {
    "district": "Rohtak",
    "state": "Haryana",
    "pincode": "800415"
  },
  {
    "district": "Rohtak",
    "state": "Haryana",
    "pincode": "800413"
  },
  {
    "district": "Rohtak",
    "state": "Haryana",
    "pincode": "800414"
  },
  {
    "district": "Rohtak",
    "state": "Haryana",
    "pincode": "800416"
  },
  {
    "district": "Sirsa",
    "state": "Haryana",
    "pincode": "800403"
  },
  {
    "district": "Sirsa",
    "state": "Haryana",
    "pincode": "800400"
  },
  {
    "district": "Sirsa",
    "state": "Haryana",
    "pincode": "800399"
  },
  {
    "district": "Sirsa",
    "state": "Haryana",
    "pincode": "800402"
  },
  {
    "district": "Sirsa",
    "state": "Haryana",
    "pincode": "800401"
  },
  {
    "district": "Sonipat",
    "state": "Haryana",
    "pincode": "800388"
  },
  {
    "district": "Sonipat",
    "state": "Haryana",
    "pincode": "800387"
  },
  {
    "district": "Sonipat",
    "state": "Haryana",
    "pincode": "800390"
  },
  {
    "district": "Sonipat",
    "state": "Haryana",
    "pincode": "800389"
  },
  {
    "district": "Yamunanagar",
    "state": "Haryana",
    "pincode": "57964"
  },
  {
    "district": "Yamunanagar",
    "state": "Haryana",
    "pincode": "800369"
  },
  {
    "district": "Bilaspur",
    "state": "Himachal Pradesh",
    "pincode": "800121"
  },
  {
    "district": "Bilaspur",
    "state": "Himachal Pradesh",
    "pincode": "800118"
  },
  {
    "district": "Bilaspur",
    "state": "Himachal Pradesh",
    "pincode": "800120"
  },
  {
    "district": "Bilaspur",
    "state": "Himachal Pradesh",
    "pincode": "800119"
  },
  {
    "district": "Chamba",
    "state": "Himachal Pradesh",
    "pincode": "800091"
  },
  {
    "district": "Chamba",
    "state": "Himachal Pradesh",
    "pincode": "800090"
  },
  {
    "district": "Chamba",
    "state": "Himachal Pradesh",
    "pincode": "800088"
  },
  {
    "district": "Chamba",
    "state": "Himachal Pradesh",
    "pincode": "7015"
  },
  {
    "district": "Hamirpur",
    "state": "Himachal Pradesh",
    "pincode": "800112"
  },
  {
    "district": "Hamirpur",
    "state": "Himachal Pradesh",
    "pincode": "800111"
  },
  {
    "district": "Hamirpur",
    "state": "Himachal Pradesh",
    "pincode": "800110"
  },
  {
    "district": "Hamirpur",
    "state": "Himachal Pradesh",
    "pincode": "800109"
  },
  {
    "district": "Kangra",
    "state": "Himachal Pradesh",
    "pincode": "12047"
  },
  {
    "district": "Kangra",
    "state": "Himachal Pradesh",
    "pincode": "800093"
  },
  {
    "district": "Kangra",
    "state": "Himachal Pradesh",
    "pincode": "800098"
  },
  {
    "district": "Kangra",
    "state": "Himachal Pradesh",
    "pincode": "800095"
  },
  {
    "district": "Kangra",
    "state": "Himachal Pradesh",
    "pincode": "800096"
  },
  {
    "district": "Kangra",
    "state": "Himachal Pradesh",
    "pincode": "800092"
  },
  {
    "district": "Kangra",
    "state": "Himachal Pradesh",
    "pincode": "800099"
  },
  {
    "district": "Kullu",
    "state": "Himachal Pradesh",
    "pincode": "800103"
  },
  {
    "district": "Kullu",
    "state": "Himachal Pradesh",
    "pincode": "800102"
  },
  {
    "district": "Kullu",
    "state": "Himachal Pradesh",
    "pincode": "800101"
  },
  {
    "district": "Kullu",
    "state": "Himachal Pradesh",
    "pincode": "800100"
  },
  {
    "district": "Mandi",
    "state": "Himachal Pradesh",
    "pincode": "14116"
  },
  {
    "district": "Mandi",
    "state": "Himachal Pradesh",
    "pincode": "800104"
  },
  {
    "district": "Mandi",
    "state": "Himachal Pradesh",
    "pincode": "16138"
  },
  {
    "district": "Mandi",
    "state": "Himachal Pradesh",
    "pincode": "800107"
  },
  {
    "district": "Mandi",
    "state": "Himachal Pradesh",
    "pincode": "175008"
  },
  {
    "district": "Mandi",
    "state": "Himachal Pradesh",
    "pincode": "800108"
  },
  {
    "district": "Mandi",
    "state": "Himachal Pradesh",
    "pincode": "800105"
  },
  {
    "district": "Mandi",
    "state": "Himachal Pradesh",
    "pincode": "800106"
  },
  {
    "district": "Shimla",
    "state": "Himachal Pradesh",
    "pincode": "800139"
  },
  {
    "district": "Shimla",
    "state": "Himachal Pradesh",
    "pincode": "800140"
  },
  {
    "district": "Shimla",
    "state": "Himachal Pradesh",
    "pincode": "800141"
  },
  {
    "district": "Shimla",
    "state": "Himachal Pradesh",
    "pincode": "800134"
  },
  {
    "district": "Shimla",
    "state": "Himachal Pradesh",
    "pincode": "800133"
  },
  {
    "district": "Shimla",
    "state": "Himachal Pradesh",
    "pincode": "800142"
  },
  {
    "district": "Shimla",
    "state": "Himachal Pradesh",
    "pincode": "800135"
  },
  {
    "district": "Shimla",
    "state": "Himachal Pradesh",
    "pincode": "800137"
  },
  {
    "district": "Shimla",
    "state": "Himachal Pradesh",
    "pincode": "800138"
  },
  {
    "district": "Sirmaur",
    "state": "Himachal Pradesh",
    "pincode": "800131"
  },
  {
    "district": "Sirmaur",
    "state": "Himachal Pradesh",
    "pincode": "800132"
  },
  {
    "district": "Sirmaur",
    "state": "Himachal Pradesh",
    "pincode": "800130"
  },
  {
    "district": "Solan",
    "state": "Himachal Pradesh",
    "pincode": "800122"
  },
  {
    "district": "Solan",
    "state": "Himachal Pradesh",
    "pincode": "800124"
  },
  {
    "district": "Solan",
    "state": "Himachal Pradesh",
    "pincode": "800123"
  },
  {
    "district": "Solan",
    "state": "Himachal Pradesh",
    "pincode": "800126"
  },
  {
    "district": "Solan",
    "state": "Himachal Pradesh",
    "pincode": "800128"
  },
  {
    "district": "Una",
    "state": "Himachal Pradesh",
    "pincode": "800113"
  },
  {
    "district": "Una",
    "state": "Himachal Pradesh",
    "pincode": "800114"
  },
  {
    "district": "Una",
    "state": "Himachal Pradesh",
    "pincode": "800116"
  },
  {
    "district": "Una",
    "state": "Himachal Pradesh",
    "pincode": "800117"
  },
  {
    "district": "Una",
    "state": "Himachal Pradesh",
    "pincode": "21078"
  },
  {
    "district": "Una",
    "state": "Himachal Pradesh",
    "pincode": "800115"
  },
  {
    "district": "Anantnag",
    "state": "Jammu & Kashmir",
    "pincode": "800034"
  },
  {
    "district": "Anantnag",
    "state": "Jammu & Kashmir",
    "pincode": "800029"
  },
  {
    "district": "Anantnag",
    "state": "Jammu & Kashmir",
    "pincode": "800033"
  },
  {
    "district": "Anantnag",
    "state": "Jammu & Kashmir",
    "pincode": "800030"
  },
  {
    "district": "Anantnag",
    "state": "Jammu & Kashmir",
    "pincode": "800039"
  },
  {
    "district": "Anantnag",
    "state": "Jammu & Kashmir",
    "pincode": "800037"
  },
  {
    "district": "Anantnag",
    "state": "Jammu & Kashmir",
    "pincode": "800032"
  },
  {
    "district": "Anantnag",
    "state": "Jammu & Kashmir",
    "pincode": "800028"
  },
  {
    "district": "Anantnag",
    "state": "Jammu & Kashmir",
    "pincode": "800038"
  },
  {
    "district": "Anantnag",
    "state": "Jammu & Kashmir",
    "pincode": "800035"
  },
  {
    "district": "Badgam",
    "state": "Jammu & Kashmir",
    "pincode": "800019"
  },
  {
    "district": "Badgam",
    "state": "Jammu & Kashmir",
    "pincode": "800017"
  },
  {
    "district": "Badgam",
    "state": "Jammu & Kashmir",
    "pincode": "800020"
  },
  {
    "district": "Badgam",
    "state": "Jammu & Kashmir",
    "pincode": "800021"
  },
  {
    "district": "Badgam",
    "state": "Jammu & Kashmir",
    "pincode": "800018"
  },
  {
    "district": "Badgam",
    "state": "Jammu & Kashmir",
    "pincode": "800016"
  },
  {
    "district": "Bandipore",
    "state": "Jammu & Kashmir",
    "pincode": "800010"
  },
  {
    "district": "Bandipore",
    "state": "Jammu & Kashmir",
    "pincode": "800011"
  },
  {
    "district": "Bandipore",
    "state": "Jammu & Kashmir",
    "pincode": "800012"
  },
  {
    "district": "Baramula",
    "state": "Jammu & Kashmir",
    "pincode": "800006"
  },
  {
    "district": "Baramula",
    "state": "Jammu & Kashmir",
    "pincode": "800009"
  },
  {
    "district": "Baramula",
    "state": "Jammu & Kashmir",
    "pincode": "800008"
  },
  {
    "district": "Baramula",
    "state": "Jammu & Kashmir",
    "pincode": "800005"
  },
  {
    "district": "Baramula",
    "state": "Jammu & Kashmir",
    "pincode": "800003"
  },
  {
    "district": "Baramula",
    "state": "Jammu & Kashmir",
    "pincode": "800007"
  },
  {
    "district": "Baramula",
    "state": "Jammu & Kashmir",
    "pincode": "800004"
  },
  {
    "district": "Doda",
    "state": "Jammu & Kashmir",
    "pincode": "800050"
  },
  {
    "district": "Doda",
    "state": "Jammu & Kashmir",
    "pincode": "800049"
  },
  {
    "district": "Doda",
    "state": "Jammu & Kashmir",
    "pincode": "4250"
  },
  {
    "district": "Ganderbal",
    "state": "Jammu & Kashmir",
    "pincode": "800015"
  },
  {
    "district": "Jammu",
    "state": "Jammu & Kashmir",
    "pincode": "800069"
  },
  {
    "district": "Jammu",
    "state": "Jammu & Kashmir",
    "pincode": "800076"
  },
  {
    "district": "Jammu",
    "state": "Jammu & Kashmir",
    "pincode": "800075"
  },
  {
    "district": "Jammu",
    "state": "Jammu & Kashmir",
    "pincode": "800073"
  },
  {
    "district": "Jammu",
    "state": "Jammu & Kashmir",
    "pincode": "800071"
  },
  {
    "district": "Jammu",
    "state": "Jammu & Kashmir",
    "pincode": "800068"
  },
  {
    "district": "Jammu",
    "state": "Jammu & Kashmir",
    "pincode": "800070"
  },
  {
    "district": "Jammu",
    "state": "Jammu & Kashmir",
    "pincode": "800074"
  },
  {
    "district": "Kargil",
    "state": "Jammu & Kashmir",
    "pincode": "800048"
  },
  {
    "district": "Kathua",
    "state": "Jammu & Kashmir",
    "pincode": "800082"
  },
  {
    "district": "Kathua",
    "state": "Jammu & Kashmir",
    "pincode": "800081"
  },
  {
    "district": "Kathua",
    "state": "Jammu & Kashmir",
    "pincode": "800086"
  },
  {
    "district": "Kathua",
    "state": "Jammu & Kashmir",
    "pincode": "800084"
  },
  {
    "district": "Kathua",
    "state": "Jammu & Kashmir",
    "pincode": "800083"
  },
  {
    "district": "Kathua",
    "state": "Jammu & Kashmir",
    "pincode": "800085"
  },
  {
    "district": "Kishtwar",
    "state": "Jammu & Kashmir",
    "pincode": "800054"
  },
  {
    "district": "Kulgam",
    "state": "Jammu & Kashmir",
    "pincode": "800046"
  },
  {
    "district": "Kulgam",
    "state": "Jammu & Kashmir",
    "pincode": "800042"
  },
  {
    "district": "Kulgam",
    "state": "Jammu & Kashmir",
    "pincode": "800040"
  },
  {
    "district": "Kulgam",
    "state": "Jammu & Kashmir",
    "pincode": "800043"
  },
  {
    "district": "Kupwara",
    "state": "Jammu & Kashmir",
    "pincode": "800002"
  },
  {
    "district": "Kupwara",
    "state": "Jammu & Kashmir",
    "pincode": "800001"
  },
  {
    "district": "Kupwara",
    "state": "Jammu & Kashmir",
    "pincode": "207"
  },
  {
    "district": "Leh(Ladakh)",
    "state": "Jammu & Kashmir",
    "pincode": "800047"
  },
  {
    "district": "Pulwama",
    "state": "Jammu & Kashmir",
    "pincode": "800024"
  },
  {
    "district": "Pulwama",
    "state": "Jammu & Kashmir",
    "pincode": "800023"
  },
  {
    "district": "Pulwama",
    "state": "Jammu & Kashmir",
    "pincode": "800022"
  },
  {
    "district": "Pulwama",
    "state": "Jammu & Kashmir",
    "pincode": "800026"
  },
  {
    "district": "Pulwama",
    "state": "Jammu & Kashmir",
    "pincode": "800025"
  },
  {
    "district": "Punch",
    "state": "Jammu & Kashmir",
    "pincode": "800062"
  },
  {
    "district": "Punch",
    "state": "Jammu & Kashmir",
    "pincode": "800063"
  },
  {
    "district": "Rajouri",
    "state": "Jammu & Kashmir",
    "pincode": "1514"
  },
  {
    "district": "Rajouri",
    "state": "Jammu & Kashmir",
    "pincode": "800066"
  },
  {
    "district": "Rajouri",
    "state": "Jammu & Kashmir",
    "pincode": "800065"
  },
  {
    "district": "Rajouri",
    "state": "Jammu & Kashmir",
    "pincode": "800067"
  },
  {
    "district": "Rajouri",
    "state": "Jammu & Kashmir",
    "pincode": "800064"
  },
  {
    "district": "Ramban",
    "state": "Jammu & Kashmir",
    "pincode": "800051"
  },
  {
    "district": "Ramban",
    "state": "Jammu & Kashmir",
    "pincode": "800053"
  },
  {
    "district": "Ramban",
    "state": "Jammu & Kashmir",
    "pincode": "800052"
  },
  {
    "district": "Reasi",
    "state": "Jammu & Kashmir",
    "pincode": "800061"
  },
  {
    "district": "Reasi",
    "state": "Jammu & Kashmir",
    "pincode": "800059"
  },
  {
    "district": "Samba",
    "state": "Jammu & Kashmir",
    "pincode": "800077"
  },
  {
    "district": "Samba",
    "state": "Jammu & Kashmir",
    "pincode": "800080"
  },
  {
    "district": "Samba",
    "state": "Jammu & Kashmir",
    "pincode": "800079"
  },
  {
    "district": "Samba",
    "state": "Jammu & Kashmir",
    "pincode": "800078"
  },
  {
    "district": "Shupiyan",
    "state": "Jammu & Kashmir",
    "pincode": "800027"
  },
  {
    "district": "Srinagar",
    "state": "Jammu & Kashmir",
    "pincode": "800013"
  },
  {
    "district": "Udhampur",
    "state": "Jammu & Kashmir",
    "pincode": "800057"
  },
  {
    "district": "Udhampur",
    "state": "Jammu & Kashmir",
    "pincode": "800058"
  },
  {
    "district": "Udhampur",
    "state": "Jammu & Kashmir",
    "pincode": "800055"
  },
  {
    "district": "Bokaro",
    "state": "Jharkhand",
    "pincode": "801778"
  },
  {
    "district": "Bokaro",
    "state": "Jharkhand",
    "pincode": "362417"
  },
  {
    "district": "Bokaro",
    "state": "Jharkhand",
    "pincode": "801777"
  },
  {
    "district": "Chatra",
    "state": "Jharkhand",
    "pincode": "349494"
  },
  {
    "district": "Chatra",
    "state": "Jharkhand",
    "pincode": "801765"
  },
  {
    "district": "Deoghar",
    "state": "Jharkhand",
    "pincode": "801769"
  },
  {
    "district": "Deoghar",
    "state": "Jharkhand",
    "pincode": "801770"
  },
  {
    "district": "Dhanbad",
    "state": "Jharkhand",
    "pincode": "801776"
  },
  {
    "district": "Dhanbad",
    "state": "Jharkhand",
    "pincode": "801775"
  },
  {
    "district": "Dumka",
    "state": "Jharkhand",
    "pincode": "801790"
  },
  {
    "district": "Dumka",
    "state": "Jharkhand",
    "pincode": "801791"
  },
  {
    "district": "Garhwa",
    "state": "Jharkhand",
    "pincode": "801764"
  },
  {
    "district": "Garhwa",
    "state": "Jharkhand",
    "pincode": "801763"
  },
  {
    "district": "Garhwa",
    "state": "Jharkhand",
    "pincode": "347473"
  },
  {
    "district": "Giridih",
    "state": "Jharkhand",
    "pincode": "352566"
  },
  {
    "district": "Giridih",
    "state": "Jharkhand",
    "pincode": "351169"
  },
  {
    "district": "Giridih",
    "state": "Jharkhand",
    "pincode": "801768"
  },
  {
    "district": "Godda",
    "state": "Jharkhand",
    "pincode": "801771"
  },
  {
    "district": "Godda",
    "state": "Jharkhand",
    "pincode": "356840"
  },
  {
    "district": "Gumla",
    "state": "Jharkhand",
    "pincode": "801797"
  },
  {
    "district": "Hazaribagh",
    "state": "Jharkhand",
    "pincode": "801788"
  },
  {
    "district": "Jamtara",
    "state": "Jharkhand",
    "pincode": "801792"
  },
  {
    "district": "Jamtara",
    "state": "Jharkhand",
    "pincode": "801793"
  },
  {
    "district": "Khunti",
    "state": "Jharkhand",
    "pincode": "801796"
  },
  {
    "district": "Kodarma",
    "state": "Jharkhand",
    "pincode": "349865"
  },
  {
    "district": "Kodarma",
    "state": "Jharkhand",
    "pincode": "801767"
  },
  {
    "district": "Kodarma",
    "state": "Jharkhand",
    "pincode": "801766"
  },
  {
    "district": "Latehar",
    "state": "Jharkhand",
    "pincode": "801787"
  },
  {
    "district": "Lohardaga",
    "state": "Jharkhand",
    "pincode": "801779"
  },
  {
    "district": "Pakur",
    "state": "Jharkhand",
    "pincode": "801774"
  },
  {
    "district": "Palamu",
    "state": "Jharkhand",
    "pincode": "801785"
  },
  {
    "district": "Palamu",
    "state": "Jharkhand",
    "pincode": "801784"
  },
  {
    "district": "Palamu",
    "state": "Jharkhand",
    "pincode": "801786"
  },
  {
    "district": "Palamu",
    "state": "Jharkhand",
    "pincode": "365336"
  },
  {
    "district": "Pashchimi Singhbhum",
    "state": "Jharkhand",
    "pincode": "801800"
  },
  {
    "district": "Pashchimi Singhbhum",
    "state": "Jharkhand",
    "pincode": "801799"
  },
  {
    "district": "Purbi Singhbhum",
    "state": "Jharkhand",
    "pincode": "801801"
  },
  {
    "district": "Purbi Singhbhum",
    "state": "Jharkhand",
    "pincode": "801783"
  },
  {
    "district": "Purbi Singhbhum",
    "state": "Jharkhand",
    "pincode": "801781"
  },
  {
    "district": "Purbi Singhbhum",
    "state": "Jharkhand",
    "pincode": "801782"
  },
  {
    "district": "Purbi Singhbhum",
    "state": "Jharkhand",
    "pincode": "801780"
  },
  {
    "district": "Ramgarh",
    "state": "Jharkhand",
    "pincode": "369185"
  },
  {
    "district": "Ramgarh",
    "state": "Jharkhand",
    "pincode": "801789"
  },
  {
    "district": "Ranchi",
    "state": "Jharkhand",
    "pincode": "801795"
  },
  {
    "district": "Ranchi",
    "state": "Jharkhand",
    "pincode": "801794"
  },
  {
    "district": "Sahibganj",
    "state": "Jharkhand",
    "pincode": "359741"
  },
  {
    "district": "Sahibganj",
    "state": "Jharkhand",
    "pincode": "801773"
  },
  {
    "district": "Sahibganj",
    "state": "Jharkhand",
    "pincode": "801772"
  },
  {
    "district": "Saraikela-Kharsawan",
    "state": "Jharkhand",
    "pincode": "378867"
  },
  {
    "district": "Saraikela-Kharsawan",
    "state": "Jharkhand",
    "pincode": "801802"
  },
  {
    "district": "Simdega",
    "state": "Jharkhand",
    "pincode": "801798"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "599027"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803046"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803048"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "598550"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803043"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803047"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803049"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803050"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803041"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "599026"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803045"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803039"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803044"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803042"
  },
  {
    "district": "Bagalkot",
    "state": "Karnataka",
    "pincode": "803040"
  },
  {
    "district": "Bangalore",
    "state": "Karnataka",
    "pincode": "803163"
  },
  {
    "district": "Bangalore",
    "state": "Karnataka",
    "pincode": "613359"
  },
  {
    "district": "Bangalore",
    "state": "Karnataka",
    "pincode": "803162"
  },
  {
    "district": "Bangalore",
    "state": "Karnataka",
    "pincode": "613357"
  },
  {
    "district": "Bangalore",
    "state": "Karnataka",
    "pincode": "613261"
  },
  {
    "district": "Bangalore",
    "state": "Karnataka",
    "pincode": "613355"
  },
  {
    "district": "Bangalore",
    "state": "Karnataka",
    "pincode": "613360"
  },
  {
    "district": "Bangalore Rural",
    "state": "Karnataka",
    "pincode": "803235"
  },
  {
    "district": "Bangalore Rural",
    "state": "Karnataka",
    "pincode": "803233"
  },
  {
    "district": "Bangalore Rural",
    "state": "Karnataka",
    "pincode": "803236"
  },
  {
    "district": "Bangalore Rural",
    "state": "Karnataka",
    "pincode": "803232"
  },
  {
    "district": "Bangalore Rural",
    "state": "Karnataka",
    "pincode": "803234"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597294"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597444"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803024"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803036"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803033"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597150"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803023"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597359"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597175"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803030"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597392"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803032"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597240"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597429"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597382"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803035"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "598110"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803028"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803025"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "598073"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597435"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "598256"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803027"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597403"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597416"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803021"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803038"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803026"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803022"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803031"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "803037"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597286"
  },
  {
    "district": "Belgaum",
    "state": "Karnataka",
    "pincode": "597284"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "803114"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "604675"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "803108"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "803109"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "803110"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "803111"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "803117"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "604875"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "803116"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "605047"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "604870"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "604702"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "803115"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "803112"
  },
  {
    "district": "Bellary",
    "state": "Karnataka",
    "pincode": "803113"
  },
  {
    "district": "Bidar",
    "state": "Karnataka",
    "pincode": "803059"
  },
  {
    "district": "Bidar",
    "state": "Karnataka",
    "pincode": "803057"
  },
  {
    "district": "Bidar",
    "state": "Karnataka",
    "pincode": "803058"
  },
  {
    "district": "Bidar",
    "state": "Karnataka",
    "pincode": "803060"
  },
  {
    "district": "Bidar",
    "state": "Karnataka",
    "pincode": "803062"
  },
  {
    "district": "Bidar",
    "state": "Karnataka",
    "pincode": "600276"
  },
  {
    "district": "Bidar",
    "state": "Karnataka",
    "pincode": "803061"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "599304"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "803054"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "803051"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "599171"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "599401"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "803052"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "599454"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "599495"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "803055"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "599676"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "599494"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "803053"
  },
  {
    "district": "Bijapur",
    "state": "Karnataka",
    "pincode": "803056"
  },
  {
    "district": "Chamarajanagar",
    "state": "Karnataka",
    "pincode": "803201"
  },
  {
    "district": "Chamarajanagar",
    "state": "Karnataka",
    "pincode": "803200"
  },
  {
    "district": "Chamarajanagar",
    "state": "Karnataka",
    "pincode": "803204"
  },
  {
    "district": "Chamarajanagar",
    "state": "Karnataka",
    "pincode": "803203"
  },
  {
    "district": "Chamarajanagar",
    "state": "Karnataka",
    "pincode": "803202"
  },
  {
    "district": "Chikkaballapura",
    "state": "Karnataka",
    "pincode": "803229"
  },
  {
    "district": "Chikkaballapura",
    "state": "Karnataka",
    "pincode": "803227"
  },
  {
    "district": "Chikkaballapura",
    "state": "Karnataka",
    "pincode": "803231"
  },
  {
    "district": "Chikkaballapura",
    "state": "Karnataka",
    "pincode": "803226"
  },
  {
    "district": "Chikkaballapura",
    "state": "Karnataka",
    "pincode": "803228"
  },
  {
    "district": "Chikkaballapura",
    "state": "Karnataka",
    "pincode": "803230"
  },
  {
    "district": "Chikmagalur",
    "state": "Karnataka",
    "pincode": "803147"
  },
  {
    "district": "Chikmagalur",
    "state": "Karnataka",
    "pincode": "803149"
  },
  {
    "district": "Chikmagalur",
    "state": "Karnataka",
    "pincode": "803148"
  },
  {
    "district": "Chikmagalur",
    "state": "Karnataka",
    "pincode": "803144"
  },
  {
    "district": "Chikmagalur",
    "state": "Karnataka",
    "pincode": "803150"
  },
  {
    "district": "Chikmagalur",
    "state": "Karnataka",
    "pincode": "803145"
  },
  {
    "district": "Chikmagalur",
    "state": "Karnataka",
    "pincode": "803143"
  },
  {
    "district": "Chikmagalur",
    "state": "Karnataka",
    "pincode": "803146"
  },
  {
    "district": "Chitradurga",
    "state": "Karnataka",
    "pincode": "803119"
  },
  {
    "district": "Chitradurga",
    "state": "Karnataka",
    "pincode": "803120"
  },
  {
    "district": "Chitradurga",
    "state": "Karnataka",
    "pincode": "803123"
  },
  {
    "district": "Chitradurga",
    "state": "Karnataka",
    "pincode": "803121"
  },
  {
    "district": "Chitradurga",
    "state": "Karnataka",
    "pincode": "803122"
  },
  {
    "district": "Chitradurga",
    "state": "Karnataka",
    "pincode": "803118"
  },
  {
    "district": "Chitradurga",
    "state": "Karnataka",
    "pincode": "605249"
  },
  {
    "district": "Dakshina Kannada",
    "state": "Karnataka",
    "pincode": "803183"
  },
  {
    "district": "Dakshina Kannada",
    "state": "Karnataka",
    "pincode": "803184"
  },
  {
    "district": "Dakshina Kannada",
    "state": "Karnataka",
    "pincode": "617518"
  },
  {
    "district": "Dakshina Kannada",
    "state": "Karnataka",
    "pincode": "803181"
  },
  {
    "district": "Dakshina Kannada",
    "state": "Karnataka",
    "pincode": "803180"
  },
  {
    "district": "Dakshina Kannada",
    "state": "Karnataka",
    "pincode": "803179"
  },
  {
    "district": "Dakshina Kannada",
    "state": "Karnataka",
    "pincode": "803185"
  },
  {
    "district": "Dakshina Kannada",
    "state": "Karnataka",
    "pincode": "803186"
  },
  {
    "district": "Dakshina Kannada",
    "state": "Karnataka",
    "pincode": "803182"
  },
  {
    "district": "Dakshina Kannada",
    "state": "Karnataka",
    "pincode": "617605"
  },
  {
    "district": "Davanagere",
    "state": "Karnataka",
    "pincode": "803129"
  },
  {
    "district": "Davanagere",
    "state": "Karnataka",
    "pincode": "803127"
  },
  {
    "district": "Davanagere",
    "state": "Karnataka",
    "pincode": "803125"
  },
  {
    "district": "Davanagere",
    "state": "Karnataka",
    "pincode": "803124"
  },
  {
    "district": "Davanagere",
    "state": "Karnataka",
    "pincode": "803128"
  },
  {
    "district": "Davanagere",
    "state": "Karnataka",
    "pincode": "803126"
  },
  {
    "district": "Davanagere",
    "state": "Karnataka",
    "pincode": "606238"
  },
  {
    "district": "Dharwad",
    "state": "Karnataka",
    "pincode": "803084"
  },
  {
    "district": "Dharwad",
    "state": "Karnataka",
    "pincode": "803086"
  },
  {
    "district": "Dharwad",
    "state": "Karnataka",
    "pincode": "803083"
  },
  {
    "district": "Dharwad",
    "state": "Karnataka",
    "pincode": "803087"
  },
  {
    "district": "Dharwad",
    "state": "Karnataka",
    "pincode": "803088"
  },
  {
    "district": "Dharwad",
    "state": "Karnataka",
    "pincode": "803085"
  },
  {
    "district": "Gadag",
    "state": "Karnataka",
    "pincode": "803078"
  },
  {
    "district": "Gadag",
    "state": "Karnataka",
    "pincode": "803076"
  },
  {
    "district": "Gadag",
    "state": "Karnataka",
    "pincode": "803081"
  },
  {
    "district": "Gadag",
    "state": "Karnataka",
    "pincode": "803079"
  },
  {
    "district": "Gadag",
    "state": "Karnataka",
    "pincode": "803082"
  },
  {
    "district": "Gadag",
    "state": "Karnataka",
    "pincode": "803077"
  },
  {
    "district": "Gadag",
    "state": "Karnataka",
    "pincode": "803074"
  },
  {
    "district": "Gadag",
    "state": "Karnataka",
    "pincode": "803075"
  },
  {
    "district": "Gadag",
    "state": "Karnataka",
    "pincode": "803080"
  },
  {
    "district": "Gulbarga",
    "state": "Karnataka",
    "pincode": "803206"
  },
  {
    "district": "Gulbarga",
    "state": "Karnataka",
    "pincode": "803205"
  },
  {
    "district": "Gulbarga",
    "state": "Karnataka",
    "pincode": "803208"
  },
  {
    "district": "Gulbarga",
    "state": "Karnataka",
    "pincode": "803210"
  },
  {
    "district": "Gulbarga",
    "state": "Karnataka",
    "pincode": "803207"
  },
  {
    "district": "Gulbarga",
    "state": "Karnataka",
    "pincode": "803214"
  },
  {
    "district": "Gulbarga",
    "state": "Karnataka",
    "pincode": "803209"
  },
  {
    "district": "Gulbarga",
    "state": "Karnataka",
    "pincode": "803212"
  },
  {
    "district": "Gulbarga",
    "state": "Karnataka",
    "pincode": "803213"
  },
  {
    "district": "Hassan",
    "state": "Karnataka",
    "pincode": "803175"
  },
  {
    "district": "Hassan",
    "state": "Karnataka",
    "pincode": "803176"
  },
  {
    "district": "Hassan",
    "state": "Karnataka",
    "pincode": "803173"
  },
  {
    "district": "Hassan",
    "state": "Karnataka",
    "pincode": "803172"
  },
  {
    "district": "Hassan",
    "state": "Karnataka",
    "pincode": "803178"
  },
  {
    "district": "Hassan",
    "state": "Karnataka",
    "pincode": "803174"
  },
  {
    "district": "Hassan",
    "state": "Karnataka",
    "pincode": "803177"
  },
  {
    "district": "Hassan",
    "state": "Karnataka",
    "pincode": "803171"
  },
  {
    "district": "Haveri",
    "state": "Karnataka",
    "pincode": "803101"
  },
  {
    "district": "Haveri",
    "state": "Karnataka",
    "pincode": "803105"
  },
  {
    "district": "Haveri",
    "state": "Karnataka",
    "pincode": "604269"
  },
  {
    "district": "Haveri",
    "state": "Karnataka",
    "pincode": "803103"
  },
  {
    "district": "Haveri",
    "state": "Karnataka",
    "pincode": "803104"
  },
  {
    "district": "Haveri",
    "state": "Karnataka",
    "pincode": "803106"
  },
  {
    "district": "Haveri",
    "state": "Karnataka",
    "pincode": "803107"
  },
  {
    "district": "Haveri",
    "state": "Karnataka",
    "pincode": "803102"
  },
  {
    "district": "Haveri",
    "state": "Karnataka",
    "pincode": "803100"
  },
  {
    "district": "Kodagu",
    "state": "Karnataka",
    "pincode": "803189"
  },
  {
    "district": "Kodagu",
    "state": "Karnataka",
    "pincode": "803187"
  },
  {
    "district": "Kodagu",
    "state": "Karnataka",
    "pincode": "803188"
  },
  {
    "district": "Kodagu",
    "state": "Karnataka",
    "pincode": "803190"
  },
  {
    "district": "Kolar",
    "state": "Karnataka",
    "pincode": "803223"
  },
  {
    "district": "Kolar",
    "state": "Karnataka",
    "pincode": "803221"
  },
  {
    "district": "Kolar",
    "state": "Karnataka",
    "pincode": "803222"
  },
  {
    "district": "Kolar",
    "state": "Karnataka",
    "pincode": "803225"
  },
  {
    "district": "Kolar",
    "state": "Karnataka",
    "pincode": "803224"
  },
  {
    "district": "Kolar",
    "state": "Karnataka",
    "pincode": "803220"
  },
  {
    "district": "Koppal",
    "state": "Karnataka",
    "pincode": "803072"
  },
  {
    "district": "Koppal",
    "state": "Karnataka",
    "pincode": "601580"
  },
  {
    "district": "Koppal",
    "state": "Karnataka",
    "pincode": "601681"
  },
  {
    "district": "Koppal",
    "state": "Karnataka",
    "pincode": "803073"
  },
  {
    "district": "Koppal",
    "state": "Karnataka",
    "pincode": "601348"
  },
  {
    "district": "Koppal",
    "state": "Karnataka",
    "pincode": "803071"
  },
  {
    "district": "Koppal",
    "state": "Karnataka",
    "pincode": "601532"
  },
  {
    "district": "Koppal",
    "state": "Karnataka",
    "pincode": "803070"
  },
  {
    "district": "Mandya",
    "state": "Karnataka",
    "pincode": "613710"
  },
  {
    "district": "Mandya",
    "state": "Karnataka",
    "pincode": "803164"
  },
  {
    "district": "Mandya",
    "state": "Karnataka",
    "pincode": "803169"
  },
  {
    "district": "Mandya",
    "state": "Karnataka",
    "pincode": "803170"
  },
  {
    "district": "Mandya",
    "state": "Karnataka",
    "pincode": "803168"
  },
  {
    "district": "Mandya",
    "state": "Karnataka",
    "pincode": "803165"
  },
  {
    "district": "Mandya",
    "state": "Karnataka",
    "pincode": "803166"
  },
  {
    "district": "Mandya",
    "state": "Karnataka",
    "pincode": "803167"
  },
  {
    "district": "Mysore",
    "state": "Karnataka",
    "pincode": "803198"
  },
  {
    "district": "Mysore",
    "state": "Karnataka",
    "pincode": "803195"
  },
  {
    "district": "Mysore",
    "state": "Karnataka",
    "pincode": "803192"
  },
  {
    "district": "Mysore",
    "state": "Karnataka",
    "pincode": "803193"
  },
  {
    "district": "Mysore",
    "state": "Karnataka",
    "pincode": "803194"
  },
  {
    "district": "Mysore",
    "state": "Karnataka",
    "pincode": "803197"
  },
  {
    "district": "Mysore",
    "state": "Karnataka",
    "pincode": "803191"
  },
  {
    "district": "Mysore",
    "state": "Karnataka",
    "pincode": "803196"
  },
  {
    "district": "Mysore",
    "state": "Karnataka",
    "pincode": "803199"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "601135"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "803066"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "600535"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "600941"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "803064"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "803068"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "600520"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "803063"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "803067"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "803069"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "600967"
  },
  {
    "district": "Raichur",
    "state": "Karnataka",
    "pincode": "601074"
  },
  {
    "district": "Ramanagara",
    "state": "Karnataka",
    "pincode": "626186"
  },
  {
    "district": "Ramanagara",
    "state": "Karnataka",
    "pincode": "803239"
  },
  {
    "district": "Ramanagara",
    "state": "Karnataka",
    "pincode": "803240"
  },
  {
    "district": "Ramanagara",
    "state": "Karnataka",
    "pincode": "803237"
  },
  {
    "district": "Ramanagara",
    "state": "Karnataka",
    "pincode": "803238"
  },
  {
    "district": "Shimoga",
    "state": "Karnataka",
    "pincode": "803138"
  },
  {
    "district": "Shimoga",
    "state": "Karnataka",
    "pincode": "803135"
  },
  {
    "district": "Shimoga",
    "state": "Karnataka",
    "pincode": "803130"
  },
  {
    "district": "Shimoga",
    "state": "Karnataka",
    "pincode": "803131"
  },
  {
    "district": "Shimoga",
    "state": "Karnataka",
    "pincode": "803134"
  },
  {
    "district": "Shimoga",
    "state": "Karnataka",
    "pincode": "803137"
  },
  {
    "district": "Shimoga",
    "state": "Karnataka",
    "pincode": "803133"
  },
  {
    "district": "Shimoga",
    "state": "Karnataka",
    "pincode": "803132"
  },
  {
    "district": "Shimoga",
    "state": "Karnataka",
    "pincode": "803136"
  },
  {
    "district": "Tumakuru",
    "state": "Karnataka",
    "pincode": "610265"
  },
  {
    "district": "Tumkur",
    "state": "Karnataka",
    "pincode": "803152"
  },
  {
    "district": "Tumkur",
    "state": "Karnataka",
    "pincode": "803158"
  },
  {
    "district": "Tumkur",
    "state": "Karnataka",
    "pincode": "803156"
  },
  {
    "district": "Tumkur",
    "state": "Karnataka",
    "pincode": "803161"
  },
  {
    "district": "Tumkur",
    "state": "Karnataka",
    "pincode": "803155"
  },
  {
    "district": "Tumkur",
    "state": "Karnataka",
    "pincode": "803154"
  },
  {
    "district": "Tumkur",
    "state": "Karnataka",
    "pincode": "803153"
  },
  {
    "district": "Tumkur",
    "state": "Karnataka",
    "pincode": "803159"
  },
  {
    "district": "Tumkur",
    "state": "Karnataka",
    "pincode": "803157"
  },
  {
    "district": "Tumkur",
    "state": "Karnataka",
    "pincode": "803160"
  },
  {
    "district": "Udupi",
    "state": "Karnataka",
    "pincode": "608832"
  },
  {
    "district": "Udupi",
    "state": "Karnataka",
    "pincode": "803142"
  },
  {
    "district": "Udupi",
    "state": "Karnataka",
    "pincode": "803139"
  },
  {
    "district": "Udupi",
    "state": "Karnataka",
    "pincode": "803140"
  },
  {
    "district": "Udupi",
    "state": "Karnataka",
    "pincode": "803141"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803095"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803099"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803089"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803091"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803098"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "603878"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803090"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803096"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803093"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803097"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803094"
  },
  {
    "district": "Uttara Kannada",
    "state": "Karnataka",
    "pincode": "803092"
  },
  {
    "district": "Yadgir",
    "state": "Karnataka",
    "pincode": "620937"
  },
  {
    "district": "Yadgir",
    "state": "Karnataka",
    "pincode": "803218"
  },
  {
    "district": "Yadgir",
    "state": "Karnataka",
    "pincode": "621025"
  },
  {
    "district": "Yadgir",
    "state": "Karnataka",
    "pincode": "620889"
  },
  {
    "district": "Yadgir",
    "state": "Karnataka",
    "pincode": "803217"
  },
  {
    "district": "Yadgir",
    "state": "Karnataka",
    "pincode": "803215"
  },
  {
    "district": "Yadgir",
    "state": "Karnataka",
    "pincode": "803219"
  },
  {
    "district": "Alappuzha",
    "state": "Kerala",
    "pincode": "803299"
  },
  {
    "district": "Alappuzha",
    "state": "Kerala",
    "pincode": "803301"
  },
  {
    "district": "Alappuzha",
    "state": "Kerala",
    "pincode": "803298"
  },
  {
    "district": "Alappuzha",
    "state": "Kerala",
    "pincode": "628256"
  },
  {
    "district": "Alappuzha",
    "state": "Kerala",
    "pincode": "803300"
  },
  {
    "district": "Alappuzha",
    "state": "Kerala",
    "pincode": "803302"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "803286"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "803285"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "627992"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "803289"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "803288"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "628035"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "803292"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "628015"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "803291"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "803287"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "803284"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "628031"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "628002"
  },
  {
    "district": "Ernakulam",
    "state": "Kerala",
    "pincode": "803290"
  },
  {
    "district": "Idukki",
    "state": "Kerala",
    "pincode": "628079"
  },
  {
    "district": "Idukki",
    "state": "Kerala",
    "pincode": "803293"
  },
  {
    "district": "Kannur",
    "state": "Kerala",
    "pincode": "900023"
  },
  {
    "district": "Kannur",
    "state": "Kerala",
    "pincode": "900025"
  },
  {
    "district": "Kannur",
    "state": "Kerala",
    "pincode": "803259"
  },
  {
    "district": "Kannur",
    "state": "Kerala",
    "pincode": "803262"
  },
  {
    "district": "Kannur",
    "state": "Kerala",
    "pincode": "803261"
  },
  {
    "district": "Kannur",
    "state": "Kerala",
    "pincode": "627288"
  },
  {
    "district": "Kannur",
    "state": "Kerala",
    "pincode": "803257"
  },
  {
    "district": "Kannur",
    "state": "Kerala",
    "pincode": "627205"
  },
  {
    "district": "Kannur",
    "state": "Kerala",
    "pincode": "803258"
  },
  {
    "district": "Kannur",
    "state": "Kerala",
    "pincode": "803263"
  },
  {
    "district": "Kasaragod",
    "state": "Kerala",
    "pincode": "803256"
  },
  {
    "district": "Kasaragod",
    "state": "Kerala",
    "pincode": "803255"
  },
  {
    "district": "Kasaragod",
    "state": "Kerala",
    "pincode": "627175"
  },
  {
    "district": "Kollam",
    "state": "Kerala",
    "pincode": "628368"
  },
  {
    "district": "Kollam",
    "state": "Kerala",
    "pincode": "803306"
  },
  {
    "district": "Kollam",
    "state": "Kerala",
    "pincode": "628430"
  },
  {
    "district": "Kollam",
    "state": "Kerala",
    "pincode": "803308"
  },
  {
    "district": "Kollam",
    "state": "Kerala",
    "pincode": "803307"
  },
  {
    "district": "Kottayam",
    "state": "Kerala",
    "pincode": "803297"
  },
  {
    "district": "Kottayam",
    "state": "Kerala",
    "pincode": "628138"
  },
  {
    "district": "Kottayam",
    "state": "Kerala",
    "pincode": "628171"
  },
  {
    "district": "Kottayam",
    "state": "Kerala",
    "pincode": "803296"
  },
  {
    "district": "Kottayam",
    "state": "Kerala",
    "pincode": "803294"
  },
  {
    "district": "Kottayam",
    "state": "Kerala",
    "pincode": "803295"
  },
  {
    "district": "Kozhikode",
    "state": "Kerala",
    "pincode": "627439"
  },
  {
    "district": "Kozhikode",
    "state": "Kerala",
    "pincode": "627440"
  },
  {
    "district": "Kozhikode",
    "state": "Kerala",
    "pincode": "803267"
  },
  {
    "district": "Kozhikode",
    "state": "Kerala",
    "pincode": "900026"
  },
  {
    "district": "Kozhikode",
    "state": "Kerala",
    "pincode": "627367"
  },
  {
    "district": "Kozhikode",
    "state": "Kerala",
    "pincode": "803266"
  },
  {
    "district": "Kozhikode",
    "state": "Kerala",
    "pincode": "627435"
  },
  {
    "district": "Kozhikode",
    "state": "Kerala",
    "pincode": "803265"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "627468"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "627527"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "803269"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "803268"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "627488"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "627540"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "803270"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "803272"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "627512"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "803271"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "627553"
  },
  {
    "district": "Malappuram",
    "state": "Kerala",
    "pincode": "900027"
  },
  {
    "district": "Palakkad",
    "state": "Kerala",
    "pincode": "900024"
  },
  {
    "district": "Palakkad",
    "state": "Kerala",
    "pincode": "803276"
  },
  {
    "district": "Palakkad",
    "state": "Kerala",
    "pincode": "627627"
  },
  {
    "district": "Palakkad",
    "state": "Kerala",
    "pincode": "803274"
  },
  {
    "district": "Palakkad",
    "state": "Kerala",
    "pincode": "803275"
  },
  {
    "district": "Palakkad",
    "state": "Kerala",
    "pincode": "627598"
  },
  {
    "district": "Palakkad",
    "state": "Kerala",
    "pincode": "803273"
  },
  {
    "district": "Pathanamthitta",
    "state": "Kerala",
    "pincode": "803305"
  },
  {
    "district": "Pathanamthitta",
    "state": "Kerala",
    "pincode": "628343"
  },
  {
    "district": "Pathanamthitta",
    "state": "Kerala",
    "pincode": "803304"
  },
  {
    "district": "Pathanamthitta",
    "state": "Kerala",
    "pincode": "803303"
  },
  {
    "district": "Thiruvananthapuram",
    "state": "Kerala",
    "pincode": "803310"
  },
  {
    "district": "Thiruvananthapuram",
    "state": "Kerala",
    "pincode": "803311"
  },
  {
    "district": "Thiruvananthapuram",
    "state": "Kerala",
    "pincode": "803313"
  },
  {
    "district": "Thiruvananthapuram",
    "state": "Kerala",
    "pincode": "803312"
  },
  {
    "district": "Thiruvananthapuram",
    "state": "Kerala",
    "pincode": "803309"
  },
  {
    "district": "Thrissur",
    "state": "Kerala",
    "pincode": "803283"
  },
  {
    "district": "Thrissur",
    "state": "Kerala",
    "pincode": "803279"
  },
  {
    "district": "Thrissur",
    "state": "Kerala",
    "pincode": "803278"
  },
  {
    "district": "Thrissur",
    "state": "Kerala",
    "pincode": "803282"
  },
  {
    "district": "Thrissur",
    "state": "Kerala",
    "pincode": "803281"
  },
  {
    "district": "Thrissur",
    "state": "Kerala",
    "pincode": "803277"
  },
  {
    "district": "Thrissur",
    "state": "Kerala",
    "pincode": "803280"
  },
  {
    "district": "Thrissur",
    "state": "Kerala",
    "pincode": "627770"
  },
  {
    "district": "Wayanad",
    "state": "Kerala",
    "pincode": "803264"
  },
  {
    "district": "Wayanad",
    "state": "Kerala",
    "pincode": "627296"
  },
  {
    "district": "Wayanad",
    "state": "Kerala",
    "pincode": "627317"
  },
  {
    "district": "Alirajpur",
    "state": "Madhya Pradesh",
    "pincode": "802433"
  },
  {
    "district": "Alirajpur",
    "state": "Madhya Pradesh",
    "pincode": "802431"
  },
  {
    "district": "Alirajpur",
    "state": "Madhya Pradesh",
    "pincode": "802432"
  },
  {
    "district": "Anuppur",
    "state": "Madhya Pradesh",
    "pincode": "802421"
  },
  {
    "district": "Anuppur",
    "state": "Madhya Pradesh",
    "pincode": "802419"
  },
  {
    "district": "Anuppur",
    "state": "Madhya Pradesh",
    "pincode": "802416"
  },
  {
    "district": "Anuppur",
    "state": "Madhya Pradesh",
    "pincode": "802420"
  },
  {
    "district": "Anuppur",
    "state": "Madhya Pradesh",
    "pincode": "802417"
  },
  {
    "district": "Anuppur",
    "state": "Madhya Pradesh",
    "pincode": "802418"
  },
  {
    "district": "Ashoknagar",
    "state": "Madhya Pradesh",
    "pincode": "802408"
  },
  {
    "district": "Ashoknagar",
    "state": "Madhya Pradesh",
    "pincode": "802407"
  },
  {
    "district": "Ashoknagar",
    "state": "Madhya Pradesh",
    "pincode": "802406"
  },
  {
    "district": "Ashoknagar",
    "state": "Madhya Pradesh",
    "pincode": "802409"
  },
  {
    "district": "Ashoknagar",
    "state": "Madhya Pradesh",
    "pincode": "500585"
  },
  {
    "district": "Balaghat",
    "state": "Madhya Pradesh",
    "pincode": "802398"
  },
  {
    "district": "Balaghat",
    "state": "Madhya Pradesh",
    "pincode": "802397"
  },
  {
    "district": "Balaghat",
    "state": "Madhya Pradesh",
    "pincode": "802395"
  },
  {
    "district": "Balaghat",
    "state": "Madhya Pradesh",
    "pincode": "802400"
  },
  {
    "district": "Balaghat",
    "state": "Madhya Pradesh",
    "pincode": "802399"
  },
  {
    "district": "Balaghat",
    "state": "Madhya Pradesh",
    "pincode": "802396"
  },
  {
    "district": "Barwani",
    "state": "Madhya Pradesh",
    "pincode": "802286"
  },
  {
    "district": "Barwani",
    "state": "Madhya Pradesh",
    "pincode": "802285"
  },
  {
    "district": "Barwani",
    "state": "Madhya Pradesh",
    "pincode": "802290"
  },
  {
    "district": "Barwani",
    "state": "Madhya Pradesh",
    "pincode": "802288"
  },
  {
    "district": "Barwani",
    "state": "Madhya Pradesh",
    "pincode": "802289"
  },
  {
    "district": "Barwani",
    "state": "Madhya Pradesh",
    "pincode": "802287"
  },
  {
    "district": "Barwani",
    "state": "Madhya Pradesh",
    "pincode": "802291"
  },
  {
    "district": "Betul",
    "state": "Madhya Pradesh",
    "pincode": "802340"
  },
  {
    "district": "Betul",
    "state": "Madhya Pradesh",
    "pincode": "802334"
  },
  {
    "district": "Betul",
    "state": "Madhya Pradesh",
    "pincode": "802335"
  },
  {
    "district": "Betul",
    "state": "Madhya Pradesh",
    "pincode": "802336"
  },
  {
    "district": "Betul",
    "state": "Madhya Pradesh",
    "pincode": "802333"
  },
  {
    "district": "Betul",
    "state": "Madhya Pradesh",
    "pincode": "802337"
  },
  {
    "district": "Betul",
    "state": "Madhya Pradesh",
    "pincode": "802339"
  },
  {
    "district": "Betul",
    "state": "Madhya Pradesh",
    "pincode": "802338"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802091"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802098"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802090"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802099"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802094"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802093"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802097"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802095"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802092"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802096"
  },
  {
    "district": "Bhind",
    "state": "Madhya Pradesh",
    "pincode": "802089"
  },
  {
    "district": "Bhopal",
    "state": "Madhya Pradesh",
    "pincode": "802311"
  },
  {
    "district": "Bhopal",
    "state": "Madhya Pradesh",
    "pincode": "802312"
  },
  {
    "district": "Burhanpur",
    "state": "Madhya Pradesh",
    "pincode": "802439"
  },
  {
    "district": "Burhanpur",
    "state": "Madhya Pradesh",
    "pincode": "802441"
  },
  {
    "district": "Burhanpur",
    "state": "Madhya Pradesh",
    "pincode": "802440"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802142"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802132"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802145"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802146"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802134"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802139"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802137"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802143"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802135"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802141"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802133"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802138"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802136"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802140"
  },
  {
    "district": "Chhatarpur",
    "state": "Madhya Pradesh",
    "pincode": "802144"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802377"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802385"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "494083"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "494350"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802384"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802379"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802386"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802381"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802383"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802378"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802380"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802389"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802388"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802382"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802391"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802390"
  },
  {
    "district": "Chhindwara",
    "state": "Madhya Pradesh",
    "pincode": "802387"
  },
  {
    "district": "Damoh",
    "state": "Madhya Pradesh",
    "pincode": "802167"
  },
  {
    "district": "Damoh",
    "state": "Madhya Pradesh",
    "pincode": "802164"
  },
  {
    "district": "Damoh",
    "state": "Madhya Pradesh",
    "pincode": "802166"
  },
  {
    "district": "Damoh",
    "state": "Madhya Pradesh",
    "pincode": "461887"
  },
  {
    "district": "Damoh",
    "state": "Madhya Pradesh",
    "pincode": "802165"
  },
  {
    "district": "Damoh",
    "state": "Madhya Pradesh",
    "pincode": "802168"
  },
  {
    "district": "Datia",
    "state": "Madhya Pradesh",
    "pincode": "802110"
  },
  {
    "district": "Datia",
    "state": "Madhya Pradesh",
    "pincode": "802111"
  },
  {
    "district": "Datia",
    "state": "Madhya Pradesh",
    "pincode": "802109"
  },
  {
    "district": "Datia",
    "state": "Madhya Pradesh",
    "pincode": "802108"
  },
  {
    "district": "Datia",
    "state": "Madhya Pradesh",
    "pincode": "802107"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802254"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802245"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802248"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802255"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802249"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802251"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802253"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802256"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802250"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "474332"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802247"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802252"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802246"
  },
  {
    "district": "Dewas",
    "state": "Madhya Pradesh",
    "pincode": "802244"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802257"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802264"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802266"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802260"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802267"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802263"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802265"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802262"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802261"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802258"
  },
  {
    "district": "Dhar",
    "state": "Madhya Pradesh",
    "pincode": "802259"
  },
  {
    "district": "Dindori",
    "state": "Madhya Pradesh",
    "pincode": "802371"
  },
  {
    "district": "Dindori",
    "state": "Madhya Pradesh",
    "pincode": "802370"
  },
  {
    "district": "Guna",
    "state": "Madhya Pradesh",
    "pincode": "802404"
  },
  {
    "district": "Guna",
    "state": "Madhya Pradesh",
    "pincode": "802405"
  },
  {
    "district": "Guna",
    "state": "Madhya Pradesh",
    "pincode": "802401"
  },
  {
    "district": "Guna",
    "state": "Madhya Pradesh",
    "pincode": "802403"
  },
  {
    "district": "Guna",
    "state": "Madhya Pradesh",
    "pincode": "802402"
  },
  {
    "district": "Gwalior",
    "state": "Madhya Pradesh",
    "pincode": "802106"
  },
  {
    "district": "Gwalior",
    "state": "Madhya Pradesh",
    "pincode": "802105"
  },
  {
    "district": "Gwalior",
    "state": "Madhya Pradesh",
    "pincode": "802102"
  },
  {
    "district": "Gwalior",
    "state": "Madhya Pradesh",
    "pincode": "802104"
  },
  {
    "district": "Gwalior",
    "state": "Madhya Pradesh",
    "pincode": "802100"
  },
  {
    "district": "Gwalior",
    "state": "Madhya Pradesh",
    "pincode": "802103"
  },
  {
    "district": "Harda",
    "state": "Madhya Pradesh",
    "pincode": "802342"
  },
  {
    "district": "Harda",
    "state": "Madhya Pradesh",
    "pincode": "802341"
  },
  {
    "district": "Harda",
    "state": "Madhya Pradesh",
    "pincode": "802343"
  },
  {
    "district": "Hoshangabad",
    "state": "Madhya Pradesh",
    "pincode": "802347"
  },
  {
    "district": "Hoshangabad",
    "state": "Madhya Pradesh",
    "pincode": "488034"
  },
  {
    "district": "Hoshangabad",
    "state": "Madhya Pradesh",
    "pincode": "802346"
  },
  {
    "district": "Hoshangabad",
    "state": "Madhya Pradesh",
    "pincode": "802345"
  },
  {
    "district": "Hoshangabad",
    "state": "Madhya Pradesh",
    "pincode": "802349"
  },
  {
    "district": "Hoshangabad",
    "state": "Madhya Pradesh",
    "pincode": "802344"
  },
  {
    "district": "Hoshangabad",
    "state": "Madhya Pradesh",
    "pincode": "802348"
  },
  {
    "district": "Indore",
    "state": "Madhya Pradesh",
    "pincode": "802270"
  },
  {
    "district": "Indore",
    "state": "Madhya Pradesh",
    "pincode": "802269"
  },
  {
    "district": "Indore",
    "state": "Madhya Pradesh",
    "pincode": "802271"
  },
  {
    "district": "Indore",
    "state": "Madhya Pradesh",
    "pincode": "802273"
  },
  {
    "district": "Indore",
    "state": "Madhya Pradesh",
    "pincode": "802277"
  },
  {
    "district": "Indore",
    "state": "Madhya Pradesh",
    "pincode": "802276"
  },
  {
    "district": "Indore",
    "state": "Madhya Pradesh",
    "pincode": "802274"
  },
  {
    "district": "Indore",
    "state": "Madhya Pradesh",
    "pincode": "802268"
  },
  {
    "district": "Indore",
    "state": "Madhya Pradesh",
    "pincode": "802272"
  },
  {
    "district": "Jabalpur",
    "state": "Madhya Pradesh",
    "pincode": "802363"
  },
  {
    "district": "Jabalpur",
    "state": "Madhya Pradesh",
    "pincode": "802362"
  },
  {
    "district": "Jabalpur",
    "state": "Madhya Pradesh",
    "pincode": "802361"
  },
  {
    "district": "Jabalpur",
    "state": "Madhya Pradesh",
    "pincode": "802357"
  },
  {
    "district": "Jabalpur",
    "state": "Madhya Pradesh",
    "pincode": "802356"
  },
  {
    "district": "Jabalpur",
    "state": "Madhya Pradesh",
    "pincode": "802364"
  },
  {
    "district": "Jabalpur",
    "state": "Madhya Pradesh",
    "pincode": "802358"
  },
  {
    "district": "Jabalpur",
    "state": "Madhya Pradesh",
    "pincode": "802359"
  },
  {
    "district": "Jabalpur",
    "state": "Madhya Pradesh",
    "pincode": "802355"
  },
  {
    "district": "Jhabua",
    "state": "Madhya Pradesh",
    "pincode": "802429"
  },
  {
    "district": "Jhabua",
    "state": "Madhya Pradesh",
    "pincode": "504556"
  },
  {
    "district": "Jhabua",
    "state": "Madhya Pradesh",
    "pincode": "802428"
  },
  {
    "district": "Jhabua",
    "state": "Madhya Pradesh",
    "pincode": "802430"
  },
  {
    "district": "Jhabua",
    "state": "Madhya Pradesh",
    "pincode": "802427"
  },
  {
    "district": "Katni",
    "state": "Madhya Pradesh",
    "pincode": "802352"
  },
  {
    "district": "Katni",
    "state": "Madhya Pradesh",
    "pincode": "802353"
  },
  {
    "district": "Katni",
    "state": "Madhya Pradesh",
    "pincode": "802351"
  },
  {
    "district": "Katni",
    "state": "Madhya Pradesh",
    "pincode": "802354"
  },
  {
    "district": "Khandwa (East Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802434"
  },
  {
    "district": "Khandwa (East Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802435"
  },
  {
    "district": "Khandwa (East Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802437"
  },
  {
    "district": "Khandwa (East Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802436"
  },
  {
    "district": "Khandwa (East Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802438"
  },
  {
    "district": "Khargone (West Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802278"
  },
  {
    "district": "Khargone (West Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802283"
  },
  {
    "district": "Khargone (West Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "900037"
  },
  {
    "district": "Khargone (West Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802282"
  },
  {
    "district": "Khargone (West Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802284"
  },
  {
    "district": "Khargone (West Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802280"
  },
  {
    "district": "Khargone (West Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802281"
  },
  {
    "district": "Khargone (West Nimar)",
    "state": "Madhya Pradesh",
    "pincode": "802279"
  },
  {
    "district": "Mandla",
    "state": "Madhya Pradesh",
    "pincode": "802374"
  },
  {
    "district": "Mandla",
    "state": "Madhya Pradesh",
    "pincode": "802375"
  },
  {
    "district": "Mandla",
    "state": "Madhya Pradesh",
    "pincode": "802373"
  },
  {
    "district": "Mandla",
    "state": "Madhya Pradesh",
    "pincode": "802376"
  },
  {
    "district": "Mandla",
    "state": "Madhya Pradesh",
    "pincode": "802372"
  },
  {
    "district": "Mandsaur",
    "state": "Madhya Pradesh",
    "pincode": "802205"
  },
  {
    "district": "Mandsaur",
    "state": "Madhya Pradesh",
    "pincode": "802209"
  },
  {
    "district": "Mandsaur",
    "state": "Madhya Pradesh",
    "pincode": "802206"
  },
  {
    "district": "Mandsaur",
    "state": "Madhya Pradesh",
    "pincode": "802211"
  },
  {
    "district": "Mandsaur",
    "state": "Madhya Pradesh",
    "pincode": "802212"
  },
  {
    "district": "Mandsaur",
    "state": "Madhya Pradesh",
    "pincode": "802207"
  },
  {
    "district": "Mandsaur",
    "state": "Madhya Pradesh",
    "pincode": "802208"
  },
  {
    "district": "Mandsaur",
    "state": "Madhya Pradesh",
    "pincode": "802210"
  },
  {
    "district": "Mandsaur",
    "state": "Madhya Pradesh",
    "pincode": "802213"
  },
  {
    "district": "Mandsaur",
    "state": "Madhya Pradesh",
    "pincode": "802214"
  },
  {
    "district": "Morena",
    "state": "Madhya Pradesh",
    "pincode": "802081"
  },
  {
    "district": "Morena",
    "state": "Madhya Pradesh",
    "pincode": "802084"
  },
  {
    "district": "Morena",
    "state": "Madhya Pradesh",
    "pincode": "802087"
  },
  {
    "district": "Morena",
    "state": "Madhya Pradesh",
    "pincode": "802085"
  },
  {
    "district": "Morena",
    "state": "Madhya Pradesh",
    "pincode": "802086"
  },
  {
    "district": "Morena",
    "state": "Madhya Pradesh",
    "pincode": "802083"
  },
  {
    "district": "Morena",
    "state": "Madhya Pradesh",
    "pincode": "802082"
  },
  {
    "district": "Morena",
    "state": "Madhya Pradesh",
    "pincode": "802088"
  },
  {
    "district": "Narsimhapur",
    "state": "Madhya Pradesh",
    "pincode": "491055"
  },
  {
    "district": "Narsimhapur",
    "state": "Madhya Pradesh",
    "pincode": "802366"
  },
  {
    "district": "Narsimhapur",
    "state": "Madhya Pradesh",
    "pincode": "802365"
  },
  {
    "district": "Narsimhapur",
    "state": "Madhya Pradesh",
    "pincode": "802368"
  },
  {
    "district": "Narsimhapur",
    "state": "Madhya Pradesh",
    "pincode": "802367"
  },
  {
    "district": "Narsimhapur",
    "state": "Madhya Pradesh",
    "pincode": "490814"
  },
  {
    "district": "Narsimhapur",
    "state": "Madhya Pradesh",
    "pincode": "900036"
  },
  {
    "district": "Narsimhapur",
    "state": "Madhya Pradesh",
    "pincode": "802369"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "468310"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "802196"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "802197"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "802201"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "802204"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "802203"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "468318"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "802200"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "802202"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "802199"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "468340"
  },
  {
    "district": "Neemuch",
    "state": "Madhya Pradesh",
    "pincode": "802198"
  },
  {
    "district": "Panna",
    "state": "Madhya Pradesh",
    "pincode": "802147"
  },
  {
    "district": "Panna",
    "state": "Madhya Pradesh",
    "pincode": "802151"
  },
  {
    "district": "Panna",
    "state": "Madhya Pradesh",
    "pincode": "802149"
  },
  {
    "district": "Panna",
    "state": "Madhya Pradesh",
    "pincode": "802150"
  },
  {
    "district": "Panna",
    "state": "Madhya Pradesh",
    "pincode": "802148"
  },
  {
    "district": "Panna",
    "state": "Madhya Pradesh",
    "pincode": "802152"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802330"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802329"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802325"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802324"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802327"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802328"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802323"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802322"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802331"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802326"
  },
  {
    "district": "Raisen",
    "state": "Madhya Pradesh",
    "pincode": "802332"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802299"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802302"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802295"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802293"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802294"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802297"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "480298"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802292"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802301"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802303"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802296"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802300"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802298"
  },
  {
    "district": "Rajgarh",
    "state": "Madhya Pradesh",
    "pincode": "802304"
  },
  {
    "district": "Ratlam",
    "state": "Madhya Pradesh",
    "pincode": "802218"
  },
  {
    "district": "Ratlam",
    "state": "Madhya Pradesh",
    "pincode": "802217"
  },
  {
    "district": "Ratlam",
    "state": "Madhya Pradesh",
    "pincode": "802223"
  },
  {
    "district": "Ratlam",
    "state": "Madhya Pradesh",
    "pincode": "802216"
  },
  {
    "district": "Ratlam",
    "state": "Madhya Pradesh",
    "pincode": "802221"
  },
  {
    "district": "Ratlam",
    "state": "Madhya Pradesh",
    "pincode": "802215"
  },
  {
    "district": "Ratlam",
    "state": "Madhya Pradesh",
    "pincode": "802222"
  },
  {
    "district": "Ratlam",
    "state": "Madhya Pradesh",
    "pincode": "802220"
  },
  {
    "district": "Ratlam",
    "state": "Madhya Pradesh",
    "pincode": "802219"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802183"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802180"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802190"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802191"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802186"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802184"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802187"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802188"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802189"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802185"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802182"
  },
  {
    "district": "Rewa",
    "state": "Madhya Pradesh",
    "pincode": "802181"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "802155"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "802153"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "802163"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "802161"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "802154"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "460964"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "802157"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "802162"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "802159"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "802156"
  },
  {
    "district": "Sagar",
    "state": "Madhya Pradesh",
    "pincode": "802158"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802178"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802172"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802171"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802173"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802177"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802169"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802179"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802174"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "463236"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802176"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802170"
  },
  {
    "district": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "802175"
  },
  {
    "district": "Sehore",
    "state": "Madhya Pradesh",
    "pincode": "802315"
  },
  {
    "district": "Sehore",
    "state": "Madhya Pradesh",
    "pincode": "802320"
  },
  {
    "district": "Sehore",
    "state": "Madhya Pradesh",
    "pincode": "802318"
  },
  {
    "district": "Sehore",
    "state": "Madhya Pradesh",
    "pincode": "802317"
  },
  {
    "district": "Sehore",
    "state": "Madhya Pradesh",
    "pincode": "802316"
  },
  {
    "district": "Sehore",
    "state": "Madhya Pradesh",
    "pincode": "802319"
  },
  {
    "district": "Sehore",
    "state": "Madhya Pradesh",
    "pincode": "802321"
  },
  {
    "district": "Sehore",
    "state": "Madhya Pradesh",
    "pincode": "802314"
  },
  {
    "district": "Sehore",
    "state": "Madhya Pradesh",
    "pincode": "483466"
  },
  {
    "district": "Seoni",
    "state": "Madhya Pradesh",
    "pincode": "802394"
  },
  {
    "district": "Seoni",
    "state": "Madhya Pradesh",
    "pincode": "495815"
  },
  {
    "district": "Seoni",
    "state": "Madhya Pradesh",
    "pincode": "802392"
  },
  {
    "district": "Seoni",
    "state": "Madhya Pradesh",
    "pincode": "802393"
  },
  {
    "district": "Shahdol",
    "state": "Madhya Pradesh",
    "pincode": "802411"
  },
  {
    "district": "Shahdol",
    "state": "Madhya Pradesh",
    "pincode": "802414"
  },
  {
    "district": "Shahdol",
    "state": "Madhya Pradesh",
    "pincode": "802415"
  },
  {
    "district": "Shahdol",
    "state": "Madhya Pradesh",
    "pincode": "802412"
  },
  {
    "district": "Shahdol",
    "state": "Madhya Pradesh",
    "pincode": "802410"
  },
  {
    "district": "Shahdol",
    "state": "Madhya Pradesh",
    "pincode": "802413"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802237"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802242"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802235"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802236"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802238"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802240"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802234"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "473183"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802243"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802239"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802241"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802232"
  },
  {
    "district": "Shajapur",
    "state": "Madhya Pradesh",
    "pincode": "802233"
  },
  {
    "district": "Sheopur",
    "state": "Madhya Pradesh",
    "pincode": "802080"
  },
  {
    "district": "Sheopur",
    "state": "Madhya Pradesh",
    "pincode": "802079"
  },
  {
    "district": "Sheopur",
    "state": "Madhya Pradesh",
    "pincode": "802078"
  },
  {
    "district": "Shivpuri",
    "state": "Madhya Pradesh",
    "pincode": "802116"
  },
  {
    "district": "Shivpuri",
    "state": "Madhya Pradesh",
    "pincode": "455363"
  },
  {
    "district": "Shivpuri",
    "state": "Madhya Pradesh",
    "pincode": "802114"
  },
  {
    "district": "Shivpuri",
    "state": "Madhya Pradesh",
    "pincode": "802118"
  },
  {
    "district": "Shivpuri",
    "state": "Madhya Pradesh",
    "pincode": "802115"
  },
  {
    "district": "Shivpuri",
    "state": "Madhya Pradesh",
    "pincode": "802113"
  },
  {
    "district": "Shivpuri",
    "state": "Madhya Pradesh",
    "pincode": "802117"
  },
  {
    "district": "Shivpuri",
    "state": "Madhya Pradesh",
    "pincode": "802112"
  },
  {
    "district": "Sidhi",
    "state": "Madhya Pradesh",
    "pincode": "802423"
  },
  {
    "district": "Sidhi",
    "state": "Madhya Pradesh",
    "pincode": "802425"
  },
  {
    "district": "Sidhi",
    "state": "Madhya Pradesh",
    "pincode": "802422"
  },
  {
    "district": "Sidhi",
    "state": "Madhya Pradesh",
    "pincode": "802424"
  },
  {
    "district": "Singrauli",
    "state": "Madhya Pradesh",
    "pincode": "802426"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802131"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802127"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802125"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802122"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802129"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802128"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802124"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802120"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802121"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802126"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802123"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802119"
  },
  {
    "district": "Tikamgarh",
    "state": "Madhya Pradesh",
    "pincode": "802130"
  },
  {
    "district": "Ujjain",
    "state": "Madhya Pradesh",
    "pincode": "802231"
  },
  {
    "district": "Ujjain",
    "state": "Madhya Pradesh",
    "pincode": "802224"
  },
  {
    "district": "Ujjain",
    "state": "Madhya Pradesh",
    "pincode": "802227"
  },
  {
    "district": "Ujjain",
    "state": "Madhya Pradesh",
    "pincode": "802229"
  },
  {
    "district": "Ujjain",
    "state": "Madhya Pradesh",
    "pincode": "802225"
  },
  {
    "district": "Ujjain",
    "state": "Madhya Pradesh",
    "pincode": "802228"
  },
  {
    "district": "Ujjain",
    "state": "Madhya Pradesh",
    "pincode": "802230"
  },
  {
    "district": "Ujjain",
    "state": "Madhya Pradesh",
    "pincode": "802226"
  },
  {
    "district": "Umaria",
    "state": "Madhya Pradesh",
    "pincode": "802193"
  },
  {
    "district": "Umaria",
    "state": "Madhya Pradesh",
    "pincode": "802195"
  },
  {
    "district": "Umaria",
    "state": "Madhya Pradesh",
    "pincode": "802194"
  },
  {
    "district": "Umaria",
    "state": "Madhya Pradesh",
    "pincode": "802192"
  },
  {
    "district": "Vidisha",
    "state": "Madhya Pradesh",
    "pincode": "802308"
  },
  {
    "district": "Vidisha",
    "state": "Madhya Pradesh",
    "pincode": "802307"
  },
  {
    "district": "Vidisha",
    "state": "Madhya Pradesh",
    "pincode": "802305"
  },
  {
    "district": "Vidisha",
    "state": "Madhya Pradesh",
    "pincode": "802309"
  },
  {
    "district": "Vidisha",
    "state": "Madhya Pradesh",
    "pincode": "802306"
  },
  {
    "district": "Vidisha",
    "state": "Madhya Pradesh",
    "pincode": "802310"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "802828"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "557248"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "802831"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "558718"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "558631"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "802823"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "557705"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "558320"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "802827"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "802825"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "802830"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "802822"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "557863"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "802824"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "802832"
  },
  {
    "district": "Ahmadnagar",
    "state": "Maharashtra",
    "pincode": "802826"
  },
  {
    "district": "Akola",
    "state": "Maharashtra",
    "pincode": "802676"
  },
  {
    "district": "Akola",
    "state": "Maharashtra",
    "pincode": "802674"
  },
  {
    "district": "Akola",
    "state": "Maharashtra",
    "pincode": "802675"
  },
  {
    "district": "Akola",
    "state": "Maharashtra",
    "pincode": "530476"
  },
  {
    "district": "Akola",
    "state": "Maharashtra",
    "pincode": "802677"
  },
  {
    "district": "Akola",
    "state": "Maharashtra",
    "pincode": "802678"
  },
  {
    "district": "Akola",
    "state": "Maharashtra",
    "pincode": "802673"
  },
  {
    "district": "Akola",
    "state": "Maharashtra",
    "pincode": "530164"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802685"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802690"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802684"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "532801"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802692"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802686"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802683"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802691"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802693"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "531532"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802687"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "533070"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802689"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "532528"
  },
  {
    "district": "Amravati",
    "state": "Maharashtra",
    "pincode": "802688"
  },
  {
    "district": "Aurangabad",
    "state": "Maharashtra",
    "pincode": "802765"
  },
  {
    "district": "Aurangabad",
    "state": "Maharashtra",
    "pincode": "548730"
  },
  {
    "district": "Aurangabad",
    "state": "Maharashtra",
    "pincode": "802769"
  },
  {
    "district": "Aurangabad",
    "state": "Maharashtra",
    "pincode": "802763"
  },
  {
    "district": "Aurangabad",
    "state": "Maharashtra",
    "pincode": "802767"
  },
  {
    "district": "Aurangabad",
    "state": "Maharashtra",
    "pincode": "802770"
  },
  {
    "district": "Aurangabad",
    "state": "Maharashtra",
    "pincode": "802764"
  },
  {
    "district": "Aurangabad",
    "state": "Maharashtra",
    "pincode": "548490"
  },
  {
    "district": "Aurangabad",
    "state": "Maharashtra",
    "pincode": "802768"
  },
  {
    "district": "Bhandara",
    "state": "Maharashtra",
    "pincode": "802713"
  },
  {
    "district": "Bhandara",
    "state": "Maharashtra",
    "pincode": "537190"
  },
  {
    "district": "Bhandara",
    "state": "Maharashtra",
    "pincode": "537497"
  },
  {
    "district": "Bhandara",
    "state": "Maharashtra",
    "pincode": "536839"
  },
  {
    "district": "Bhandara",
    "state": "Maharashtra",
    "pincode": "528754"
  },
  {
    "district": "Bhandara",
    "state": "Maharashtra",
    "pincode": "802714"
  },
  {
    "district": "Bhandara",
    "state": "Maharashtra",
    "pincode": "537168"
  },
  {
    "district": "Bhandara",
    "state": "Maharashtra",
    "pincode": "802712"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "802839"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "558895"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "802835"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "802837"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "802833"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "802836"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "802834"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "802838"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "558953"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "559021"
  },
  {
    "district": "Bid",
    "state": "Maharashtra",
    "pincode": "559435"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "543235"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802669"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802668"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802670"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802662"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802666"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802672"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802665"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802667"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802664"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "528353"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802663"
  },
  {
    "district": "Buldana",
    "state": "Maharashtra",
    "pincode": "802671"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "802724"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "802721"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "802720"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "802722"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "540550"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "541316"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "541954"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "541694"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "541560"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "802723"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "540611"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "541483"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "802725"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "541049"
  },
  {
    "district": "Chandrapur",
    "state": "Maharashtra",
    "pincode": "802719"
  },
  {
    "district": "Dhule",
    "state": "Maharashtra",
    "pincode": "802646"
  },
  {
    "district": "Dhule",
    "state": "Maharashtra",
    "pincode": "802645"
  },
  {
    "district": "Dhule",
    "state": "Maharashtra",
    "pincode": "526464"
  },
  {
    "district": "Dhule",
    "state": "Maharashtra",
    "pincode": "526155"
  },
  {
    "district": "Dhule",
    "state": "Maharashtra",
    "pincode": "802644"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "539998"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "538518"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "539754"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "539228"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "802717"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "538987"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "539650"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "802718"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "538785"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "538642"
  },
  {
    "district": "Gadchiroli",
    "state": "Maharashtra",
    "pincode": "540146"
  },
  {
    "district": "Gondiya",
    "state": "Maharashtra",
    "pincode": "537832"
  },
  {
    "district": "Gondiya",
    "state": "Maharashtra",
    "pincode": "537772"
  },
  {
    "district": "Gondiya",
    "state": "Maharashtra",
    "pincode": "802716"
  },
  {
    "district": "Gondiya",
    "state": "Maharashtra",
    "pincode": "537675"
  },
  {
    "district": "Gondiya",
    "state": "Maharashtra",
    "pincode": "538130"
  },
  {
    "district": "Gondiya",
    "state": "Maharashtra",
    "pincode": "538024"
  },
  {
    "district": "Gondiya",
    "state": "Maharashtra",
    "pincode": "802715"
  },
  {
    "district": "Hingoli",
    "state": "Maharashtra",
    "pincode": "546018"
  },
  {
    "district": "Hingoli",
    "state": "Maharashtra",
    "pincode": "802750"
  },
  {
    "district": "Hingoli",
    "state": "Maharashtra",
    "pincode": "802748"
  },
  {
    "district": "Hingoli",
    "state": "Maharashtra",
    "pincode": "802749"
  },
  {
    "district": "Hingoli",
    "state": "Maharashtra",
    "pincode": "546014"
  },
  {
    "district": "Hingoli",
    "state": "Maharashtra",
    "pincode": "545809"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802656"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802658"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802652"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "527082"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802659"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802647"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802655"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802654"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802649"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802653"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802661"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "527028"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802660"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802657"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802651"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802650"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "528076"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "527150"
  },
  {
    "district": "Jalgaon",
    "state": "Maharashtra",
    "pincode": "802648"
  },
  {
    "district": "Jalna",
    "state": "Maharashtra",
    "pincode": "802761"
  },
  {
    "district": "Jalna",
    "state": "Maharashtra",
    "pincode": "547736"
  },
  {
    "district": "Jalna",
    "state": "Maharashtra",
    "pincode": "802759"
  },
  {
    "district": "Jalna",
    "state": "Maharashtra",
    "pincode": "547961"
  },
  {
    "district": "Jalna",
    "state": "Maharashtra",
    "pincode": "547443"
  },
  {
    "district": "Jalna",
    "state": "Maharashtra",
    "pincode": "802760"
  },
  {
    "district": "Jalna",
    "state": "Maharashtra",
    "pincode": "802762"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "802890"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "567321"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "802884"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "802885"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "802888"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "802887"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "802886"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "802881"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "802889"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "802882"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "567330"
  },
  {
    "district": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "802883"
  },
  {
    "district": "Latur",
    "state": "Maharashtra",
    "pincode": "802841"
  },
  {
    "district": "Latur",
    "state": "Maharashtra",
    "pincode": "802842"
  },
  {
    "district": "Latur",
    "state": "Maharashtra",
    "pincode": "560510"
  },
  {
    "district": "Latur",
    "state": "Maharashtra",
    "pincode": "560929"
  },
  {
    "district": "Latur",
    "state": "Maharashtra",
    "pincode": "560431"
  },
  {
    "district": "Latur",
    "state": "Maharashtra",
    "pincode": "802840"
  },
  {
    "district": "Latur",
    "state": "Maharashtra",
    "pincode": "802843"
  },
  {
    "district": "Latur",
    "state": "Maharashtra",
    "pincode": "560262"
  },
  {
    "district": "Latur",
    "state": "Maharashtra",
    "pincode": "560562"
  },
  {
    "district": "Latur",
    "state": "Maharashtra",
    "pincode": "802844"
  },
  {
    "district": "Mumbai",
    "state": "Maharashtra",
    "pincode": "802794"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "536621"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "535969"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "536055"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802703"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802708"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "535452"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802702"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802706"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "536403"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "535809"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802704"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "535734"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802700"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802710"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802701"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "535404"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802707"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802705"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "802711"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "535970"
  },
  {
    "district": "Nagpur",
    "state": "Maharashtra",
    "pincode": "900049"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "544648"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802739"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802743"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802747"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802741"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802736"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "544400"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802745"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802735"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802742"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802744"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "544129"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802738"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802746"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "545151"
  },
  {
    "district": "Nanded",
    "state": "Maharashtra",
    "pincode": "802737"
  },
  {
    "district": "Nandurbar",
    "state": "Maharashtra",
    "pincode": "525237"
  },
  {
    "district": "Nandurbar",
    "state": "Maharashtra",
    "pincode": "802642"
  },
  {
    "district": "Nandurbar",
    "state": "Maharashtra",
    "pincode": "802643"
  },
  {
    "district": "Nandurbar",
    "state": "Maharashtra",
    "pincode": "802641"
  },
  {
    "district": "Nandurbar",
    "state": "Maharashtra",
    "pincode": "802640"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802778"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "550516"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "549965"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802777"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "550649"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802779"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "549915"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802772"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802774"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802773"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802776"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "551368"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "550731"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802771"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802780"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "549783"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802775"
  },
  {
    "district": "Nashik",
    "state": "Maharashtra",
    "pincode": "802781"
  },
  {
    "district": "Osmanabad",
    "state": "Maharashtra",
    "pincode": "802846"
  },
  {
    "district": "Osmanabad",
    "state": "Maharashtra",
    "pincode": "802847"
  },
  {
    "district": "Osmanabad",
    "state": "Maharashtra",
    "pincode": "802851"
  },
  {
    "district": "Osmanabad",
    "state": "Maharashtra",
    "pincode": "802850"
  },
  {
    "district": "Osmanabad",
    "state": "Maharashtra",
    "pincode": "802848"
  },
  {
    "district": "Osmanabad",
    "state": "Maharashtra",
    "pincode": "802845"
  },
  {
    "district": "Osmanabad",
    "state": "Maharashtra",
    "pincode": "802849"
  },
  {
    "district": "Osmanabad",
    "state": "Maharashtra",
    "pincode": "802852"
  },
  {
    "district": "Osmanabad",
    "state": "Maharashtra",
    "pincode": "561262"
  },
  {
    "district": "Parbhani",
    "state": "Maharashtra",
    "pincode": "802757"
  },
  {
    "district": "Parbhani",
    "state": "Maharashtra",
    "pincode": "802752"
  },
  {
    "district": "Parbhani",
    "state": "Maharashtra",
    "pincode": "802754"
  },
  {
    "district": "Parbhani",
    "state": "Maharashtra",
    "pincode": "547119"
  },
  {
    "district": "Parbhani",
    "state": "Maharashtra",
    "pincode": "802753"
  },
  {
    "district": "Parbhani",
    "state": "Maharashtra",
    "pincode": "802755"
  },
  {
    "district": "Parbhani",
    "state": "Maharashtra",
    "pincode": "802758"
  },
  {
    "district": "Parbhani",
    "state": "Maharashtra",
    "pincode": "802751"
  },
  {
    "district": "Parbhani",
    "state": "Maharashtra",
    "pincode": "802756"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802808"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802820"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802819"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "555862"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802816"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802821"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802818"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802806"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802810"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802811"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802814"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "555861"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802817"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802807"
  },
  {
    "district": "Pune",
    "state": "Maharashtra",
    "pincode": "802809"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802801"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802798"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "553748"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802799"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802805"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "554652"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802797"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "554960"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802802"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802796"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802800"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "555230"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802803"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802804"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "554759"
  },
  {
    "district": "Raigarh",
    "state": "Maharashtra",
    "pincode": "802795"
  },
  {
    "district": "Ratnagiri",
    "state": "Maharashtra",
    "pincode": "802874"
  },
  {
    "district": "Ratnagiri",
    "state": "Maharashtra",
    "pincode": "802872"
  },
  {
    "district": "Ratnagiri",
    "state": "Maharashtra",
    "pincode": "565872"
  },
  {
    "district": "Ratnagiri",
    "state": "Maharashtra",
    "pincode": "565379"
  },
  {
    "district": "Ratnagiri",
    "state": "Maharashtra",
    "pincode": "802873"
  },
  {
    "district": "Ratnagiri",
    "state": "Maharashtra",
    "pincode": "565995"
  },
  {
    "district": "Ratnagiri",
    "state": "Maharashtra",
    "pincode": "564760"
  },
  {
    "district": "Ratnagiri",
    "state": "Maharashtra",
    "pincode": "802876"
  },
  {
    "district": "Ratnagiri",
    "state": "Maharashtra",
    "pincode": "802875"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "802892"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "568926"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "568486"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "568794"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "568552"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "568415"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "802895"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "568295"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "802894"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "802891"
  },
  {
    "district": "Sangli",
    "state": "Maharashtra",
    "pincode": "802893"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "563395"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "802870"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "563201"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "563744"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "563232"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "802863"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "802871"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "564121"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "802867"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "802864"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "564465"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "802866"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "802868"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "802869"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "563537"
  },
  {
    "district": "Satara",
    "state": "Maharashtra",
    "pincode": "802865"
  },
  {
    "district": "Sindhudurg",
    "state": "Maharashtra",
    "pincode": "566312"
  },
  {
    "district": "Sindhudurg",
    "state": "Maharashtra",
    "pincode": "802877"
  },
  {
    "district": "Sindhudurg",
    "state": "Maharashtra",
    "pincode": "910603"
  },
  {
    "district": "Sindhudurg",
    "state": "Maharashtra",
    "pincode": "566839"
  },
  {
    "district": "Sindhudurg",
    "state": "Maharashtra",
    "pincode": "802878"
  },
  {
    "district": "Sindhudurg",
    "state": "Maharashtra",
    "pincode": "802880"
  },
  {
    "district": "Sindhudurg",
    "state": "Maharashtra",
    "pincode": "566375"
  },
  {
    "district": "Sindhudurg",
    "state": "Maharashtra",
    "pincode": "802879"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "802860"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "802855"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "802862"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "802853"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "802854"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "561988"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "802861"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "562439"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "802859"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "562219"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "802857"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "802858"
  },
  {
    "district": "Solapur",
    "state": "Maharashtra",
    "pincode": "802856"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802793"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802792"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802789"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802782"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802783"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802790"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802786"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "551969"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "553290"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802788"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802784"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "552391"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "552914"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "551566"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802787"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802791"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "552182"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "802785"
  },
  {
    "district": "Thane",
    "state": "Maharashtra",
    "pincode": "551844"
  },
  {
    "district": "Wardha",
    "state": "Maharashtra",
    "pincode": "533465"
  },
  {
    "district": "Wardha",
    "state": "Maharashtra",
    "pincode": "802694"
  },
  {
    "district": "Wardha",
    "state": "Maharashtra",
    "pincode": "802698"
  },
  {
    "district": "Wardha",
    "state": "Maharashtra",
    "pincode": "802699"
  },
  {
    "district": "Wardha",
    "state": "Maharashtra",
    "pincode": "533580"
  },
  {
    "district": "Wardha",
    "state": "Maharashtra",
    "pincode": "802697"
  },
  {
    "district": "Wardha",
    "state": "Maharashtra",
    "pincode": "534619"
  },
  {
    "district": "Wardha",
    "state": "Maharashtra",
    "pincode": "533971"
  },
  {
    "district": "Wardha",
    "state": "Maharashtra",
    "pincode": "802695"
  },
  {
    "district": "Wardha",
    "state": "Maharashtra",
    "pincode": "802696"
  },
  {
    "district": "Washim",
    "state": "Maharashtra",
    "pincode": "802680"
  },
  {
    "district": "Washim",
    "state": "Maharashtra",
    "pincode": "530646"
  },
  {
    "district": "Washim",
    "state": "Maharashtra",
    "pincode": "802679"
  },
  {
    "district": "Washim",
    "state": "Maharashtra",
    "pincode": "531077"
  },
  {
    "district": "Washim",
    "state": "Maharashtra",
    "pincode": "802682"
  },
  {
    "district": "Washim",
    "state": "Maharashtra",
    "pincode": "802681"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "542160"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "802728"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "802729"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "802732"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "542234"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "542503"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "543170"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "543786"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "802726"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "802733"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "802730"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "543581"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "802731"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "802734"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "802727"
  },
  {
    "district": "Yavatmal",
    "state": "Maharashtra",
    "pincode": "543914"
  },
  {
    "district": "Bishnupur",
    "state": "Manipur",
    "pincode": "801471"
  },
  {
    "district": "Bishnupur",
    "state": "Manipur",
    "pincode": "801475"
  },
  {
    "district": "Bishnupur",
    "state": "Manipur",
    "pincode": "801474"
  },
  {
    "district": "Bishnupur",
    "state": "Manipur",
    "pincode": "801473"
  },
  {
    "district": "Bishnupur",
    "state": "Manipur",
    "pincode": "801472"
  },
  {
    "district": "Bishnupur",
    "state": "Manipur",
    "pincode": "801470"
  },
  {
    "district": "Chandel",
    "state": "Manipur",
    "pincode": "801496"
  },
  {
    "district": "Imphal East",
    "state": "Manipur",
    "pincode": "801495"
  },
  {
    "district": "Imphal East",
    "state": "Manipur",
    "pincode": "801493"
  },
  {
    "district": "Imphal East",
    "state": "Manipur",
    "pincode": "801494"
  },
  {
    "district": "Imphal West",
    "state": "Manipur",
    "pincode": "801487"
  },
  {
    "district": "Imphal West",
    "state": "Manipur",
    "pincode": "801485"
  },
  {
    "district": "Imphal West",
    "state": "Manipur",
    "pincode": "801492"
  },
  {
    "district": "Imphal West",
    "state": "Manipur",
    "pincode": "801489"
  },
  {
    "district": "Imphal West",
    "state": "Manipur",
    "pincode": "801469"
  },
  {
    "district": "Imphal West",
    "state": "Manipur",
    "pincode": "801488"
  },
  {
    "district": "Imphal West",
    "state": "Manipur",
    "pincode": "801486"
  },
  {
    "district": "Imphal West",
    "state": "Manipur",
    "pincode": "801490"
  },
  {
    "district": "Imphal West",
    "state": "Manipur",
    "pincode": "801491"
  },
  {
    "district": "Thoubal",
    "state": "Manipur",
    "pincode": "801477"
  },
  {
    "district": "Thoubal",
    "state": "Manipur",
    "pincode": "801484"
  },
  {
    "district": "Thoubal",
    "state": "Manipur",
    "pincode": "801483"
  },
  {
    "district": "Thoubal",
    "state": "Manipur",
    "pincode": "801476"
  },
  {
    "district": "Thoubal",
    "state": "Manipur",
    "pincode": "801480"
  },
  {
    "district": "Thoubal",
    "state": "Manipur",
    "pincode": "801482"
  },
  {
    "district": "Thoubal",
    "state": "Manipur",
    "pincode": "801479"
  },
  {
    "district": "Thoubal",
    "state": "Manipur",
    "pincode": "801478"
  },
  {
    "district": "Thoubal",
    "state": "Manipur",
    "pincode": "801481"
  },
  {
    "district": "East Garo Hills",
    "state": "Meghalaya",
    "pincode": "801537"
  },
  {
    "district": "East Garo Hills",
    "state": "Meghalaya",
    "pincode": "801538"
  },
  {
    "district": "East Khasi Hills",
    "state": "Meghalaya",
    "pincode": "801544"
  },
  {
    "district": "East Khasi Hills",
    "state": "Meghalaya",
    "pincode": "801543"
  },
  {
    "district": "Jaintia Hills",
    "state": "Meghalaya",
    "pincode": "801545"
  },
  {
    "district": "Ribhoi",
    "state": "Meghalaya",
    "pincode": "801542"
  },
  {
    "district": "South Garo Hills",
    "state": "Meghalaya",
    "pincode": "801539"
  },
  {
    "district": "West Garo Hills",
    "state": "Meghalaya",
    "pincode": "801536"
  },
  {
    "district": "West Khasi Hills",
    "state": "Meghalaya",
    "pincode": "801541"
  },
  {
    "district": "West Khasi Hills",
    "state": "Meghalaya",
    "pincode": "801540"
  },
  {
    "district": "Aizawl",
    "state": "Mizoram",
    "pincode": "801506"
  },
  {
    "district": "Aizawl",
    "state": "Mizoram",
    "pincode": "801504"
  },
  {
    "district": "Aizawl",
    "state": "Mizoram",
    "pincode": "801505"
  },
  {
    "district": "Aizawl",
    "state": "Mizoram",
    "pincode": "801507"
  },
  {
    "district": "Champhai",
    "state": "Mizoram",
    "pincode": "801511"
  },
  {
    "district": "Champhai",
    "state": "Mizoram",
    "pincode": "801510"
  },
  {
    "district": "Champhai",
    "state": "Mizoram",
    "pincode": "801509"
  },
  {
    "district": "Champhai",
    "state": "Mizoram",
    "pincode": "801508"
  },
  {
    "district": "Kolasib",
    "state": "Mizoram",
    "pincode": "801502"
  },
  {
    "district": "Kolasib",
    "state": "Mizoram",
    "pincode": "801503"
  },
  {
    "district": "Kolasib",
    "state": "Mizoram",
    "pincode": "801500"
  },
  {
    "district": "Kolasib",
    "state": "Mizoram",
    "pincode": "801501"
  },
  {
    "district": "Lawngtlai",
    "state": "Mizoram",
    "pincode": "801518"
  },
  {
    "district": "Lunglei",
    "state": "Mizoram",
    "pincode": "801517"
  },
  {
    "district": "Lunglei",
    "state": "Mizoram",
    "pincode": "801516"
  },
  {
    "district": "Lunglei",
    "state": "Mizoram",
    "pincode": "801515"
  },
  {
    "district": "Mamit",
    "state": "Mizoram",
    "pincode": "801499"
  },
  {
    "district": "Mamit",
    "state": "Mizoram",
    "pincode": "801498"
  },
  {
    "district": "Mamit",
    "state": "Mizoram",
    "pincode": "801497"
  },
  {
    "district": "Saiha",
    "state": "Mizoram",
    "pincode": "801519"
  },
  {
    "district": "Serchhip",
    "state": "Mizoram",
    "pincode": "801514"
  },
  {
    "district": "Serchhip",
    "state": "Mizoram",
    "pincode": "801512"
  },
  {
    "district": "Serchhip",
    "state": "Mizoram",
    "pincode": "801513"
  },
  {
    "district": "Dimapur",
    "state": "Nagaland",
    "pincode": "801458"
  },
  {
    "district": "Dimapur",
    "state": "Nagaland",
    "pincode": "801457"
  },
  {
    "district": "Dimapur",
    "state": "Nagaland",
    "pincode": "900053"
  },
  {
    "district": "Dimapur",
    "state": "Nagaland",
    "pincode": "801459"
  },
  {
    "district": "Kiphire",
    "state": "Nagaland",
    "pincode": "801464"
  },
  {
    "district": "Kiphire",
    "state": "Nagaland",
    "pincode": "268164"
  },
  {
    "district": "Kohima",
    "state": "Nagaland",
    "pincode": "801466"
  },
  {
    "district": "Kohima",
    "state": "Nagaland",
    "pincode": "801465"
  },
  {
    "district": "Longleng",
    "state": "Nagaland",
    "pincode": "801463"
  },
  {
    "district": "Mokokchung",
    "state": "Nagaland",
    "pincode": "801453"
  },
  {
    "district": "Mokokchung",
    "state": "Nagaland",
    "pincode": "267167"
  },
  {
    "district": "Mokokchung",
    "state": "Nagaland",
    "pincode": "801454"
  },
  {
    "district": "Mokokchung",
    "state": "Nagaland",
    "pincode": "801452"
  },
  {
    "district": "Mon",
    "state": "Nagaland",
    "pincode": "267033"
  },
  {
    "district": "Mon",
    "state": "Nagaland",
    "pincode": "801451"
  },
  {
    "district": "Mon",
    "state": "Nagaland",
    "pincode": "801450"
  },
  {
    "district": "Mon",
    "state": "Nagaland",
    "pincode": "266977"
  },
  {
    "district": "Mon",
    "state": "Nagaland",
    "pincode": "267084"
  },
  {
    "district": "Peren",
    "state": "Nagaland",
    "pincode": "801467"
  },
  {
    "district": "Peren",
    "state": "Nagaland",
    "pincode": "801468"
  },
  {
    "district": "Peren",
    "state": "Nagaland",
    "pincode": "268383"
  },
  {
    "district": "Phek",
    "state": "Nagaland",
    "pincode": "267844"
  },
  {
    "district": "Phek",
    "state": "Nagaland",
    "pincode": "267813"
  },
  {
    "district": "Phek",
    "state": "Nagaland",
    "pincode": "801461"
  },
  {
    "district": "Phek",
    "state": "Nagaland",
    "pincode": "801460"
  },
  {
    "district": "Tuensang",
    "state": "Nagaland",
    "pincode": "267930"
  },
  {
    "district": "Tuensang",
    "state": "Nagaland",
    "pincode": "267970"
  },
  {
    "district": "Tuensang",
    "state": "Nagaland",
    "pincode": "268002"
  },
  {
    "district": "Tuensang",
    "state": "Nagaland",
    "pincode": "801462"
  },
  {
    "district": "Wokha",
    "state": "Nagaland",
    "pincode": "267434"
  },
  {
    "district": "Wokha",
    "state": "Nagaland",
    "pincode": "801456"
  },
  {
    "district": "Zunheboto",
    "state": "Nagaland",
    "pincode": "801455"
  },
  {
    "district": "East",
    "state": "NCT of Delhi",
    "pincode": "900050"
  },
  {
    "district": "New Delhi",
    "state": "NCT of Delhi",
    "pincode": "800442"
  },
  {
    "district": "North",
    "state": "NCT of Delhi",
    "pincode": "900051"
  },
  {
    "district": "South",
    "state": "NCT of Delhi",
    "pincode": "800441"
  },
  {
    "district": "South West",
    "state": "NCT of Delhi",
    "pincode": "800443"
  },
  {
    "district": "Anugul",
    "state": "Odisha",
    "pincode": "801851"
  },
  {
    "district": "Anugul",
    "state": "Odisha",
    "pincode": "801852"
  },
  {
    "district": "Anugul",
    "state": "Odisha",
    "pincode": "801850"
  },
  {
    "district": "Balangir",
    "state": "Odisha",
    "pincode": "801890"
  },
  {
    "district": "Balangir",
    "state": "Odisha",
    "pincode": "801892"
  },
  {
    "district": "Balangir",
    "state": "Odisha",
    "pincode": "801891"
  },
  {
    "district": "Balangir",
    "state": "Odisha",
    "pincode": "801893"
  },
  {
    "district": "Balangir",
    "state": "Odisha",
    "pincode": "420513"
  },
  {
    "district": "Baleshwar",
    "state": "Odisha",
    "pincode": "801833"
  },
  {
    "district": "Baleshwar",
    "state": "Odisha",
    "pincode": "801829"
  },
  {
    "district": "Baleshwar",
    "state": "Odisha",
    "pincode": "801831"
  },
  {
    "district": "Baleshwar",
    "state": "Odisha",
    "pincode": "801832"
  },
  {
    "district": "Bargarh",
    "state": "Odisha",
    "pincode": "380893"
  },
  {
    "district": "Bargarh",
    "state": "Odisha",
    "pincode": "801804"
  },
  {
    "district": "Bargarh",
    "state": "Odisha",
    "pincode": "801805"
  },
  {
    "district": "Bargarh",
    "state": "Odisha",
    "pincode": "801803"
  },
  {
    "district": "Baudh",
    "state": "Odisha",
    "pincode": "801886"
  },
  {
    "district": "Bhadrak",
    "state": "Odisha",
    "pincode": "801836"
  },
  {
    "district": "Bhadrak",
    "state": "Odisha",
    "pincode": "801835"
  },
  {
    "district": "Bhadrak",
    "state": "Odisha",
    "pincode": "395394"
  },
  {
    "district": "Bhadrak",
    "state": "Odisha",
    "pincode": "801834"
  },
  {
    "district": "Cuttack",
    "state": "Odisha",
    "pincode": "801842"
  },
  {
    "district": "Cuttack",
    "state": "Odisha",
    "pincode": "801841"
  },
  {
    "district": "Cuttack",
    "state": "Odisha",
    "pincode": "801843"
  },
  {
    "district": "Cuttack",
    "state": "Odisha",
    "pincode": "801844"
  },
  {
    "district": "Debagarh",
    "state": "Odisha",
    "pincode": "801814"
  },
  {
    "district": "Dhenkanal",
    "state": "Odisha",
    "pincode": "801848"
  },
  {
    "district": "Dhenkanal",
    "state": "Odisha",
    "pincode": "801849"
  },
  {
    "district": "Dhenkanal",
    "state": "Odisha",
    "pincode": "403068"
  },
  {
    "district": "Dhenkanal",
    "state": "Odisha",
    "pincode": "801847"
  },
  {
    "district": "Gajapati",
    "state": "Odisha",
    "pincode": "801882"
  },
  {
    "district": "Gajapati",
    "state": "Odisha",
    "pincode": "801883"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801868"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801866"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801865"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801881"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801864"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801874"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801880"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801879"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801875"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801878"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801877"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801869"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801872"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801871"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801870"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801876"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801873"
  },
  {
    "district": "Ganjam",
    "state": "Odisha",
    "pincode": "801867"
  },
  {
    "district": "Jagatsinghapur",
    "state": "Odisha",
    "pincode": "801840"
  },
  {
    "district": "Jagatsinghapur",
    "state": "Odisha",
    "pincode": "801839"
  },
  {
    "district": "Jajapur",
    "state": "Odisha",
    "pincode": "801845"
  },
  {
    "district": "Jajapur",
    "state": "Odisha",
    "pincode": "801846"
  },
  {
    "district": "Jharsuguda",
    "state": "Odisha",
    "pincode": "801806"
  },
  {
    "district": "Jharsuguda",
    "state": "Odisha",
    "pincode": "801807"
  },
  {
    "district": "Jharsuguda",
    "state": "Odisha",
    "pincode": "801808"
  },
  {
    "district": "Kalahandi",
    "state": "Odisha",
    "pincode": "801897"
  },
  {
    "district": "Kalahandi",
    "state": "Odisha",
    "pincode": "423957"
  },
  {
    "district": "Kalahandi",
    "state": "Odisha",
    "pincode": "801898"
  },
  {
    "district": "Kalahandi",
    "state": "Odisha",
    "pincode": "801896"
  },
  {
    "district": "Kalahandi",
    "state": "Odisha",
    "pincode": "423597"
  },
  {
    "district": "Kandhamal",
    "state": "Odisha",
    "pincode": "416820"
  },
  {
    "district": "Kandhamal",
    "state": "Odisha",
    "pincode": "801885"
  },
  {
    "district": "Kandhamal",
    "state": "Odisha",
    "pincode": "801884"
  },
  {
    "district": "Kendrapara",
    "state": "Odisha",
    "pincode": "801838"
  },
  {
    "district": "Kendrapara",
    "state": "Odisha",
    "pincode": "801837"
  },
  {
    "district": "Kendujhar",
    "state": "Odisha",
    "pincode": "801824"
  },
  {
    "district": "Kendujhar",
    "state": "Odisha",
    "pincode": "801821"
  },
  {
    "district": "Kendujhar",
    "state": "Odisha",
    "pincode": "385509"
  },
  {
    "district": "Kendujhar",
    "state": "Odisha",
    "pincode": "801822"
  },
  {
    "district": "Kendujhar",
    "state": "Odisha",
    "pincode": "801823"
  },
  {
    "district": "Khordha",
    "state": "Odisha",
    "pincode": "801857"
  },
  {
    "district": "Khordha",
    "state": "Odisha",
    "pincode": "801858"
  },
  {
    "district": "Khordha",
    "state": "Odisha",
    "pincode": "801859"
  },
  {
    "district": "Khordha",
    "state": "Odisha",
    "pincode": "801856"
  },
  {
    "district": "Khordha",
    "state": "Odisha",
    "pincode": "801855"
  },
  {
    "district": "Khordha",
    "state": "Odisha",
    "pincode": "407593"
  },
  {
    "district": "Koraput",
    "state": "Odisha",
    "pincode": "801906"
  },
  {
    "district": "Koraput",
    "state": "Odisha",
    "pincode": "801905"
  },
  {
    "district": "Koraput",
    "state": "Odisha",
    "pincode": "801904"
  },
  {
    "district": "Koraput",
    "state": "Odisha",
    "pincode": "801907"
  },
  {
    "district": "Malkangiri",
    "state": "Odisha",
    "pincode": "801909"
  },
  {
    "district": "Malkangiri",
    "state": "Odisha",
    "pincode": "801908"
  },
  {
    "district": "Mayurbhanj",
    "state": "Odisha",
    "pincode": "801828"
  },
  {
    "district": "Mayurbhanj",
    "state": "Odisha",
    "pincode": "801826"
  },
  {
    "district": "Mayurbhanj",
    "state": "Odisha",
    "pincode": "801825"
  },
  {
    "district": "Mayurbhanj",
    "state": "Odisha",
    "pincode": "801827"
  },
  {
    "district": "Nabarangapur",
    "state": "Odisha",
    "pincode": "801903"
  },
  {
    "district": "Nabarangapur",
    "state": "Odisha",
    "pincode": "801902"
  },
  {
    "district": "Nayagarh",
    "state": "Odisha",
    "pincode": "405521"
  },
  {
    "district": "Nayagarh",
    "state": "Odisha",
    "pincode": "801853"
  },
  {
    "district": "Nayagarh",
    "state": "Odisha",
    "pincode": "801854"
  },
  {
    "district": "Nuapada",
    "state": "Odisha",
    "pincode": "801895"
  },
  {
    "district": "Nuapada",
    "state": "Odisha",
    "pincode": "801894"
  },
  {
    "district": "Puri",
    "state": "Odisha",
    "pincode": "801862"
  },
  {
    "district": "Puri",
    "state": "Odisha",
    "pincode": "801861"
  },
  {
    "district": "Puri",
    "state": "Odisha",
    "pincode": "801860"
  },
  {
    "district": "Puri",
    "state": "Odisha",
    "pincode": "801863"
  },
  {
    "district": "Rayagada",
    "state": "Odisha",
    "pincode": "801901"
  },
  {
    "district": "Rayagada",
    "state": "Odisha",
    "pincode": "801900"
  },
  {
    "district": "Rayagada",
    "state": "Odisha",
    "pincode": "801899"
  },
  {
    "district": "Sambalpur",
    "state": "Odisha",
    "pincode": "801811"
  },
  {
    "district": "Sambalpur",
    "state": "Odisha",
    "pincode": "801810"
  },
  {
    "district": "Sambalpur",
    "state": "Odisha",
    "pincode": "801809"
  },
  {
    "district": "Sambalpur",
    "state": "Odisha",
    "pincode": "801812"
  },
  {
    "district": "Sambalpur",
    "state": "Odisha",
    "pincode": "801813"
  },
  {
    "district": "Subarnapur",
    "state": "Odisha",
    "pincode": "801887"
  },
  {
    "district": "Subarnapur",
    "state": "Odisha",
    "pincode": "801888"
  },
  {
    "district": "Subarnapur",
    "state": "Odisha",
    "pincode": "801889"
  },
  {
    "district": "Sundargarh",
    "state": "Odisha",
    "pincode": "801818"
  },
  {
    "district": "Sundargarh",
    "state": "Odisha",
    "pincode": "801816"
  },
  {
    "district": "Sundargarh",
    "state": "Odisha",
    "pincode": "801819"
  },
  {
    "district": "Sundargarh",
    "state": "Odisha",
    "pincode": "801815"
  },
  {
    "district": "Bargarh",
    "state": "Orissa",
    "pincode": "380419"
  },
  {
    "district": "Nayagarh",
    "state": "Orissa",
    "pincode": "406632"
  },
  {
    "district": "Karaikal",
    "state": "Puducherry",
    "pincode": "804040"
  },
  {
    "district": "Mahe",
    "state": "Puducherry",
    "pincode": "804039"
  },
  {
    "district": "Puducherry",
    "state": "Puducherry",
    "pincode": "804038"
  },
  {
    "district": "Puducherry",
    "state": "Puducherry",
    "pincode": "804037"
  },
  {
    "district": "Puducherry",
    "state": "Puducherry",
    "pincode": "804036"
  },
  {
    "district": "Yanam",
    "state": "Puducherry",
    "pincode": "804035"
  },
  {
    "district": "Amristar",
    "state": "Punjab",
    "pincode": "800247"
  },
  {
    "district": "Amristar",
    "state": "Punjab",
    "pincode": "800252"
  },
  {
    "district": "Amristar",
    "state": "Punjab",
    "pincode": "800251"
  },
  {
    "district": "Amristar",
    "state": "Punjab",
    "pincode": "800249"
  },
  {
    "district": "Amristar",
    "state": "Punjab",
    "pincode": "800248"
  },
  {
    "district": "Amristar",
    "state": "Punjab",
    "pincode": "800246"
  },
  {
    "district": "Amristar",
    "state": "Punjab",
    "pincode": "37598"
  },
  {
    "district": "Amristar",
    "state": "Punjab",
    "pincode": "800253"
  },
  {
    "district": "Barnala",
    "state": "Punjab",
    "pincode": "800281"
  },
  {
    "district": "Barnala",
    "state": "Punjab",
    "pincode": "800285"
  },
  {
    "district": "Barnala",
    "state": "Punjab",
    "pincode": "800283"
  },
  {
    "district": "Barnala",
    "state": "Punjab",
    "pincode": "800282"
  },
  {
    "district": "Barnala",
    "state": "Punjab",
    "pincode": "800284"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "800226"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "800222"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "35656"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "800224"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "900017"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "800225"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "800228"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "35801"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "35636"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "35731"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "35635"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "35678"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "800231"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "35722"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "800229"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "800223"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "800227"
  },
  {
    "district": "Bathinda",
    "state": "Punjab",
    "pincode": "800230"
  },
  {
    "district": "Faridkot",
    "state": "Punjab",
    "pincode": "800219"
  },
  {
    "district": "Faridkot",
    "state": "Punjab",
    "pincode": "800221"
  },
  {
    "district": "Faridkot",
    "state": "Punjab",
    "pincode": "800220"
  },
  {
    "district": "Fathegarh Sahib",
    "state": "Punjab",
    "pincode": "800186"
  },
  {
    "district": "Fathegarh Sahib",
    "state": "Punjab",
    "pincode": "800184"
  },
  {
    "district": "Fathegarh Sahib",
    "state": "Punjab",
    "pincode": "800187"
  },
  {
    "district": "Fathegarh Sahib",
    "state": "Punjab",
    "pincode": "800188"
  },
  {
    "district": "Fathegarh Sahib",
    "state": "Punjab",
    "pincode": "800185"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "800214"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "35024"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "800213"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "800207"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "800211"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "800212"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "800204"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "800206"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "34736"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "35212"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "800210"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "800209"
  },
  {
    "district": "Firozpur",
    "state": "Punjab",
    "pincode": "800205"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "800149"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "800152"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "800147"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "800145"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "800148"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "800146"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "28200"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "800144"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "800150"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "800151"
  },
  {
    "district": "Gurdaspur",
    "state": "Punjab",
    "pincode": "800143"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "800172"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "800173"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "800179"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "800175"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "800176"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "800178"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "31056"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "800174"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "800177"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "31344"
  },
  {
    "district": "Hoshiarpur",
    "state": "Punjab",
    "pincode": "800171"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800168"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800167"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800170"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "30044"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800162"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800166"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800169"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800159"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800161"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800164"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800163"
  },
  {
    "district": "Jalandhar",
    "state": "Punjab",
    "pincode": "800160"
  },
  {
    "district": "Kapurthala",
    "state": "Punjab",
    "pincode": "800154"
  },
  {
    "district": "Kapurthala",
    "state": "Punjab",
    "pincode": "800153"
  },
  {
    "district": "Kapurthala",
    "state": "Punjab",
    "pincode": "800156"
  },
  {
    "district": "Kapurthala",
    "state": "Punjab",
    "pincode": "800155"
  },
  {
    "district": "Kapurthala",
    "state": "Punjab",
    "pincode": "800158"
  },
  {
    "district": "Kapurthala",
    "state": "Punjab",
    "pincode": "800157"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800194"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800199"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800191"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800196"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800189"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800193"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800197"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800192"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800198"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800195"
  },
  {
    "district": "Ludhiana",
    "state": "Punjab",
    "pincode": "800190"
  },
  {
    "district": "Mansa",
    "state": "Punjab",
    "pincode": "800233"
  },
  {
    "district": "Mansa",
    "state": "Punjab",
    "pincode": "800235"
  },
  {
    "district": "Mansa",
    "state": "Punjab",
    "pincode": "36020"
  },
  {
    "district": "Mansa",
    "state": "Punjab",
    "pincode": "800234"
  },
  {
    "district": "Mansa",
    "state": "Punjab",
    "pincode": "36144"
  },
  {
    "district": "Mansa",
    "state": "Punjab",
    "pincode": "800236"
  },
  {
    "district": "Mansa",
    "state": "Punjab",
    "pincode": "800232"
  },
  {
    "district": "Moga",
    "state": "Punjab",
    "pincode": "800200"
  },
  {
    "district": "Moga",
    "state": "Punjab",
    "pincode": "800201"
  },
  {
    "district": "Moga",
    "state": "Punjab",
    "pincode": "800203"
  },
  {
    "district": "Moga",
    "state": "Punjab",
    "pincode": "34220"
  },
  {
    "district": "Moga",
    "state": "Punjab",
    "pincode": "800202"
  },
  {
    "district": "Moga",
    "state": "Punjab",
    "pincode": "33912"
  },
  {
    "district": "Mukstar",
    "state": "Punjab",
    "pincode": "800218"
  },
  {
    "district": "Mukstar",
    "state": "Punjab",
    "pincode": "800216"
  },
  {
    "district": "Mukstar",
    "state": "Punjab",
    "pincode": "800215"
  },
  {
    "district": "Mukstar",
    "state": "Punjab",
    "pincode": "800217"
  },
  {
    "district": "Patiala",
    "state": "Punjab",
    "pincode": "800241"
  },
  {
    "district": "Patiala",
    "state": "Punjab",
    "pincode": "800239"
  },
  {
    "district": "Patiala",
    "state": "Punjab",
    "pincode": "800244"
  },
  {
    "district": "Patiala",
    "state": "Punjab",
    "pincode": "800240"
  },
  {
    "district": "Patiala",
    "state": "Punjab",
    "pincode": "800242"
  },
  {
    "district": "Patiala",
    "state": "Punjab",
    "pincode": "800238"
  },
  {
    "district": "Patiala",
    "state": "Punjab",
    "pincode": "800245"
  },
  {
    "district": "Patiala",
    "state": "Punjab",
    "pincode": "800237"
  },
  {
    "district": "Patiala",
    "state": "Punjab",
    "pincode": "800243"
  },
  {
    "district": "Rupnagar",
    "state": "Punjab",
    "pincode": "800257"
  },
  {
    "district": "Rupnagar",
    "state": "Punjab",
    "pincode": "800260"
  },
  {
    "district": "Rupnagar",
    "state": "Punjab",
    "pincode": "800261"
  },
  {
    "district": "Rupnagar",
    "state": "Punjab",
    "pincode": "800258"
  },
  {
    "district": "Rupnagar",
    "state": "Punjab",
    "pincode": "800259"
  },
  {
    "district": "Sahibzada  Ajit Singh Nagar",
    "state": "Punjab",
    "pincode": "800265"
  },
  {
    "district": "Sahibzada  Ajit Singh Nagar",
    "state": "Punjab",
    "pincode": "800268"
  },
  {
    "district": "Sahibzada  Ajit Singh Nagar",
    "state": "Punjab",
    "pincode": "800263"
  },
  {
    "district": "Sahibzada  Ajit Singh Nagar",
    "state": "Punjab",
    "pincode": "800262"
  },
  {
    "district": "Sahibzada  Ajit Singh Nagar",
    "state": "Punjab",
    "pincode": "39387"
  },
  {
    "district": "Sahibzada  Ajit Singh Nagar",
    "state": "Punjab",
    "pincode": "800264"
  },
  {
    "district": "Sahibzada  Ajit Singh Nagar",
    "state": "Punjab",
    "pincode": "800266"
  },
  {
    "district": "Sahibzada  Ajit Singh Nagar",
    "state": "Punjab",
    "pincode": "800267"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800269"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "39571"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800272"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800275"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800271"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800277"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800280"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800278"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800274"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800270"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800279"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800273"
  },
  {
    "district": "Sangrur",
    "state": "Punjab",
    "pincode": "800276"
  },
  {
    "district": "Shahid Bhagat Singh Nagar",
    "state": "Punjab",
    "pincode": "800183"
  },
  {
    "district": "Shahid Bhagat Singh Nagar",
    "state": "Punjab",
    "pincode": "800180"
  },
  {
    "district": "Shahid Bhagat Singh Nagar",
    "state": "Punjab",
    "pincode": "800181"
  },
  {
    "district": "Shahid Bhagat Singh Nagar",
    "state": "Punjab",
    "pincode": "800182"
  },
  {
    "district": "Tarn Taran",
    "state": "Punjab",
    "pincode": "38125"
  },
  {
    "district": "Tarn Taran",
    "state": "Punjab",
    "pincode": "38244"
  },
  {
    "district": "Tarn Taran",
    "state": "Punjab",
    "pincode": "800256"
  },
  {
    "district": "Tarn Taran",
    "state": "Punjab",
    "pincode": "800255"
  },
  {
    "district": "Tarn Taran",
    "state": "Punjab",
    "pincode": "800254"
  },
  {
    "district": "Ajmer",
    "state": "Rajasthan",
    "pincode": "800570"
  },
  {
    "district": "Ajmer",
    "state": "Rajasthan",
    "pincode": "800571"
  },
  {
    "district": "Ajmer",
    "state": "Rajasthan",
    "pincode": "800575"
  },
  {
    "district": "Ajmer",
    "state": "Rajasthan",
    "pincode": "800568"
  },
  {
    "district": "Ajmer",
    "state": "Rajasthan",
    "pincode": "800573"
  },
  {
    "district": "Ajmer",
    "state": "Rajasthan",
    "pincode": "800569"
  },
  {
    "district": "Ajmer",
    "state": "Rajasthan",
    "pincode": "800574"
  },
  {
    "district": "Ajmer",
    "state": "Rajasthan",
    "pincode": "800572"
  },
  {
    "district": "Alwar",
    "state": "Rajasthan",
    "pincode": "800490"
  },
  {
    "district": "Alwar",
    "state": "Rajasthan",
    "pincode": "800486"
  },
  {
    "district": "Alwar",
    "state": "Rajasthan",
    "pincode": "800487"
  },
  {
    "district": "Alwar",
    "state": "Rajasthan",
    "pincode": "800489"
  },
  {
    "district": "Alwar",
    "state": "Rajasthan",
    "pincode": "800492"
  },
  {
    "district": "Alwar",
    "state": "Rajasthan",
    "pincode": "72517"
  },
  {
    "district": "Alwar",
    "state": "Rajasthan",
    "pincode": "800491"
  },
  {
    "district": "Alwar",
    "state": "Rajasthan",
    "pincode": "800488"
  },
  {
    "district": "Banswara",
    "state": "Rajasthan",
    "pincode": "800601"
  },
  {
    "district": "Banswara",
    "state": "Rajasthan",
    "pincode": "800602"
  },
  {
    "district": "Baran",
    "state": "Rajasthan",
    "pincode": "800614"
  },
  {
    "district": "Baran",
    "state": "Rajasthan",
    "pincode": "800615"
  },
  {
    "district": "Baran",
    "state": "Rajasthan",
    "pincode": "800616"
  },
  {
    "district": "Baran",
    "state": "Rajasthan",
    "pincode": "800613"
  },
  {
    "district": "Barmer",
    "state": "Rajasthan",
    "pincode": "800549"
  },
  {
    "district": "Barmer",
    "state": "Rajasthan",
    "pincode": "800550"
  },
  {
    "district": "Bharatpur",
    "state": "Rajasthan",
    "pincode": "800501"
  },
  {
    "district": "Bharatpur",
    "state": "Rajasthan",
    "pincode": "800498"
  },
  {
    "district": "Bharatpur",
    "state": "Rajasthan",
    "pincode": "800499"
  },
  {
    "district": "Bharatpur",
    "state": "Rajasthan",
    "pincode": "800495"
  },
  {
    "district": "Bharatpur",
    "state": "Rajasthan",
    "pincode": "800493"
  },
  {
    "district": "Bharatpur",
    "state": "Rajasthan",
    "pincode": "800497"
  },
  {
    "district": "Bharatpur",
    "state": "Rajasthan",
    "pincode": "800496"
  },
  {
    "district": "Bharatpur",
    "state": "Rajasthan",
    "pincode": "800494"
  },
  {
    "district": "Bharatpur",
    "state": "Rajasthan",
    "pincode": "75339"
  },
  {
    "district": "Bharatpur",
    "state": "Rajasthan",
    "pincode": "800500"
  },
  {
    "district": "Bhilwara",
    "state": "Rajasthan",
    "pincode": "800588"
  },
  {
    "district": "Bhilwara",
    "state": "Rajasthan",
    "pincode": "800592"
  },
  {
    "district": "Bhilwara",
    "state": "Rajasthan",
    "pincode": "800591"
  },
  {
    "district": "Bhilwara",
    "state": "Rajasthan",
    "pincode": "800589"
  },
  {
    "district": "Bhilwara",
    "state": "Rajasthan",
    "pincode": "800593"
  },
  {
    "district": "Bhilwara",
    "state": "Rajasthan",
    "pincode": "800594"
  },
  {
    "district": "Bhilwara",
    "state": "Rajasthan",
    "pincode": "800590"
  },
  {
    "district": "Bikaner",
    "state": "Rajasthan",
    "pincode": "800460"
  },
  {
    "district": "Bikaner",
    "state": "Rajasthan",
    "pincode": "800461"
  },
  {
    "district": "Bikaner",
    "state": "Rajasthan",
    "pincode": "800463"
  },
  {
    "district": "Bikaner",
    "state": "Rajasthan",
    "pincode": "800462"
  },
  {
    "district": "Bundi",
    "state": "Rajasthan",
    "pincode": "800587"
  },
  {
    "district": "Bundi",
    "state": "Rajasthan",
    "pincode": "800583"
  },
  {
    "district": "Bundi",
    "state": "Rajasthan",
    "pincode": "800586"
  },
  {
    "district": "Bundi",
    "state": "Rajasthan",
    "pincode": "800585"
  },
  {
    "district": "Bundi",
    "state": "Rajasthan",
    "pincode": "800584"
  },
  {
    "district": "Bundi",
    "state": "Rajasthan",
    "pincode": "800582"
  },
  {
    "district": "Chittaurgarh",
    "state": "Rajasthan",
    "pincode": "800608"
  },
  {
    "district": "Chittaurgarh",
    "state": "Rajasthan",
    "pincode": "800603"
  },
  {
    "district": "Chittaurgarh",
    "state": "Rajasthan",
    "pincode": "800605"
  },
  {
    "district": "Chittaurgarh",
    "state": "Rajasthan",
    "pincode": "800606"
  },
  {
    "district": "Chittaurgarh",
    "state": "Rajasthan",
    "pincode": "800607"
  },
  {
    "district": "Chittaurgarh",
    "state": "Rajasthan",
    "pincode": "800604"
  },
  {
    "district": "Churu",
    "state": "Rajasthan",
    "pincode": "800471"
  },
  {
    "district": "Churu",
    "state": "Rajasthan",
    "pincode": "800472"
  },
  {
    "district": "Churu",
    "state": "Rajasthan",
    "pincode": "800468"
  },
  {
    "district": "Churu",
    "state": "Rajasthan",
    "pincode": "800470"
  },
  {
    "district": "Churu",
    "state": "Rajasthan",
    "pincode": "800465"
  },
  {
    "district": "Churu",
    "state": "Rajasthan",
    "pincode": "800469"
  },
  {
    "district": "Churu",
    "state": "Rajasthan",
    "pincode": "800467"
  },
  {
    "district": "Churu",
    "state": "Rajasthan",
    "pincode": "800466"
  },
  {
    "district": "Churu",
    "state": "Rajasthan",
    "pincode": "800473"
  },
  {
    "district": "Churu",
    "state": "Rajasthan",
    "pincode": "800464"
  },
  {
    "district": "Dausa",
    "state": "Rajasthan",
    "pincode": "800510"
  },
  {
    "district": "Dausa",
    "state": "Rajasthan",
    "pincode": "800511"
  },
  {
    "district": "Dausa",
    "state": "Rajasthan",
    "pincode": "800512"
  },
  {
    "district": "Dausa",
    "state": "Rajasthan",
    "pincode": "78282"
  },
  {
    "district": "Dhaulpur",
    "state": "Rajasthan",
    "pincode": "800502"
  },
  {
    "district": "Dhaulpur",
    "state": "Rajasthan",
    "pincode": "800503"
  },
  {
    "district": "Dhaulpur",
    "state": "Rajasthan",
    "pincode": "800504"
  },
  {
    "district": "Dungarpur",
    "state": "Rajasthan",
    "pincode": "800599"
  },
  {
    "district": "Dungarpur",
    "state": "Rajasthan",
    "pincode": "800600"
  },
  {
    "district": "Ganganagar",
    "state": "Rajasthan",
    "pincode": "800451"
  },
  {
    "district": "Ganganagar",
    "state": "Rajasthan",
    "pincode": "800448"
  },
  {
    "district": "Ganganagar",
    "state": "Rajasthan",
    "pincode": "800446"
  },
  {
    "district": "Ganganagar",
    "state": "Rajasthan",
    "pincode": "800445"
  },
  {
    "district": "Ganganagar",
    "state": "Rajasthan",
    "pincode": "800444"
  },
  {
    "district": "Ganganagar",
    "state": "Rajasthan",
    "pincode": "800449"
  },
  {
    "district": "Ganganagar",
    "state": "Rajasthan",
    "pincode": "800450"
  },
  {
    "district": "Ganganagar",
    "state": "Rajasthan",
    "pincode": "800447"
  },
  {
    "district": "Ganganagar",
    "state": "Rajasthan",
    "pincode": "800453"
  },
  {
    "district": "Ganganagar",
    "state": "Rajasthan",
    "pincode": "800452"
  },
  {
    "district": "Hanumangarh",
    "state": "Rajasthan",
    "pincode": "800459"
  },
  {
    "district": "Hanumangarh",
    "state": "Rajasthan",
    "pincode": "800455"
  },
  {
    "district": "Hanumangarh",
    "state": "Rajasthan",
    "pincode": "800458"
  },
  {
    "district": "Hanumangarh",
    "state": "Rajasthan",
    "pincode": "800456"
  },
  {
    "district": "Hanumangarh",
    "state": "Rajasthan",
    "pincode": "800457"
  },
  {
    "district": "Hanumangarh",
    "state": "Rajasthan",
    "pincode": "800454"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800521"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800523"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800516"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800522"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800520"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800517"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800513"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800519"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800518"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800515"
  },
  {
    "district": "Jaipur",
    "state": "Rajasthan",
    "pincode": "800514"
  },
  {
    "district": "Jaisalmer",
    "state": "Rajasthan",
    "pincode": "800547"
  },
  {
    "district": "Jaisalmer",
    "state": "Rajasthan",
    "pincode": "800548"
  },
  {
    "district": "Jalor",
    "state": "Rajasthan",
    "pincode": "800552"
  },
  {
    "district": "Jalor",
    "state": "Rajasthan",
    "pincode": "800551"
  },
  {
    "district": "Jalor",
    "state": "Rajasthan",
    "pincode": "800553"
  },
  {
    "district": "Jhalawar",
    "state": "Rajasthan",
    "pincode": "800619"
  },
  {
    "district": "Jhalawar",
    "state": "Rajasthan",
    "pincode": "800620"
  },
  {
    "district": "Jhalawar",
    "state": "Rajasthan",
    "pincode": "800618"
  },
  {
    "district": "Jhalawar",
    "state": "Rajasthan",
    "pincode": "800621"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800477"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800474"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800481"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800476"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800482"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800475"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800483"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800484"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800478"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800480"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800485"
  },
  {
    "district": "Jhunjhunun",
    "state": "Rajasthan",
    "pincode": "800479"
  },
  {
    "district": "Jodhpur",
    "state": "Rajasthan",
    "pincode": "800546"
  },
  {
    "district": "Jodhpur",
    "state": "Rajasthan",
    "pincode": "800544"
  },
  {
    "district": "Jodhpur",
    "state": "Rajasthan",
    "pincode": "800543"
  },
  {
    "district": "Jodhpur",
    "state": "Rajasthan",
    "pincode": "800545"
  },
  {
    "district": "Karauli",
    "state": "Rajasthan",
    "pincode": "800506"
  },
  {
    "district": "Karauli",
    "state": "Rajasthan",
    "pincode": "800507"
  },
  {
    "district": "Karauli",
    "state": "Rajasthan",
    "pincode": "800505"
  },
  {
    "district": "Kota",
    "state": "Rajasthan",
    "pincode": "101762"
  },
  {
    "district": "Kota",
    "state": "Rajasthan",
    "pincode": "800610"
  },
  {
    "district": "Kota",
    "state": "Rajasthan",
    "pincode": "800609"
  },
  {
    "district": "Kota",
    "state": "Rajasthan",
    "pincode": "800611"
  },
  {
    "district": "Kota",
    "state": "Rajasthan",
    "pincode": "800612"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "83400"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "800534"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "800541"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "800537"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "800533"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "800540"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "800538"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "800536"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "800535"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "800542"
  },
  {
    "district": "Nagaur",
    "state": "Rajasthan",
    "pincode": "800539"
  },
  {
    "district": "Pali",
    "state": "Rajasthan",
    "pincode": "800567"
  },
  {
    "district": "Pali",
    "state": "Rajasthan",
    "pincode": "800566"
  },
  {
    "district": "Pali",
    "state": "Rajasthan",
    "pincode": "800559"
  },
  {
    "district": "Pali",
    "state": "Rajasthan",
    "pincode": "800561"
  },
  {
    "district": "Pali",
    "state": "Rajasthan",
    "pincode": "800562"
  },
  {
    "district": "Pali",
    "state": "Rajasthan",
    "pincode": "800563"
  },
  {
    "district": "Pali",
    "state": "Rajasthan",
    "pincode": "800560"
  },
  {
    "district": "Pali",
    "state": "Rajasthan",
    "pincode": "800565"
  },
  {
    "district": "Pali",
    "state": "Rajasthan",
    "pincode": "800564"
  },
  {
    "district": "Pratapgarh",
    "state": "Rajasthan",
    "pincode": "800627"
  },
  {
    "district": "Pratapgarh",
    "state": "Rajasthan",
    "pincode": "800628"
  },
  {
    "district": "Rajsamand",
    "state": "Rajasthan",
    "pincode": "800596"
  },
  {
    "district": "Rajsamand",
    "state": "Rajasthan",
    "pincode": "800595"
  },
  {
    "district": "Rajsamand",
    "state": "Rajasthan",
    "pincode": "800598"
  },
  {
    "district": "Rajsamand",
    "state": "Rajasthan",
    "pincode": "800597"
  },
  {
    "district": "Sawai Madhopur",
    "state": "Rajasthan",
    "pincode": "800508"
  },
  {
    "district": "Sawai Madhopur",
    "state": "Rajasthan",
    "pincode": "800509"
  },
  {
    "district": "Sikar",
    "state": "Rajasthan",
    "pincode": "800525"
  },
  {
    "district": "Sikar",
    "state": "Rajasthan",
    "pincode": "800529"
  },
  {
    "district": "Sikar",
    "state": "Rajasthan",
    "pincode": "800526"
  },
  {
    "district": "Sikar",
    "state": "Rajasthan",
    "pincode": "800528"
  },
  {
    "district": "Sikar",
    "state": "Rajasthan",
    "pincode": "800532"
  },
  {
    "district": "Sikar",
    "state": "Rajasthan",
    "pincode": "800524"
  },
  {
    "district": "Sikar",
    "state": "Rajasthan",
    "pincode": "800531"
  },
  {
    "district": "Sikar",
    "state": "Rajasthan",
    "pincode": "800527"
  },
  {
    "district": "Sikar",
    "state": "Rajasthan",
    "pincode": "800530"
  },
  {
    "district": "Sirohi",
    "state": "Rajasthan",
    "pincode": "800558"
  },
  {
    "district": "Sirohi",
    "state": "Rajasthan",
    "pincode": "800557"
  },
  {
    "district": "Sirohi",
    "state": "Rajasthan",
    "pincode": "800556"
  },
  {
    "district": "Sirohi",
    "state": "Rajasthan",
    "pincode": "800554"
  },
  {
    "district": "Sirohi",
    "state": "Rajasthan",
    "pincode": "800555"
  },
  {
    "district": "Tonk",
    "state": "Rajasthan",
    "pincode": "800580"
  },
  {
    "district": "Tonk",
    "state": "Rajasthan",
    "pincode": "800576"
  },
  {
    "district": "Tonk",
    "state": "Rajasthan",
    "pincode": "800577"
  },
  {
    "district": "Tonk",
    "state": "Rajasthan",
    "pincode": "800579"
  },
  {
    "district": "Tonk",
    "state": "Rajasthan",
    "pincode": "800578"
  },
  {
    "district": "Tonk",
    "state": "Rajasthan",
    "pincode": "800581"
  },
  {
    "district": "Udaipur",
    "state": "Rajasthan",
    "pincode": "800624"
  },
  {
    "district": "Udaipur",
    "state": "Rajasthan",
    "pincode": "800622"
  },
  {
    "district": "Udaipur",
    "state": "Rajasthan",
    "pincode": "800625"
  },
  {
    "district": "Udaipur",
    "state": "Rajasthan",
    "pincode": "800626"
  },
  {
    "district": "Udaipur",
    "state": "Rajasthan",
    "pincode": "800623"
  },
  {
    "district": "East District",
    "state": "Sikkim",
    "pincode": "801421"
  },
  {
    "district": "East District",
    "state": "Sikkim",
    "pincode": "801423"
  },
  {
    "district": "East District",
    "state": "Sikkim",
    "pincode": "801422"
  },
  {
    "district": "North  District",
    "state": "Sikkim",
    "pincode": "801416"
  },
  {
    "district": "South District",
    "state": "Sikkim",
    "pincode": "801420"
  },
  {
    "district": "South District",
    "state": "Sikkim",
    "pincode": "801419"
  },
  {
    "district": "West District",
    "state": "Sikkim",
    "pincode": "801417"
  },
  {
    "district": "West District",
    "state": "Sikkim",
    "pincode": "801418"
  },
  {
    "district": "Ariyalur",
    "state": "Tamil Nadu",
    "pincode": "803645"
  },
  {
    "district": "Ariyalur",
    "state": "Tamil Nadu",
    "pincode": "803643"
  },
  {
    "district": "Ariyalur",
    "state": "Tamil Nadu",
    "pincode": "803644"
  },
  {
    "district": "Ariyalur",
    "state": "Tamil Nadu",
    "pincode": "803642"
  },
  {
    "district": "Chennai",
    "state": "Tamil Nadu",
    "pincode": "803339"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803994"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804006"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803969"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803995"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803984"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803986"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803997"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803971"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803973"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803965"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803968"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803962"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803964"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804000"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804009"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803996"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803961"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803963"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803975"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804007"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803998"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803967"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804001"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803972"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803991"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804002"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803993"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804005"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803974"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803960"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804004"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803966"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803992"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803999"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803985"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804010"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803987"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803970"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "803988"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804008"
  },
  {
    "district": "Coimbatore",
    "state": "Tamil Nadu",
    "pincode": "804003"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803658"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803655"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803657"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803650"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803663"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803661"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803656"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803651"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803660"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803662"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803647"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803648"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803649"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803653"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803665"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803654"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803659"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803646"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803666"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803652"
  },
  {
    "district": "Cuddalore",
    "state": "Tamil Nadu",
    "pincode": "803664"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803946"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803948"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803944"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803945"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803943"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803941"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803940"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803942"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803949"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803947"
  },
  {
    "district": "Dharmapuri",
    "state": "Tamil Nadu",
    "pincode": "803950"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803587"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803597"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803577"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803583"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803593"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803578"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803600"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803592"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803589"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803582"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803590"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803574"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803595"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803585"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803575"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803598"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803579"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803576"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803580"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803594"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803599"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803596"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803591"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803586"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803588"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803584"
  },
  {
    "district": "Dindigul",
    "state": "Tamil Nadu",
    "pincode": "803581"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803511"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803512"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803516"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803551"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803507"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803514"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803545"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803517"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803508"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803557"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803535"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803537"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803526"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803542"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803523"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803515"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803530"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803533"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803522"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803506"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803548"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803556"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803527"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803554"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803524"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803521"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803546"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803531"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803528"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803540"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803510"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803513"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803525"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803529"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803547"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803519"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803534"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803532"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803509"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803518"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803505"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803552"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803553"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803550"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803520"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803549"
  },
  {
    "district": "Erode",
    "state": "Tamil Nadu",
    "pincode": "803555"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803373"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803353"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803364"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803343"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803374"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803365"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803371"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803341"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803348"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803372"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803369"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803340"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803362"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803361"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803356"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803354"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803347"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803344"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803346"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803342"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803350"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803345"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803355"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803363"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803370"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803368"
  },
  {
    "district": "Kancheepuram",
    "state": "Tamil Nadu",
    "pincode": "803367"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803937"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803932"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803916"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803933"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803923"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803881"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803900"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803921"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803922"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803917"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803882"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803910"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803890"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803930"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803880"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803884"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803914"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803939"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803909"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803894"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803893"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803892"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803889"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803903"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803934"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803898"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803902"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803886"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803919"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803918"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803925"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803905"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803931"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803927"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803888"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803912"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803885"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803907"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803895"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803883"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803897"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803936"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803891"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803913"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803929"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803924"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803935"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803938"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803926"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803915"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803896"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803899"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803908"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803887"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803904"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803920"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803901"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803906"
  },
  {
    "district": "Kanniyakumari",
    "state": "Tamil Nadu",
    "pincode": "803911"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803601"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803607"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803611"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803613"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803614"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803615"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803612"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803602"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803608"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803605"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803604"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803603"
  },
  {
    "district": "Karur",
    "state": "Tamil Nadu",
    "pincode": "803610"
  },
  {
    "district": "Krishnagiri",
    "state": "Tamil Nadu",
    "pincode": "803953"
  },
  {
    "district": "Krishnagiri",
    "state": "Tamil Nadu",
    "pincode": "803957"
  },
  {
    "district": "Krishnagiri",
    "state": "Tamil Nadu",
    "pincode": "803951"
  },
  {
    "district": "Krishnagiri",
    "state": "Tamil Nadu",
    "pincode": "803955"
  },
  {
    "district": "Krishnagiri",
    "state": "Tamil Nadu",
    "pincode": "803956"
  },
  {
    "district": "Krishnagiri",
    "state": "Tamil Nadu",
    "pincode": "803954"
  },
  {
    "district": "Krishnagiri",
    "state": "Tamil Nadu",
    "pincode": "803958"
  },
  {
    "district": "Krishnagiri",
    "state": "Tamil Nadu",
    "pincode": "803959"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803740"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803748"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803750"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803754"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803741"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803746"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803743"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803752"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803747"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803751"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803753"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803749"
  },
  {
    "district": "Madurai",
    "state": "Tamil Nadu",
    "pincode": "803745"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803675"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803671"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803669"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803670"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803674"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803667"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803677"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803672"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803673"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803668"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803678"
  },
  {
    "district": "Nagapattinam",
    "state": "Tamil Nadu",
    "pincode": "803676"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803485"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803488"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803498"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803495"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803482"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803481"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803499"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803490"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803497"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803483"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803486"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803504"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803500"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803492"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803494"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803502"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803489"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803493"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803491"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803496"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803484"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803501"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803503"
  },
  {
    "district": "Namakkal",
    "state": "Tamil Nadu",
    "pincode": "803487"
  },
  {
    "district": "Perambalur",
    "state": "Tamil Nadu",
    "pincode": "803638"
  },
  {
    "district": "Perambalur",
    "state": "Tamil Nadu",
    "pincode": "803640"
  },
  {
    "district": "Perambalur",
    "state": "Tamil Nadu",
    "pincode": "803641"
  },
  {
    "district": "Perambalur",
    "state": "Tamil Nadu",
    "pincode": "803639"
  },
  {
    "district": "Perambalur",
    "state": "Tamil Nadu",
    "pincode": "803637"
  },
  {
    "district": "Pudukkottai",
    "state": "Tamil Nadu",
    "pincode": "803721"
  },
  {
    "district": "Pudukkottai",
    "state": "Tamil Nadu",
    "pincode": "803716"
  },
  {
    "district": "Pudukkottai",
    "state": "Tamil Nadu",
    "pincode": "803724"
  },
  {
    "district": "Pudukkottai",
    "state": "Tamil Nadu",
    "pincode": "803719"
  },
  {
    "district": "Pudukkottai",
    "state": "Tamil Nadu",
    "pincode": "803715"
  },
  {
    "district": "Pudukkottai",
    "state": "Tamil Nadu",
    "pincode": "803723"
  },
  {
    "district": "Pudukkottai",
    "state": "Tamil Nadu",
    "pincode": "803722"
  },
  {
    "district": "Pudukkottai",
    "state": "Tamil Nadu",
    "pincode": "803717"
  },
  {
    "district": "Pudukkottai",
    "state": "Tamil Nadu",
    "pincode": "803720"
  },
  {
    "district": "Pudukkottai",
    "state": "Tamil Nadu",
    "pincode": "803718"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803807"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803808"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803812"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803811"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803806"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803805"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803804"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803810"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803813"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803809"
  },
  {
    "district": "Ramanathapuram",
    "state": "Tamil Nadu",
    "pincode": "803803"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803459"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803467"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803473"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803470"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803468"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803458"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803456"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803472"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803478"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803464"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803450"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803451"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803462"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803453"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803475"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803443"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803457"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803466"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803444"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803446"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803448"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803474"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803452"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803447"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803465"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803471"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803455"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803463"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803461"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803480"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803479"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803454"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803477"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803460"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803449"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803469"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803476"
  },
  {
    "district": "Salem",
    "state": "Tamil Nadu",
    "pincode": "803445"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803734"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803739"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803728"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803731"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803733"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803730"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803738"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803735"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803725"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803729"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803732"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803726"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803736"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803737"
  },
  {
    "district": "Sivaganga",
    "state": "Tamil Nadu",
    "pincode": "803727"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803712"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803691"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803703"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803701"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803695"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803699"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803697"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803710"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803705"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803702"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803709"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803700"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803711"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803713"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803714"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803698"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803707"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803706"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803696"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803690"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803693"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803704"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803694"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803708"
  },
  {
    "district": "Thanjavur",
    "state": "Tamil Nadu",
    "pincode": "803692"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803569"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803572"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803570"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803559"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803560"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803571"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803566"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803568"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803573"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803565"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803563"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803558"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803561"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803562"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803564"
  },
  {
    "district": "The Nilgiris",
    "state": "Tamil Nadu",
    "pincode": "803567"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803786"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803762"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803760"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803759"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803777"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803764"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803763"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803784"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803780"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803785"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803782"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803783"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803776"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803773"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803774"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803761"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803778"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803770"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803775"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803767"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803781"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803766"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803769"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803768"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803772"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803779"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803765"
  },
  {
    "district": "Theni",
    "state": "Tamil Nadu",
    "pincode": "803771"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803316"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803323"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803314"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803317"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803335"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803320"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803315"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803327"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803321"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803326"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803324"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803322"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803319"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803325"
  },
  {
    "district": "Thiruvallur",
    "state": "Tamil Nadu",
    "pincode": "803318"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803680"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803681"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803686"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803687"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803689"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803683"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803685"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803682"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803688"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803684"
  },
  {
    "district": "Thiruvarur",
    "state": "Tamil Nadu",
    "pincode": "803679"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803829"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803831"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803827"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803825"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803818"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803816"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803815"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803832"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803826"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803817"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803814"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803830"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803823"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803835"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803822"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803824"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803828"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803821"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803833"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803834"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803819"
  },
  {
    "district": "Thoothukkudi",
    "state": "Tamil Nadu",
    "pincode": "803820"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803622"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803626"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803616"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803632"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803629"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803625"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803635"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803618"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803620"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803636"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803627"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803628"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803624"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803630"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803619"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803617"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803623"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803634"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803631"
  },
  {
    "district": "Tiruchirappalli",
    "state": "Tamil Nadu",
    "pincode": "803621"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803851"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803857"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803861"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803864"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803845"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803865"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803849"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803875"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803871"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803848"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803843"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803873"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803867"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803856"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803868"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803870"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803850"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803872"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803862"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803874"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803859"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803878"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803853"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803869"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803854"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803839"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803837"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803844"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803841"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803858"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803855"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803836"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803847"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803842"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803846"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803876"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803840"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803879"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803860"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803852"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803877"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803838"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803866"
  },
  {
    "district": "Tirunelveli",
    "state": "Tamil Nadu",
    "pincode": "803863"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804021"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804019"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804030"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804018"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804012"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804031"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804015"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804017"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804033"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804020"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804032"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804016"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804011"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804028"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804014"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804027"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804034"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804022"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804026"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804029"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804023"
  },
  {
    "district": "Tiruppur",
    "state": "Tamil Nadu",
    "pincode": "804013"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803412"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803421"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803419"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803416"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803417"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803411"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803423"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803414"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803418"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803420"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803422"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803413"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803415"
  },
  {
    "district": "Tiruvannamalai",
    "state": "Tamil Nadu",
    "pincode": "803424"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803405"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803406"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803383"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803387"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803392"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803375"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803409"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803395"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803390"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803386"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803408"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803389"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803403"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803399"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803391"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803402"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803376"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803385"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803382"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803388"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803377"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803394"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803410"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803407"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803404"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803398"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803393"
  },
  {
    "district": "Vellore",
    "state": "Tamil Nadu",
    "pincode": "803384"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803426"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803434"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803441"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803425"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803440"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803429"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803433"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803428"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803437"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803439"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803436"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803427"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803435"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803442"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803438"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803432"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803430"
  },
  {
    "district": "Viluppuram",
    "state": "Tamil Nadu",
    "pincode": "803431"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803801"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803789"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803799"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803800"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803795"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803787"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803790"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803802"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803788"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803797"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803794"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803793"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803796"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803792"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803791"
  },
  {
    "district": "Virudhunagar",
    "state": "Tamil Nadu",
    "pincode": "803798"
  },
  {
    "district": "Jagtial",
    "state": "Telangana",
    "pincode": "569653"
  },
  {
    "district": "Jagtial",
    "state": "Telangana",
    "pincode": "571657"
  },
  {
    "district": "Jogulamba Gadwal",
    "state": "Telangana",
    "pincode": "576440"
  },
  {
    "district": "Jogulamba Gadwal",
    "state": "Telangana",
    "pincode": "576389"
  },
  {
    "district": "Kamareddy",
    "state": "Telangana",
    "pincode": "571384"
  },
  {
    "district": "Kamareddy",
    "state": "Telangana",
    "pincode": "571473"
  },
  {
    "district": "Karimnagar",
    "state": "Telangana",
    "pincode": "572243"
  },
  {
    "district": "Karimnagar",
    "state": "Telangana",
    "pincode": "577097"
  },
  {
    "district": "Khammam",
    "state": "Telangana",
    "pincode": "290068"
  },
  {
    "district": "Mahabubabad",
    "state": "Telangana",
    "pincode": "578679"
  },
  {
    "district": "Mahabubabad",
    "state": "Telangana",
    "pincode": "578638"
  },
  {
    "district": "Mahabubabad",
    "state": "Telangana",
    "pincode": "578549"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telangana",
    "pincode": "575543"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telangana",
    "pincode": "574952"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telangana",
    "pincode": "575879"
  },
  {
    "district": "Manchiryal",
    "state": "Telangana",
    "pincode": "570685"
  },
  {
    "district": "Manchiryal",
    "state": "Telangana",
    "pincode": "569912"
  },
  {
    "district": "Manchiryal",
    "state": "Telangana",
    "pincode": "570590"
  },
  {
    "district": "Manchiryal",
    "state": "Telangana",
    "pincode": "570613"
  },
  {
    "district": "Medak",
    "state": "Telangana",
    "pincode": "572998"
  },
  {
    "district": "Medak",
    "state": "Telangana",
    "pincode": "572935"
  },
  {
    "district": "Medak",
    "state": "Telangana",
    "pincode": "573594"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574171"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "290081"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574070"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574170"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574099"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574137"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574072"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574150"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574078"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574173"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574153"
  },
  {
    "district": "Medchal",
    "state": "Telangana",
    "pincode": "574126"
  },
  {
    "district": "Nalgonda",
    "state": "Telangana",
    "pincode": "577305"
  },
  {
    "district": "Nalgonda",
    "state": "Telangana",
    "pincode": "576878"
  },
  {
    "district": "Nalgonda",
    "state": "Telangana",
    "pincode": "290090"
  },
  {
    "district": "Nalgonda",
    "state": "Telangana",
    "pincode": "576931"
  },
  {
    "district": "Nalgonda",
    "state": "Telangana",
    "pincode": "577401"
  },
  {
    "district": "Nirmal",
    "state": "Telangana",
    "pincode": "579685"
  },
  {
    "district": "Nizamabad",
    "state": "Telangana",
    "pincode": "570851"
  },
  {
    "district": "Peddapalli",
    "state": "Telangana",
    "pincode": "571808"
  },
  {
    "district": "Peddapalli",
    "state": "Telangana",
    "pincode": "572259"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "574827"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "575289"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "574243"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "574222"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "574213"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "575441"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "574214"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "574242"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "574760"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "574045"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "290102"
  },
  {
    "district": "Rangareddy",
    "state": "Telangana",
    "pincode": "574202"
  },
  {
    "district": "Sangareddy",
    "state": "Telangana",
    "pincode": "573924"
  },
  {
    "district": "Sangareddy",
    "state": "Telangana",
    "pincode": "572812"
  },
  {
    "district": "Sangareddy",
    "state": "Telangana",
    "pincode": "573966"
  },
  {
    "district": "Siddipet",
    "state": "Telangana",
    "pincode": "577610"
  },
  {
    "district": "Suryapet",
    "state": "Telangana",
    "pincode": "577526"
  },
  {
    "district": "Suryapet",
    "state": "Telangana",
    "pincode": "576568"
  },
  {
    "district": "Vikarabad",
    "state": "Telangana",
    "pincode": "574905"
  },
  {
    "district": "Vikarabad",
    "state": "Telangana",
    "pincode": "574635"
  },
  {
    "district": "Wanaparthy",
    "state": "Telangana",
    "pincode": "575970"
  },
  {
    "district": "Wanaparthy",
    "state": "Telangana",
    "pincode": "575990"
  },
  {
    "district": "Wanaparthy",
    "state": "Telangana",
    "pincode": "576024"
  },
  {
    "district": "Wanaparthy",
    "state": "Telangana",
    "pincode": "576202"
  },
  {
    "district": "Warangal (Rural)",
    "state": "Telangana",
    "pincode": "578318"
  },
  {
    "district": "Yadadri Bhuvanagiri",
    "state": "Telangana",
    "pincode": "576537"
  },
  {
    "district": "Yadadri Bhuvanagiri",
    "state": "Telangana",
    "pincode": "576841"
  },
  {
    "district": "Yadadri Bhuvanagiri",
    "state": "Telangana",
    "pincode": "576679"
  },
  {
    "district": "Yadadri Bhuvanagiri",
    "state": "Telangana",
    "pincode": "576824"
  },
  {
    "district": "Yadadri Bhuvanagiri",
    "state": "Telangana",
    "pincode": "576531"
  },
  {
    "district": "Adilabad",
    "state": "Telengana",
    "pincode": "802896"
  },
  {
    "district": "Adilabad",
    "state": "Telengana",
    "pincode": "802900"
  },
  {
    "district": "Adilabad",
    "state": "Telengana",
    "pincode": "802898"
  },
  {
    "district": "Adilabad",
    "state": "Telengana",
    "pincode": "802897"
  },
  {
    "district": "Adilabad",
    "state": "Telengana",
    "pincode": "802902"
  },
  {
    "district": "Adilabad",
    "state": "Telengana",
    "pincode": "802901"
  },
  {
    "district": "Adilabad",
    "state": "Telengana",
    "pincode": "802899"
  },
  {
    "district": "Hyderabad",
    "state": "Telengana",
    "pincode": "802918"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "572606"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "572648"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "802908"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "572550"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "802911"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "802909"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "802910"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "571999"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "802907"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "802912"
  },
  {
    "district": "Karimnagar",
    "state": "Telengana",
    "pincode": "572368"
  },
  {
    "district": "Khammam",
    "state": "Telengana",
    "pincode": "802937"
  },
  {
    "district": "Khammam",
    "state": "Telengana",
    "pincode": "802934"
  },
  {
    "district": "Khammam",
    "state": "Telengana",
    "pincode": "579872"
  },
  {
    "district": "Khammam",
    "state": "Telengana",
    "pincode": "802932"
  },
  {
    "district": "Khammam",
    "state": "Telengana",
    "pincode": "802933"
  },
  {
    "district": "Khammam",
    "state": "Telengana",
    "pincode": "802936"
  },
  {
    "district": "Khammam",
    "state": "Telengana",
    "pincode": "802935"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "575653"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "575385"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "802925"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "576291"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "575593"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "576363"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "802922"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "575744"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "802923"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "900001"
  },
  {
    "district": "Mahbubnagar",
    "state": "Telengana",
    "pincode": "802924"
  },
  {
    "district": "Medak",
    "state": "Telengana",
    "pincode": "573948"
  },
  {
    "district": "Medak",
    "state": "Telengana",
    "pincode": "573521"
  },
  {
    "district": "Medak",
    "state": "Telengana",
    "pincode": "573642"
  },
  {
    "district": "Medak",
    "state": "Telengana",
    "pincode": "802913"
  },
  {
    "district": "Medak",
    "state": "Telengana",
    "pincode": "802916"
  },
  {
    "district": "Medak",
    "state": "Telengana",
    "pincode": "802917"
  },
  {
    "district": "Medak",
    "state": "Telengana",
    "pincode": "802914"
  },
  {
    "district": "Medak",
    "state": "Telengana",
    "pincode": "802915"
  },
  {
    "district": "Nalgonda",
    "state": "Telengana",
    "pincode": "802926"
  },
  {
    "district": "Nalgonda",
    "state": "Telengana",
    "pincode": "577259"
  },
  {
    "district": "Nalgonda",
    "state": "Telengana",
    "pincode": "576851"
  },
  {
    "district": "Nalgonda",
    "state": "Telengana",
    "pincode": "577579"
  },
  {
    "district": "Nalgonda",
    "state": "Telengana",
    "pincode": "577573"
  },
  {
    "district": "Nalgonda",
    "state": "Telengana",
    "pincode": "802929"
  },
  {
    "district": "Nalgonda",
    "state": "Telengana",
    "pincode": "802928"
  },
  {
    "district": "Nalgonda",
    "state": "Telengana",
    "pincode": "802927"
  },
  {
    "district": "Nizamabad",
    "state": "Telengana",
    "pincode": "802903"
  },
  {
    "district": "Nizamabad",
    "state": "Telengana",
    "pincode": "802905"
  },
  {
    "district": "Nizamabad",
    "state": "Telengana",
    "pincode": "802906"
  },
  {
    "district": "Nizamabad",
    "state": "Telengana",
    "pincode": "802904"
  },
  {
    "district": "Rangareddy",
    "state": "Telengana",
    "pincode": "574215"
  },
  {
    "district": "Rangareddy",
    "state": "Telengana",
    "pincode": "574853"
  },
  {
    "district": "Rangareddy",
    "state": "Telengana",
    "pincode": "574108"
  },
  {
    "district": "Rangareddy",
    "state": "Telengana",
    "pincode": "574211"
  },
  {
    "district": "Rangareddy",
    "state": "Telengana",
    "pincode": "802921"
  },
  {
    "district": "Rangareddy",
    "state": "Telengana",
    "pincode": "802920"
  },
  {
    "district": "Srikakulam",
    "state": "Telengana",
    "pincode": "581460"
  },
  {
    "district": "Warangal",
    "state": "Telengana",
    "pincode": "577841"
  },
  {
    "district": "Warangal",
    "state": "Telengana",
    "pincode": "802931"
  },
  {
    "district": "Warangal",
    "state": "Telengana",
    "pincode": "578603"
  },
  {
    "district": "Warangal",
    "state": "Telengana",
    "pincode": "577769"
  },
  {
    "district": "Warangal",
    "state": "Telengana",
    "pincode": "802930"
  },
  {
    "district": "Dhalai",
    "state": "Tripura",
    "pincode": "801532"
  },
  {
    "district": "Dhalai",
    "state": "Tripura",
    "pincode": "801531"
  },
  {
    "district": "North Tripura",
    "state": "Tripura",
    "pincode": "801534"
  },
  {
    "district": "North Tripura",
    "state": "Tripura",
    "pincode": "801533"
  },
  {
    "district": "North Tripura",
    "state": "Tripura",
    "pincode": "801535"
  },
  {
    "district": "North Tripura",
    "state": "Tripura",
    "pincode": "272656"
  },
  {
    "district": "South Tripura",
    "state": "Tripura",
    "pincode": "801527"
  },
  {
    "district": "South Tripura",
    "state": "Tripura",
    "pincode": "801529"
  },
  {
    "district": "South Tripura",
    "state": "Tripura",
    "pincode": "801530"
  },
  {
    "district": "South Tripura",
    "state": "Tripura",
    "pincode": "801528"
  },
  {
    "district": "South Tripura",
    "state": "Tripura",
    "pincode": "801526"
  },
  {
    "district": "West Tripura",
    "state": "Tripura",
    "pincode": "801523"
  },
  {
    "district": "West Tripura",
    "state": "Tripura",
    "pincode": "801524"
  },
  {
    "district": "West Tripura",
    "state": "Tripura",
    "pincode": "272006"
  },
  {
    "district": "West Tripura",
    "state": "Tripura",
    "pincode": "801520"
  },
  {
    "district": "West Tripura",
    "state": "Tripura",
    "pincode": "272113"
  },
  {
    "district": "West Tripura",
    "state": "Tripura",
    "pincode": "271849"
  },
  {
    "district": "West Tripura",
    "state": "Tripura",
    "pincode": "801522"
  },
  {
    "district": "West Tripura",
    "state": "Tripura",
    "pincode": "801525"
  },
  {
    "district": "West Tripura",
    "state": "Tripura",
    "pincode": "801521"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800806"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800804"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800814"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800802"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800801"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800812"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800808"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800809"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800810"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800807"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800813"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800811"
  },
  {
    "district": "Agra",
    "state": "Uttar Pradesh",
    "pincode": "800803"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800768"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800765"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800774"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800766"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800767"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800773"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800769"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800763"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800770"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800764"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800771"
  },
  {
    "district": "Aligarh",
    "state": "Uttar Pradesh",
    "pincode": "800772"
  },
  {
    "district": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "801086"
  },
  {
    "district": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "801091"
  },
  {
    "district": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "801089"
  },
  {
    "district": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "801084"
  },
  {
    "district": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "801092"
  },
  {
    "district": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "801083"
  },
  {
    "district": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "801082"
  },
  {
    "district": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "801085"
  },
  {
    "district": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "801088"
  },
  {
    "district": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "801090"
  },
  {
    "district": "Ambedkar Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801116"
  },
  {
    "district": "Ambedkar Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801114"
  },
  {
    "district": "Ambedkar Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801112"
  },
  {
    "district": "Ambedkar Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801115"
  },
  {
    "district": "Ambedkar Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801113"
  },
  {
    "district": "Amroha",
    "state": "Uttar Pradesh",
    "pincode": "800702"
  },
  {
    "district": "Amroha",
    "state": "Uttar Pradesh",
    "pincode": "800699"
  },
  {
    "district": "Amroha",
    "state": "Uttar Pradesh",
    "pincode": "800698"
  },
  {
    "district": "Amroha",
    "state": "Uttar Pradesh",
    "pincode": "800700"
  },
  {
    "district": "Amroha",
    "state": "Uttar Pradesh",
    "pincode": "800704"
  },
  {
    "district": "Amroha",
    "state": "Uttar Pradesh",
    "pincode": "800703"
  },
  {
    "district": "Amroha",
    "state": "Uttar Pradesh",
    "pincode": "800701"
  },
  {
    "district": "Amroha",
    "state": "Uttar Pradesh",
    "pincode": "800705"
  },
  {
    "district": "Auraiya",
    "state": "Uttar Pradesh",
    "pincode": "800987"
  },
  {
    "district": "Auraiya",
    "state": "Uttar Pradesh",
    "pincode": "800989"
  },
  {
    "district": "Auraiya",
    "state": "Uttar Pradesh",
    "pincode": "800992"
  },
  {
    "district": "Auraiya",
    "state": "Uttar Pradesh",
    "pincode": "800988"
  },
  {
    "district": "Auraiya",
    "state": "Uttar Pradesh",
    "pincode": "800986"
  },
  {
    "district": "Auraiya",
    "state": "Uttar Pradesh",
    "pincode": "800991"
  },
  {
    "district": "Auraiya",
    "state": "Uttar Pradesh",
    "pincode": "800990"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801183"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801189"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801187"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801185"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801186"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801193"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801184"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "195176"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801194"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801188"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801190"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801192"
  },
  {
    "district": "Azamgarh",
    "state": "Uttar Pradesh",
    "pincode": "801191"
  },
  {
    "district": "Baghpat",
    "state": "Uttar Pradesh",
    "pincode": "800725"
  },
  {
    "district": "Baghpat",
    "state": "Uttar Pradesh",
    "pincode": "800726"
  },
  {
    "district": "Baghpat",
    "state": "Uttar Pradesh",
    "pincode": "800724"
  },
  {
    "district": "Baghpat",
    "state": "Uttar Pradesh",
    "pincode": "800723"
  },
  {
    "district": "Baghpat",
    "state": "Uttar Pradesh",
    "pincode": "800720"
  },
  {
    "district": "Baghpat",
    "state": "Uttar Pradesh",
    "pincode": "800722"
  },
  {
    "district": "Baghpat",
    "state": "Uttar Pradesh",
    "pincode": "800727"
  },
  {
    "district": "Baghpat",
    "state": "Uttar Pradesh",
    "pincode": "800721"
  },
  {
    "district": "Bahraich",
    "state": "Uttar Pradesh",
    "pincode": "801125"
  },
  {
    "district": "Bahraich",
    "state": "Uttar Pradesh",
    "pincode": "801126"
  },
  {
    "district": "Bahraich",
    "state": "Uttar Pradesh",
    "pincode": "801123"
  },
  {
    "district": "Bahraich",
    "state": "Uttar Pradesh",
    "pincode": "801124"
  },
  {
    "district": "Ballia",
    "state": "Uttar Pradesh",
    "pincode": "801206"
  },
  {
    "district": "Ballia",
    "state": "Uttar Pradesh",
    "pincode": "801208"
  },
  {
    "district": "Ballia",
    "state": "Uttar Pradesh",
    "pincode": "199959"
  },
  {
    "district": "Ballia",
    "state": "Uttar Pradesh",
    "pincode": "801202"
  },
  {
    "district": "Ballia",
    "state": "Uttar Pradesh",
    "pincode": "801205"
  },
  {
    "district": "Ballia",
    "state": "Uttar Pradesh",
    "pincode": "801207"
  },
  {
    "district": "Ballia",
    "state": "Uttar Pradesh",
    "pincode": "801204"
  },
  {
    "district": "Ballia",
    "state": "Uttar Pradesh",
    "pincode": "801210"
  },
  {
    "district": "Ballia",
    "state": "Uttar Pradesh",
    "pincode": "801209"
  },
  {
    "district": "Ballia",
    "state": "Uttar Pradesh",
    "pincode": "801203"
  },
  {
    "district": "Balrampur",
    "state": "Uttar Pradesh",
    "pincode": "801129"
  },
  {
    "district": "Balrampur",
    "state": "Uttar Pradesh",
    "pincode": "801131"
  },
  {
    "district": "Balrampur",
    "state": "Uttar Pradesh",
    "pincode": "801130"
  },
  {
    "district": "Balrampur",
    "state": "Uttar Pradesh",
    "pincode": "801132"
  },
  {
    "district": "Banda",
    "state": "Uttar Pradesh",
    "pincode": "801056"
  },
  {
    "district": "Banda",
    "state": "Uttar Pradesh",
    "pincode": "801053"
  },
  {
    "district": "Banda",
    "state": "Uttar Pradesh",
    "pincode": "801051"
  },
  {
    "district": "Banda",
    "state": "Uttar Pradesh",
    "pincode": "801055"
  },
  {
    "district": "Banda",
    "state": "Uttar Pradesh",
    "pincode": "801050"
  },
  {
    "district": "Banda",
    "state": "Uttar Pradesh",
    "pincode": "801057"
  },
  {
    "district": "Banda",
    "state": "Uttar Pradesh",
    "pincode": "801054"
  },
  {
    "district": "Banda",
    "state": "Uttar Pradesh",
    "pincode": "801052"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801097"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "163995"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801101"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801095"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801093"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801103"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801096"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801094"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801098"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801102"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801104"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801100"
  },
  {
    "district": "Bara Banki",
    "state": "Uttar Pradesh",
    "pincode": "801099"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800863"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800853"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800866"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800862"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800856"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800865"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800873"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800854"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800860"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800872"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800859"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800871"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800855"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800869"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800870"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800861"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800857"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800858"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800864"
  },
  {
    "district": "Bareilly",
    "state": "Uttar Pradesh",
    "pincode": "800868"
  },
  {
    "district": "Basti",
    "state": "Uttar Pradesh",
    "pincode": "181073"
  },
  {
    "district": "Basti",
    "state": "Uttar Pradesh",
    "pincode": "801147"
  },
  {
    "district": "Basti",
    "state": "Uttar Pradesh",
    "pincode": "801146"
  },
  {
    "district": "Basti",
    "state": "Uttar Pradesh",
    "pincode": "801145"
  },
  {
    "district": "Basti",
    "state": "Uttar Pradesh",
    "pincode": "900045"
  },
  {
    "district": "Bhadohi",
    "state": "Uttar Pradesh",
    "pincode": "801239"
  },
  {
    "district": "Bhadohi",
    "state": "Uttar Pradesh",
    "pincode": "801243"
  },
  {
    "district": "Bhadohi",
    "state": "Uttar Pradesh",
    "pincode": "801241"
  },
  {
    "district": "Bhadohi",
    "state": "Uttar Pradesh",
    "pincode": "801240"
  },
  {
    "district": "Bhadohi",
    "state": "Uttar Pradesh",
    "pincode": "801242"
  },
  {
    "district": "Bhadohi",
    "state": "Uttar Pradesh",
    "pincode": "801238"
  },
  {
    "district": "Bhadohi",
    "state": "Uttar Pradesh",
    "pincode": "801237"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800670"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800665"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800677"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800672"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800667"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800662"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800666"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800663"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800664"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800668"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800661"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800673"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800676"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800660"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800675"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800674"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800671"
  },
  {
    "district": "Bijnor",
    "state": "Uttar Pradesh",
    "pincode": "800669"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800849"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800831"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800840"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800836"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800844"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800850"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800834"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800830"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800846"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800832"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800833"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800842"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800848"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800845"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800835"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800839"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800841"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800837"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800847"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800843"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800851"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800852"
  },
  {
    "district": "Budaun",
    "state": "Uttar Pradesh",
    "pincode": "800838"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800755"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800748"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800751"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800753"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800749"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800761"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800757"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800750"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800756"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800747"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800754"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800762"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800758"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800760"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800759"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800752"
  },
  {
    "district": "Bulandshahr",
    "state": "Uttar Pradesh",
    "pincode": "800746"
  },
  {
    "district": "Chandauli",
    "state": "Uttar Pradesh",
    "pincode": "801231"
  },
  {
    "district": "Chandauli",
    "state": "Uttar Pradesh",
    "pincode": "801229"
  },
  {
    "district": "Chandauli",
    "state": "Uttar Pradesh",
    "pincode": "801227"
  },
  {
    "district": "Chandauli",
    "state": "Uttar Pradesh",
    "pincode": "801230"
  },
  {
    "district": "Chitrakoot",
    "state": "Uttar Pradesh",
    "pincode": "801059"
  },
  {
    "district": "Chitrakoot",
    "state": "Uttar Pradesh",
    "pincode": "801058"
  },
  {
    "district": "Chitrakoot",
    "state": "Uttar Pradesh",
    "pincode": "801060"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "190235"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "801178"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "801182"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "801175"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "801177"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "801173"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "801181"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "801179"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "801174"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "801176"
  },
  {
    "district": "Deoria",
    "state": "Uttar Pradesh",
    "pincode": "801180"
  },
  {
    "district": "Etah",
    "state": "Uttar Pradesh",
    "pincode": "801259"
  },
  {
    "district": "Etah",
    "state": "Uttar Pradesh",
    "pincode": "801266"
  },
  {
    "district": "Etah",
    "state": "Uttar Pradesh",
    "pincode": "801262"
  },
  {
    "district": "Etah",
    "state": "Uttar Pradesh",
    "pincode": "801260"
  },
  {
    "district": "Etah",
    "state": "Uttar Pradesh",
    "pincode": "801265"
  },
  {
    "district": "Etah",
    "state": "Uttar Pradesh",
    "pincode": "801263"
  },
  {
    "district": "Etah",
    "state": "Uttar Pradesh",
    "pincode": "801264"
  },
  {
    "district": "Etah",
    "state": "Uttar Pradesh",
    "pincode": "801258"
  },
  {
    "district": "Etah",
    "state": "Uttar Pradesh",
    "pincode": "801261"
  },
  {
    "district": "Etawah",
    "state": "Uttar Pradesh",
    "pincode": "800984"
  },
  {
    "district": "Etawah",
    "state": "Uttar Pradesh",
    "pincode": "800983"
  },
  {
    "district": "Etawah",
    "state": "Uttar Pradesh",
    "pincode": "800982"
  },
  {
    "district": "Etawah",
    "state": "Uttar Pradesh",
    "pincode": "800981"
  },
  {
    "district": "Etawah",
    "state": "Uttar Pradesh",
    "pincode": "800980"
  },
  {
    "district": "Etawah",
    "state": "Uttar Pradesh",
    "pincode": "800985"
  },
  {
    "district": "Faizabad",
    "state": "Uttar Pradesh",
    "pincode": "801109"
  },
  {
    "district": "Faizabad",
    "state": "Uttar Pradesh",
    "pincode": "801106"
  },
  {
    "district": "Faizabad",
    "state": "Uttar Pradesh",
    "pincode": "801111"
  },
  {
    "district": "Faizabad",
    "state": "Uttar Pradesh",
    "pincode": "801110"
  },
  {
    "district": "Faizabad",
    "state": "Uttar Pradesh",
    "pincode": "801105"
  },
  {
    "district": "Farrukhabad",
    "state": "Uttar Pradesh",
    "pincode": "800968"
  },
  {
    "district": "Farrukhabad",
    "state": "Uttar Pradesh",
    "pincode": "800966"
  },
  {
    "district": "Farrukhabad",
    "state": "Uttar Pradesh",
    "pincode": "800971"
  },
  {
    "district": "Farrukhabad",
    "state": "Uttar Pradesh",
    "pincode": "800965"
  },
  {
    "district": "Farrukhabad",
    "state": "Uttar Pradesh",
    "pincode": "800970"
  },
  {
    "district": "Farrukhabad",
    "state": "Uttar Pradesh",
    "pincode": "800967"
  },
  {
    "district": "Fatehpur",
    "state": "Uttar Pradesh",
    "pincode": "801063"
  },
  {
    "district": "Fatehpur",
    "state": "Uttar Pradesh",
    "pincode": "801062"
  },
  {
    "district": "Fatehpur",
    "state": "Uttar Pradesh",
    "pincode": "801064"
  },
  {
    "district": "Fatehpur",
    "state": "Uttar Pradesh",
    "pincode": "156164"
  },
  {
    "district": "Fatehpur",
    "state": "Uttar Pradesh",
    "pincode": "801067"
  },
  {
    "district": "Fatehpur",
    "state": "Uttar Pradesh",
    "pincode": "801065"
  },
  {
    "district": "Fatehpur",
    "state": "Uttar Pradesh",
    "pincode": "801066"
  },
  {
    "district": "Fatehpur",
    "state": "Uttar Pradesh",
    "pincode": "801061"
  },
  {
    "district": "Firozabad",
    "state": "Uttar Pradesh",
    "pincode": "125705"
  },
  {
    "district": "Firozabad",
    "state": "Uttar Pradesh",
    "pincode": "800817"
  },
  {
    "district": "Firozabad",
    "state": "Uttar Pradesh",
    "pincode": "800816"
  },
  {
    "district": "Firozabad",
    "state": "Uttar Pradesh",
    "pincode": "800818"
  },
  {
    "district": "Firozabad",
    "state": "Uttar Pradesh",
    "pincode": "800819"
  },
  {
    "district": "Firozabad",
    "state": "Uttar Pradesh",
    "pincode": "800820"
  },
  {
    "district": "Firozabad",
    "state": "Uttar Pradesh",
    "pincode": "800815"
  },
  {
    "district": "Gautam Buddha Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800741"
  },
  {
    "district": "Gautam Buddha Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800740"
  },
  {
    "district": "Gautam Buddha Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800742"
  },
  {
    "district": "Gautam Buddha Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800744"
  },
  {
    "district": "Gautam Buddha Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800745"
  },
  {
    "district": "Gautam Buddha Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800743"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800738"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800735"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800731"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800739"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800734"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800737"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "119761"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800733"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800730"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800732"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800729"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800728"
  },
  {
    "district": "Ghaziabad",
    "state": "Uttar Pradesh",
    "pincode": "800736"
  },
  {
    "district": "Ghazipur",
    "state": "Uttar Pradesh",
    "pincode": "801223"
  },
  {
    "district": "Ghazipur",
    "state": "Uttar Pradesh",
    "pincode": "801225"
  },
  {
    "district": "Ghazipur",
    "state": "Uttar Pradesh",
    "pincode": "801222"
  },
  {
    "district": "Ghazipur",
    "state": "Uttar Pradesh",
    "pincode": "801221"
  },
  {
    "district": "Ghazipur",
    "state": "Uttar Pradesh",
    "pincode": "801224"
  },
  {
    "district": "Ghazipur",
    "state": "Uttar Pradesh",
    "pincode": "801219"
  },
  {
    "district": "Ghazipur",
    "state": "Uttar Pradesh",
    "pincode": "801220"
  },
  {
    "district": "Ghazipur",
    "state": "Uttar Pradesh",
    "pincode": "801226"
  },
  {
    "district": "Gonda",
    "state": "Uttar Pradesh",
    "pincode": "801136"
  },
  {
    "district": "Gonda",
    "state": "Uttar Pradesh",
    "pincode": "801134"
  },
  {
    "district": "Gonda",
    "state": "Uttar Pradesh",
    "pincode": "801135"
  },
  {
    "district": "Gonda",
    "state": "Uttar Pradesh",
    "pincode": "801133"
  },
  {
    "district": "Gonda",
    "state": "Uttar Pradesh",
    "pincode": "801138"
  },
  {
    "district": "Gonda",
    "state": "Uttar Pradesh",
    "pincode": "801137"
  },
  {
    "district": "Gonda",
    "state": "Uttar Pradesh",
    "pincode": "175107"
  },
  {
    "district": "Gorakhpur",
    "state": "Uttar Pradesh",
    "pincode": "801163"
  },
  {
    "district": "Gorakhpur",
    "state": "Uttar Pradesh",
    "pincode": "801165"
  },
  {
    "district": "Gorakhpur",
    "state": "Uttar Pradesh",
    "pincode": "801164"
  },
  {
    "district": "Gorakhpur",
    "state": "Uttar Pradesh",
    "pincode": "801160"
  },
  {
    "district": "Gorakhpur",
    "state": "Uttar Pradesh",
    "pincode": "801162"
  },
  {
    "district": "Gorakhpur",
    "state": "Uttar Pradesh",
    "pincode": "801158"
  },
  {
    "district": "Gorakhpur",
    "state": "Uttar Pradesh",
    "pincode": "801161"
  },
  {
    "district": "Gorakhpur",
    "state": "Uttar Pradesh",
    "pincode": "801159"
  },
  {
    "district": "Gorakhpur",
    "state": "Uttar Pradesh",
    "pincode": "186978"
  },
  {
    "district": "Hamirpur",
    "state": "Uttar Pradesh",
    "pincode": "801041"
  },
  {
    "district": "Hamirpur",
    "state": "Uttar Pradesh",
    "pincode": "801039"
  },
  {
    "district": "Hamirpur",
    "state": "Uttar Pradesh",
    "pincode": "801038"
  },
  {
    "district": "Hamirpur",
    "state": "Uttar Pradesh",
    "pincode": "801044"
  },
  {
    "district": "Hamirpur",
    "state": "Uttar Pradesh",
    "pincode": "153693"
  },
  {
    "district": "Hamirpur",
    "state": "Uttar Pradesh",
    "pincode": "801042"
  },
  {
    "district": "Hamirpur",
    "state": "Uttar Pradesh",
    "pincode": "801043"
  },
  {
    "district": "Hamirpur",
    "state": "Uttar Pradesh",
    "pincode": "801040"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800926"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800921"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800918"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800919"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800925"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800924"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800922"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800923"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800917"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800916"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800920"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800927"
  },
  {
    "district": "Hardoi",
    "state": "Uttar Pradesh",
    "pincode": "800915"
  },
  {
    "district": "Jalaun",
    "state": "Uttar Pradesh",
    "pincode": "801011"
  },
  {
    "district": "Jalaun",
    "state": "Uttar Pradesh",
    "pincode": "801013"
  },
  {
    "district": "Jalaun",
    "state": "Uttar Pradesh",
    "pincode": "801012"
  },
  {
    "district": "Jalaun",
    "state": "Uttar Pradesh",
    "pincode": "801016"
  },
  {
    "district": "Jalaun",
    "state": "Uttar Pradesh",
    "pincode": "801015"
  },
  {
    "district": "Jalaun",
    "state": "Uttar Pradesh",
    "pincode": "801010"
  },
  {
    "district": "Jalaun",
    "state": "Uttar Pradesh",
    "pincode": "801017"
  },
  {
    "district": "Jalaun",
    "state": "Uttar Pradesh",
    "pincode": "801014"
  },
  {
    "district": "Jalaun",
    "state": "Uttar Pradesh",
    "pincode": "801008"
  },
  {
    "district": "Jalaun",
    "state": "Uttar Pradesh",
    "pincode": "801009"
  },
  {
    "district": "Jaunpur",
    "state": "Uttar Pradesh",
    "pincode": "200771"
  },
  {
    "district": "Jaunpur",
    "state": "Uttar Pradesh",
    "pincode": "801216"
  },
  {
    "district": "Jaunpur",
    "state": "Uttar Pradesh",
    "pincode": "801215"
  },
  {
    "district": "Jaunpur",
    "state": "Uttar Pradesh",
    "pincode": "801218"
  },
  {
    "district": "Jaunpur",
    "state": "Uttar Pradesh",
    "pincode": "801212"
  },
  {
    "district": "Jaunpur",
    "state": "Uttar Pradesh",
    "pincode": "801214"
  },
  {
    "district": "Jaunpur",
    "state": "Uttar Pradesh",
    "pincode": "801217"
  },
  {
    "district": "Jaunpur",
    "state": "Uttar Pradesh",
    "pincode": "801213"
  },
  {
    "district": "Jaunpur",
    "state": "Uttar Pradesh",
    "pincode": "801211"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801029"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801028"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801020"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801021"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801023"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801022"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801030"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801027"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801025"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801019"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801026"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801018"
  },
  {
    "district": "Jhansi",
    "state": "Uttar Pradesh",
    "pincode": "801024"
  },
  {
    "district": "Kannauj",
    "state": "Uttar Pradesh",
    "pincode": "800975"
  },
  {
    "district": "Kannauj",
    "state": "Uttar Pradesh",
    "pincode": "800973"
  },
  {
    "district": "Kannauj",
    "state": "Uttar Pradesh",
    "pincode": "800978"
  },
  {
    "district": "Kannauj",
    "state": "Uttar Pradesh",
    "pincode": "800972"
  },
  {
    "district": "Kannauj",
    "state": "Uttar Pradesh",
    "pincode": "800976"
  },
  {
    "district": "Kannauj",
    "state": "Uttar Pradesh",
    "pincode": "800974"
  },
  {
    "district": "Kannauj",
    "state": "Uttar Pradesh",
    "pincode": "800977"
  },
  {
    "district": "Kannauj",
    "state": "Uttar Pradesh",
    "pincode": "800979"
  },
  {
    "district": "Kanpur Dehat",
    "state": "Uttar Pradesh",
    "pincode": "800998"
  },
  {
    "district": "Kanpur Dehat",
    "state": "Uttar Pradesh",
    "pincode": "800999"
  },
  {
    "district": "Kanpur Dehat",
    "state": "Uttar Pradesh",
    "pincode": "800995"
  },
  {
    "district": "Kanpur Dehat",
    "state": "Uttar Pradesh",
    "pincode": "800994"
  },
  {
    "district": "Kanpur Dehat",
    "state": "Uttar Pradesh",
    "pincode": "801000"
  },
  {
    "district": "Kanpur Dehat",
    "state": "Uttar Pradesh",
    "pincode": "800993"
  },
  {
    "district": "Kanpur Dehat",
    "state": "Uttar Pradesh",
    "pincode": "800997"
  },
  {
    "district": "Kanpur Dehat",
    "state": "Uttar Pradesh",
    "pincode": "800996"
  },
  {
    "district": "Kanpur Dehat",
    "state": "Uttar Pradesh",
    "pincode": "801001"
  },
  {
    "district": "Kanpur Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801002"
  },
  {
    "district": "Kanpur Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801004"
  },
  {
    "district": "Kanpur Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801007"
  },
  {
    "district": "Kanpur Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801005"
  },
  {
    "district": "Kanpur Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801003"
  },
  {
    "district": "Kanshiram Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801271"
  },
  {
    "district": "Kanshiram Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801276"
  },
  {
    "district": "Kanshiram Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801268"
  },
  {
    "district": "Kanshiram Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801273"
  },
  {
    "district": "Kanshiram Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801269"
  },
  {
    "district": "Kanshiram Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801272"
  },
  {
    "district": "Kanshiram Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801274"
  },
  {
    "district": "Kanshiram Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801270"
  },
  {
    "district": "Kanshiram Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801275"
  },
  {
    "district": "Kanshiram Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801267"
  },
  {
    "district": "Kaushambi",
    "state": "Uttar Pradesh",
    "pincode": "801075"
  },
  {
    "district": "Kaushambi",
    "state": "Uttar Pradesh",
    "pincode": "801079"
  },
  {
    "district": "Kaushambi",
    "state": "Uttar Pradesh",
    "pincode": "801080"
  },
  {
    "district": "Kaushambi",
    "state": "Uttar Pradesh",
    "pincode": "801078"
  },
  {
    "district": "Kaushambi",
    "state": "Uttar Pradesh",
    "pincode": "801077"
  },
  {
    "district": "Kaushambi",
    "state": "Uttar Pradesh",
    "pincode": "801081"
  },
  {
    "district": "Kaushambi",
    "state": "Uttar Pradesh",
    "pincode": "801076"
  },
  {
    "district": "Kushinagar",
    "state": "Uttar Pradesh",
    "pincode": "801170"
  },
  {
    "district": "Kushinagar",
    "state": "Uttar Pradesh",
    "pincode": "801169"
  },
  {
    "district": "Kushinagar",
    "state": "Uttar Pradesh",
    "pincode": "801166"
  },
  {
    "district": "Kushinagar",
    "state": "Uttar Pradesh",
    "pincode": "801171"
  },
  {
    "district": "Kushinagar",
    "state": "Uttar Pradesh",
    "pincode": "801167"
  },
  {
    "district": "Kushinagar",
    "state": "Uttar Pradesh",
    "pincode": "801168"
  },
  {
    "district": "Kushinagar",
    "state": "Uttar Pradesh",
    "pincode": "801172"
  },
  {
    "district": "Lakhimpur Kheri",
    "state": "Uttar Pradesh",
    "pincode": "800899"
  },
  {
    "district": "Lakhimpur Kheri",
    "state": "Uttar Pradesh",
    "pincode": "800903"
  },
  {
    "district": "Lakhimpur Kheri",
    "state": "Uttar Pradesh",
    "pincode": "800897"
  },
  {
    "district": "Lakhimpur Kheri",
    "state": "Uttar Pradesh",
    "pincode": "800901"
  },
  {
    "district": "Lakhimpur Kheri",
    "state": "Uttar Pradesh",
    "pincode": "800900"
  },
  {
    "district": "Lakhimpur Kheri",
    "state": "Uttar Pradesh",
    "pincode": "800896"
  },
  {
    "district": "Lakhimpur Kheri",
    "state": "Uttar Pradesh",
    "pincode": "800898"
  },
  {
    "district": "Lakhimpur Kheri",
    "state": "Uttar Pradesh",
    "pincode": "800902"
  },
  {
    "district": "Lakhimpur Kheri",
    "state": "Uttar Pradesh",
    "pincode": "800895"
  },
  {
    "district": "Lakhimpur Kheri",
    "state": "Uttar Pradesh",
    "pincode": "800894"
  },
  {
    "district": "Lalitpur",
    "state": "Uttar Pradesh",
    "pincode": "801035"
  },
  {
    "district": "Lalitpur",
    "state": "Uttar Pradesh",
    "pincode": "801037"
  },
  {
    "district": "Lalitpur",
    "state": "Uttar Pradesh",
    "pincode": "801036"
  },
  {
    "district": "Lalitpur",
    "state": "Uttar Pradesh",
    "pincode": "801034"
  },
  {
    "district": "Lucknow",
    "state": "Uttar Pradesh",
    "pincode": "800954"
  },
  {
    "district": "Lucknow",
    "state": "Uttar Pradesh",
    "pincode": "800949"
  },
  {
    "district": "Lucknow",
    "state": "Uttar Pradesh",
    "pincode": "800953"
  },
  {
    "district": "Lucknow",
    "state": "Uttar Pradesh",
    "pincode": "800948"
  },
  {
    "district": "Lucknow",
    "state": "Uttar Pradesh",
    "pincode": "800950"
  },
  {
    "district": "Lucknow",
    "state": "Uttar Pradesh",
    "pincode": "800951"
  },
  {
    "district": "Lucknow",
    "state": "Uttar Pradesh",
    "pincode": "800947"
  },
  {
    "district": "Lucknow",
    "state": "Uttar Pradesh",
    "pincode": "800946"
  },
  {
    "district": "Lucknow",
    "state": "Uttar Pradesh",
    "pincode": "800955"
  },
  {
    "district": "Mahamaya Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800778"
  },
  {
    "district": "Mahamaya Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800780"
  },
  {
    "district": "Mahamaya Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800779"
  },
  {
    "district": "Mahamaya Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800781"
  },
  {
    "district": "Mahamaya Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800777"
  },
  {
    "district": "Mahamaya Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800782"
  },
  {
    "district": "Mahamaya Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800783"
  },
  {
    "district": "Mahamaya Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800775"
  },
  {
    "district": "Mahamaya Nagar",
    "state": "Uttar Pradesh",
    "pincode": "800776"
  },
  {
    "district": "Maharajganj",
    "state": "Uttar Pradesh",
    "pincode": "801155"
  },
  {
    "district": "Maharajganj",
    "state": "Uttar Pradesh",
    "pincode": "801156"
  },
  {
    "district": "Maharajganj",
    "state": "Uttar Pradesh",
    "pincode": "801157"
  },
  {
    "district": "Maharajganj",
    "state": "Uttar Pradesh",
    "pincode": "801152"
  },
  {
    "district": "Maharajganj",
    "state": "Uttar Pradesh",
    "pincode": "801153"
  },
  {
    "district": "Maharajganj",
    "state": "Uttar Pradesh",
    "pincode": "801154"
  },
  {
    "district": "Maharajganj",
    "state": "Uttar Pradesh",
    "pincode": "900046"
  },
  {
    "district": "Mahoba",
    "state": "Uttar Pradesh",
    "pincode": "801047"
  },
  {
    "district": "Mahoba",
    "state": "Uttar Pradesh",
    "pincode": "801049"
  },
  {
    "district": "Mahoba",
    "state": "Uttar Pradesh",
    "pincode": "801046"
  },
  {
    "district": "Mahoba",
    "state": "Uttar Pradesh",
    "pincode": "801045"
  },
  {
    "district": "Mahoba",
    "state": "Uttar Pradesh",
    "pincode": "801048"
  },
  {
    "district": "Mainpuri",
    "state": "Uttar Pradesh",
    "pincode": "800827"
  },
  {
    "district": "Mainpuri",
    "state": "Uttar Pradesh",
    "pincode": "800826"
  },
  {
    "district": "Mainpuri",
    "state": "Uttar Pradesh",
    "pincode": "800823"
  },
  {
    "district": "Mainpuri",
    "state": "Uttar Pradesh",
    "pincode": "800822"
  },
  {
    "district": "Mainpuri",
    "state": "Uttar Pradesh",
    "pincode": "800825"
  },
  {
    "district": "Mainpuri",
    "state": "Uttar Pradesh",
    "pincode": "800829"
  },
  {
    "district": "Mainpuri",
    "state": "Uttar Pradesh",
    "pincode": "800821"
  },
  {
    "district": "Mainpuri",
    "state": "Uttar Pradesh",
    "pincode": "800828"
  },
  {
    "district": "Mainpuri",
    "state": "Uttar Pradesh",
    "pincode": "800824"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800789"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800793"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800786"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800788"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800787"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800794"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800791"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800797"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800784"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800792"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800799"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800785"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800796"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800790"
  },
  {
    "district": "Mathura",
    "state": "Uttar Pradesh",
    "pincode": "800798"
  },
  {
    "district": "Mau",
    "state": "Uttar Pradesh",
    "pincode": "801199"
  },
  {
    "district": "Mau",
    "state": "Uttar Pradesh",
    "pincode": "801196"
  },
  {
    "district": "Mau",
    "state": "Uttar Pradesh",
    "pincode": "197584"
  },
  {
    "district": "Mau",
    "state": "Uttar Pradesh",
    "pincode": "801195"
  },
  {
    "district": "Mau",
    "state": "Uttar Pradesh",
    "pincode": "801197"
  },
  {
    "district": "Mau",
    "state": "Uttar Pradesh",
    "pincode": "801198"
  },
  {
    "district": "Mau",
    "state": "Uttar Pradesh",
    "pincode": "900047"
  },
  {
    "district": "Mau",
    "state": "Uttar Pradesh",
    "pincode": "801200"
  },
  {
    "district": "Mau",
    "state": "Uttar Pradesh",
    "pincode": "801201"
  },
  {
    "district": "Mau",
    "state": "Uttar Pradesh",
    "pincode": "197677"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800711"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800708"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800712"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800706"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800719"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "118591"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800715"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800709"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800713"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800716"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800714"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800710"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800707"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "800718"
  },
  {
    "district": "Meerut",
    "state": "Uttar Pradesh",
    "pincode": "119005"
  },
  {
    "district": "Mirzapur",
    "state": "Uttar Pradesh",
    "pincode": "801247"
  },
  {
    "district": "Mirzapur",
    "state": "Uttar Pradesh",
    "pincode": "801246"
  },
  {
    "district": "Mirzapur",
    "state": "Uttar Pradesh",
    "pincode": "801245"
  },
  {
    "district": "Mirzapur",
    "state": "Uttar Pradesh",
    "pincode": "801244"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "115116"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800687"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800681"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800684"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800689"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "115670"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800679"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800683"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800682"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800688"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "115312"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800686"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800685"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800678"
  },
  {
    "district": "Moradabad",
    "state": "Uttar Pradesh",
    "pincode": "800680"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800644"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800646"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800658"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800654"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800651"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800647"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800649"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800657"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800641"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800642"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800643"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800656"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800659"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800652"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800650"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800655"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800645"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800653"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800648"
  },
  {
    "district": "Muzzaffarnagar",
    "state": "Uttar Pradesh",
    "pincode": "800640"
  },
  {
    "district": "Pilibhit",
    "state": "Uttar Pradesh",
    "pincode": "800878"
  },
  {
    "district": "Pilibhit",
    "state": "Uttar Pradesh",
    "pincode": "800880"
  },
  {
    "district": "Pilibhit",
    "state": "Uttar Pradesh",
    "pincode": "800879"
  },
  {
    "district": "Pilibhit",
    "state": "Uttar Pradesh",
    "pincode": "800874"
  },
  {
    "district": "Pilibhit",
    "state": "Uttar Pradesh",
    "pincode": "800876"
  },
  {
    "district": "Pilibhit",
    "state": "Uttar Pradesh",
    "pincode": "800881"
  },
  {
    "district": "Pilibhit",
    "state": "Uttar Pradesh",
    "pincode": "800875"
  },
  {
    "district": "Pilibhit",
    "state": "Uttar Pradesh",
    "pincode": "800877"
  },
  {
    "district": "Pilibhit",
    "state": "Uttar Pradesh",
    "pincode": "800882"
  },
  {
    "district": "Pratapgarh",
    "state": "Uttar Pradesh",
    "pincode": "801070"
  },
  {
    "district": "Pratapgarh",
    "state": "Uttar Pradesh",
    "pincode": "801071"
  },
  {
    "district": "Pratapgarh",
    "state": "Uttar Pradesh",
    "pincode": "801073"
  },
  {
    "district": "Pratapgarh",
    "state": "Uttar Pradesh",
    "pincode": "801069"
  },
  {
    "district": "Pratapgarh",
    "state": "Uttar Pradesh",
    "pincode": "900044"
  },
  {
    "district": "Pratapgarh",
    "state": "Uttar Pradesh",
    "pincode": "801068"
  },
  {
    "district": "Pratapgarh",
    "state": "Uttar Pradesh",
    "pincode": "801074"
  },
  {
    "district": "Pratapgarh",
    "state": "Uttar Pradesh",
    "pincode": "801072"
  },
  {
    "district": "Rae Bareli",
    "state": "Uttar Pradesh",
    "pincode": "800956"
  },
  {
    "district": "Rae Bareli",
    "state": "Uttar Pradesh",
    "pincode": "800961"
  },
  {
    "district": "Rae Bareli",
    "state": "Uttar Pradesh",
    "pincode": "800958"
  },
  {
    "district": "Rae Bareli",
    "state": "Uttar Pradesh",
    "pincode": "800960"
  },
  {
    "district": "Rae Bareli",
    "state": "Uttar Pradesh",
    "pincode": "800957"
  },
  {
    "district": "Rae Bareli",
    "state": "Uttar Pradesh",
    "pincode": "145372"
  },
  {
    "district": "Rae Bareli",
    "state": "Uttar Pradesh",
    "pincode": "800963"
  },
  {
    "district": "Rae Bareli",
    "state": "Uttar Pradesh",
    "pincode": "800959"
  },
  {
    "district": "Rae Bareli",
    "state": "Uttar Pradesh",
    "pincode": "800964"
  },
  {
    "district": "Rae Bareli",
    "state": "Uttar Pradesh",
    "pincode": "800962"
  },
  {
    "district": "Rampur",
    "state": "Uttar Pradesh",
    "pincode": "800693"
  },
  {
    "district": "Rampur",
    "state": "Uttar Pradesh",
    "pincode": "800694"
  },
  {
    "district": "Rampur",
    "state": "Uttar Pradesh",
    "pincode": "800690"
  },
  {
    "district": "Rampur",
    "state": "Uttar Pradesh",
    "pincode": "800697"
  },
  {
    "district": "Rampur",
    "state": "Uttar Pradesh",
    "pincode": "800695"
  },
  {
    "district": "Rampur",
    "state": "Uttar Pradesh",
    "pincode": "800696"
  },
  {
    "district": "Rampur",
    "state": "Uttar Pradesh",
    "pincode": "800691"
  },
  {
    "district": "Rampur",
    "state": "Uttar Pradesh",
    "pincode": "800692"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800634"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800629"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800631"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800637"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800635"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800633"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800638"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800639"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800630"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800632"
  },
  {
    "district": "Saharanpur",
    "state": "Uttar Pradesh",
    "pincode": "800636"
  },
  {
    "district": "Sant Kabir Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801151"
  },
  {
    "district": "Sant Kabir Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801149"
  },
  {
    "district": "Sant Kabir Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801150"
  },
  {
    "district": "Sant Kabir Nagar",
    "state": "Uttar Pradesh",
    "pincode": "801148"
  },
  {
    "district": "Shahjahanpur",
    "state": "Uttar Pradesh",
    "pincode": "800893"
  },
  {
    "district": "Shahjahanpur",
    "state": "Uttar Pradesh",
    "pincode": "800892"
  },
  {
    "district": "Shahjahanpur",
    "state": "Uttar Pradesh",
    "pincode": "800891"
  },
  {
    "district": "Shahjahanpur",
    "state": "Uttar Pradesh",
    "pincode": "800886"
  },
  {
    "district": "Shahjahanpur",
    "state": "Uttar Pradesh",
    "pincode": "800885"
  },
  {
    "district": "Shahjahanpur",
    "state": "Uttar Pradesh",
    "pincode": "800883"
  },
  {
    "district": "Shahjahanpur",
    "state": "Uttar Pradesh",
    "pincode": "800884"
  },
  {
    "district": "Shahjahanpur",
    "state": "Uttar Pradesh",
    "pincode": "800888"
  },
  {
    "district": "Shahjahanpur",
    "state": "Uttar Pradesh",
    "pincode": "800889"
  },
  {
    "district": "Shahjahanpur",
    "state": "Uttar Pradesh",
    "pincode": "800887"
  },
  {
    "district": "Shrawasti",
    "state": "Uttar Pradesh",
    "pincode": "801127"
  },
  {
    "district": "Shrawasti",
    "state": "Uttar Pradesh",
    "pincode": "801128"
  },
  {
    "district": "Siddharthnagar",
    "state": "Uttar Pradesh",
    "pincode": "801143"
  },
  {
    "district": "Siddharthnagar",
    "state": "Uttar Pradesh",
    "pincode": "801139"
  },
  {
    "district": "Siddharthnagar",
    "state": "Uttar Pradesh",
    "pincode": "801144"
  },
  {
    "district": "Siddharthnagar",
    "state": "Uttar Pradesh",
    "pincode": "801140"
  },
  {
    "district": "Siddharthnagar",
    "state": "Uttar Pradesh",
    "pincode": "801141"
  },
  {
    "district": "Siddharthnagar",
    "state": "Uttar Pradesh",
    "pincode": "801142"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800911"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800908"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800906"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800909"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800912"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800904"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800905"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800913"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800914"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800907"
  },
  {
    "district": "Sitapur",
    "state": "Uttar Pradesh",
    "pincode": "800910"
  },
  {
    "district": "Sonbhadra",
    "state": "Uttar Pradesh",
    "pincode": "801251"
  },
  {
    "district": "Sonbhadra",
    "state": "Uttar Pradesh",
    "pincode": "801250"
  },
  {
    "district": "Sonbhadra",
    "state": "Uttar Pradesh",
    "pincode": "801253"
  },
  {
    "district": "Sonbhadra",
    "state": "Uttar Pradesh",
    "pincode": "801248"
  },
  {
    "district": "Sonbhadra",
    "state": "Uttar Pradesh",
    "pincode": "214045"
  },
  {
    "district": "Sonbhadra",
    "state": "Uttar Pradesh",
    "pincode": "801252"
  },
  {
    "district": "Sonbhadra",
    "state": "Uttar Pradesh",
    "pincode": "801257"
  },
  {
    "district": "Sonbhadra",
    "state": "Uttar Pradesh",
    "pincode": "801254"
  },
  {
    "district": "Sonbhadra",
    "state": "Uttar Pradesh",
    "pincode": "900048"
  },
  {
    "district": "Sultanpur",
    "state": "Uttar Pradesh",
    "pincode": "801118"
  },
  {
    "district": "Sultanpur",
    "state": "Uttar Pradesh",
    "pincode": "801121"
  },
  {
    "district": "Sultanpur",
    "state": "Uttar Pradesh",
    "pincode": "801122"
  },
  {
    "district": "Sultanpur",
    "state": "Uttar Pradesh",
    "pincode": "801120"
  },
  {
    "district": "Sultanpur",
    "state": "Uttar Pradesh",
    "pincode": "801117"
  },
  {
    "district": "Sultanpur",
    "state": "Uttar Pradesh",
    "pincode": "801119"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800934"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800929"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800945"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800944"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800930"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800941"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800928"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800935"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800933"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800943"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800937"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800939"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800938"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800942"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800936"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800932"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800931"
  },
  {
    "district": "Unnao",
    "state": "Uttar Pradesh",
    "pincode": "800940"
  },
  {
    "district": "Varanasi",
    "state": "Uttar Pradesh",
    "pincode": "801232"
  },
  {
    "district": "Varanasi",
    "state": "Uttar Pradesh",
    "pincode": "801236"
  },
  {
    "district": "Varanasi",
    "state": "Uttar Pradesh",
    "pincode": "801235"
  },
  {
    "district": "Almora",
    "state": "Uttarakhand",
    "pincode": "800327"
  },
  {
    "district": "Almora",
    "state": "Uttarakhand",
    "pincode": "51688"
  },
  {
    "district": "Almora",
    "state": "Uttarakhand",
    "pincode": "800325"
  },
  {
    "district": "Almora",
    "state": "Uttarakhand",
    "pincode": "800324"
  },
  {
    "district": "Bageshwar",
    "state": "Uttarakhand",
    "pincode": "800323"
  },
  {
    "district": "Bageshwar",
    "state": "Uttarakhand",
    "pincode": "53238"
  },
  {
    "district": "Chamoli",
    "state": "Uttarakhand",
    "pincode": "800290"
  },
  {
    "district": "Chamoli",
    "state": "Uttarakhand",
    "pincode": "800292"
  },
  {
    "district": "Chamoli",
    "state": "Uttarakhand",
    "pincode": "41930"
  },
  {
    "district": "Chamoli",
    "state": "Uttarakhand",
    "pincode": "800294"
  },
  {
    "district": "Chamoli",
    "state": "Uttarakhand",
    "pincode": "800291"
  },
  {
    "district": "Chamoli",
    "state": "Uttarakhand",
    "pincode": "800295"
  },
  {
    "district": "Chamoli",
    "state": "Uttarakhand",
    "pincode": "800293"
  },
  {
    "district": "Chamoli",
    "state": "Uttarakhand",
    "pincode": "900041"
  },
  {
    "district": "Chamoli",
    "state": "Uttarakhand",
    "pincode": "40272"
  },
  {
    "district": "Chamoli",
    "state": "Uttarakhand",
    "pincode": "41693"
  },
  {
    "district": "Champawat",
    "state": "Uttarakhand",
    "pincode": "54471"
  },
  {
    "district": "Champawat",
    "state": "Uttarakhand",
    "pincode": "800328"
  },
  {
    "district": "Champawat",
    "state": "Uttarakhand",
    "pincode": "800329"
  },
  {
    "district": "Champawat",
    "state": "Uttarakhand",
    "pincode": "800330"
  },
  {
    "district": "Dehradun",
    "state": "Uttarakhand",
    "pincode": "800309"
  },
  {
    "district": "Dehradun",
    "state": "Uttarakhand",
    "pincode": "800312"
  },
  {
    "district": "Dehradun",
    "state": "Uttarakhand",
    "pincode": "800306"
  },
  {
    "district": "Dehradun",
    "state": "Uttarakhand",
    "pincode": "800307"
  },
  {
    "district": "Dehradun",
    "state": "Uttarakhand",
    "pincode": "800313"
  },
  {
    "district": "Dehradun",
    "state": "Uttarakhand",
    "pincode": "900020"
  },
  {
    "district": "Dehradun",
    "state": "Uttarakhand",
    "pincode": "800305"
  },
  {
    "district": "Garhwal",
    "state": "Uttarakhand",
    "pincode": "800318"
  },
  {
    "district": "Garhwal",
    "state": "Uttarakhand",
    "pincode": "47267"
  },
  {
    "district": "Garhwal",
    "state": "Uttarakhand",
    "pincode": "48841"
  },
  {
    "district": "Garhwal",
    "state": "Uttarakhand",
    "pincode": "800319"
  },
  {
    "district": "Garhwal",
    "state": "Uttarakhand",
    "pincode": "45917"
  },
  {
    "district": "Garhwal",
    "state": "Uttarakhand",
    "pincode": "800316"
  },
  {
    "district": "Garhwal",
    "state": "Uttarakhand",
    "pincode": "47764"
  },
  {
    "district": "Garhwal",
    "state": "Uttarakhand",
    "pincode": "800315"
  },
  {
    "district": "Hardwar",
    "state": "Uttarakhand",
    "pincode": "56628"
  },
  {
    "district": "Hardwar",
    "state": "Uttarakhand",
    "pincode": "800359"
  },
  {
    "district": "Hardwar",
    "state": "Uttarakhand",
    "pincode": "800355"
  },
  {
    "district": "Hardwar",
    "state": "Uttarakhand",
    "pincode": "800360"
  },
  {
    "district": "Hardwar",
    "state": "Uttarakhand",
    "pincode": "800357"
  },
  {
    "district": "Hardwar",
    "state": "Uttarakhand",
    "pincode": "800356"
  },
  {
    "district": "Hardwar",
    "state": "Uttarakhand",
    "pincode": "56630"
  },
  {
    "district": "Hardwar",
    "state": "Uttarakhand",
    "pincode": "800353"
  },
  {
    "district": "Hardwar",
    "state": "Uttarakhand",
    "pincode": "900021"
  },
  {
    "district": "Nainital",
    "state": "Uttarakhand",
    "pincode": "800334"
  },
  {
    "district": "Nainital",
    "state": "Uttarakhand",
    "pincode": "800333"
  },
  {
    "district": "Nainital",
    "state": "Uttarakhand",
    "pincode": "800335"
  },
  {
    "district": "Nainital",
    "state": "Uttarakhand",
    "pincode": "800337"
  },
  {
    "district": "Nainital",
    "state": "Uttarakhand",
    "pincode": "800338"
  },
  {
    "district": "Nainital",
    "state": "Uttarakhand",
    "pincode": "800331"
  },
  {
    "district": "Nainital",
    "state": "Uttarakhand",
    "pincode": "800336"
  },
  {
    "district": "Pithoragarh",
    "state": "Uttarakhand",
    "pincode": "49824"
  },
  {
    "district": "Pithoragarh",
    "state": "Uttarakhand",
    "pincode": "800320"
  },
  {
    "district": "Pithoragarh",
    "state": "Uttarakhand",
    "pincode": "800321"
  },
  {
    "district": "Pithoragarh",
    "state": "Uttarakhand",
    "pincode": "900018"
  },
  {
    "district": "Pithoragarh",
    "state": "Uttarakhand",
    "pincode": "800322"
  },
  {
    "district": "Rudraprayag",
    "state": "Uttarakhand",
    "pincode": "42598"
  },
  {
    "district": "Rudraprayag",
    "state": "Uttarakhand",
    "pincode": "800299"
  },
  {
    "district": "Rudraprayag",
    "state": "Uttarakhand",
    "pincode": "800296"
  },
  {
    "district": "Rudraprayag",
    "state": "Uttarakhand",
    "pincode": "800297"
  },
  {
    "district": "Rudraprayag",
    "state": "Uttarakhand",
    "pincode": "900042"
  },
  {
    "district": "Rudraprayag",
    "state": "Uttarakhand",
    "pincode": "42138"
  },
  {
    "district": "Tehri Garhwal",
    "state": "Uttarakhand",
    "pincode": "800301"
  },
  {
    "district": "Tehri Garhwal",
    "state": "Uttarakhand",
    "pincode": "900043"
  },
  {
    "district": "Tehri Garhwal",
    "state": "Uttarakhand",
    "pincode": "900039"
  },
  {
    "district": "Tehri Garhwal",
    "state": "Uttarakhand",
    "pincode": "800298"
  },
  {
    "district": "Tehri Garhwal",
    "state": "Uttarakhand",
    "pincode": "800303"
  },
  {
    "district": "Tehri Garhwal",
    "state": "Uttarakhand",
    "pincode": "800302"
  },
  {
    "district": "Tehri Garhwal",
    "state": "Uttarakhand",
    "pincode": "800300"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800344"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800349"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800348"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "900040"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800342"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800339"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800345"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800352"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800347"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800341"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800340"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "56191"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800346"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800350"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800351"
  },
  {
    "district": "Udham Singh Nagar",
    "state": "Uttarakhand",
    "pincode": "800343"
  },
  {
    "district": "Uttarkashi",
    "state": "Uttarakhand",
    "pincode": "800287"
  },
  {
    "district": "Uttarkashi",
    "state": "Uttarakhand",
    "pincode": "40659"
  },
  {
    "district": "Uttarkashi",
    "state": "Uttarakhand",
    "pincode": "800289"
  },
  {
    "district": "Uttarkashi",
    "state": "Uttarakhand",
    "pincode": "40445"
  },
  {
    "district": "Uttarkashi",
    "state": "Uttarakhand",
    "pincode": "40139"
  },
  {
    "district": "Uttarkashi",
    "state": "Uttarakhand",
    "pincode": "800288"
  },
  {
    "district": "Bankura",
    "state": "West Bengal",
    "pincode": "801733"
  },
  {
    "district": "Bankura",
    "state": "West Bengal",
    "pincode": "801735"
  },
  {
    "district": "Bankura",
    "state": "West Bengal",
    "pincode": "801734"
  },
  {
    "district": "Barddhaman",
    "state": "West Bengal",
    "pincode": "801671"
  },
  {
    "district": "Barddhaman",
    "state": "West Bengal",
    "pincode": "801678"
  },
  {
    "district": "Barddhaman",
    "state": "West Bengal",
    "pincode": "801676"
  },
  {
    "district": "Barddhaman",
    "state": "West Bengal",
    "pincode": "801674"
  },
  {
    "district": "Barddhaman",
    "state": "West Bengal",
    "pincode": "801677"
  },
  {
    "district": "Barddhaman",
    "state": "West Bengal",
    "pincode": "801679"
  },
  {
    "district": "Barddhaman",
    "state": "West Bengal",
    "pincode": "801675"
  },
  {
    "district": "Barddhaman",
    "state": "West Bengal",
    "pincode": "801680"
  },
  {
    "district": "Birbhum",
    "state": "West Bengal",
    "pincode": "801669"
  },
  {
    "district": "Birbhum",
    "state": "West Bengal",
    "pincode": "801668"
  },
  {
    "district": "Birbhum",
    "state": "West Bengal",
    "pincode": "801664"
  },
  {
    "district": "Birbhum",
    "state": "West Bengal",
    "pincode": "801665"
  },
  {
    "district": "Birbhum",
    "state": "West Bengal",
    "pincode": "801667"
  },
  {
    "district": "Birbhum",
    "state": "West Bengal",
    "pincode": "801666"
  },
  {
    "district": "Dakshin Dinajpur",
    "state": "West Bengal",
    "pincode": "801654"
  },
  {
    "district": "Dakshin Dinajpur",
    "state": "West Bengal",
    "pincode": "311795"
  },
  {
    "district": "Dakshin Dinajpur",
    "state": "West Bengal",
    "pincode": "801653"
  },
  {
    "district": "Darjiling",
    "state": "West Bengal",
    "pincode": "801634"
  },
  {
    "district": "Darjiling",
    "state": "West Bengal",
    "pincode": "801635"
  },
  {
    "district": "Darjiling",
    "state": "West Bengal",
    "pincode": "801637"
  },
  {
    "district": "Darjiling",
    "state": "West Bengal",
    "pincode": "801636"
  },
  {
    "district": "Haora",
    "state": "West Bengal",
    "pincode": "801740"
  },
  {
    "district": "Haora",
    "state": "West Bengal",
    "pincode": "801741"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801724"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801727"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801720"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801725"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801726"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801722"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801731"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801721"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801730"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801729"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801728"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801723"
  },
  {
    "district": "Hugli",
    "state": "West Bengal",
    "pincode": "801732"
  },
  {
    "district": "Jalpaiguri",
    "state": "West Bengal",
    "pincode": "801642"
  },
  {
    "district": "Jalpaiguri",
    "state": "West Bengal",
    "pincode": "801641"
  },
  {
    "district": "Jalpaiguri",
    "state": "West Bengal",
    "pincode": "801640"
  },
  {
    "district": "Jalpaiguri",
    "state": "West Bengal",
    "pincode": "801639"
  },
  {
    "district": "Koch Bihar",
    "state": "West Bengal",
    "pincode": "801648"
  },
  {
    "district": "Koch Bihar",
    "state": "West Bengal",
    "pincode": "801643"
  },
  {
    "district": "Koch Bihar",
    "state": "West Bengal",
    "pincode": "801646"
  },
  {
    "district": "Koch Bihar",
    "state": "West Bengal",
    "pincode": "801645"
  },
  {
    "district": "Koch Bihar",
    "state": "West Bengal",
    "pincode": "801644"
  },
  {
    "district": "Koch Bihar",
    "state": "West Bengal",
    "pincode": "801647"
  },
  {
    "district": "Kolkata",
    "state": "West Bengal",
    "pincode": "801742"
  },
  {
    "district": "Kolkata",
    "state": "West Bengal",
    "pincode": "801638"
  },
  {
    "district": "Maldah",
    "state": "West Bengal",
    "pincode": "801656"
  },
  {
    "district": "Maldah",
    "state": "West Bengal",
    "pincode": "801655"
  },
  {
    "district": "Murshidabad",
    "state": "West Bengal",
    "pincode": "801663"
  },
  {
    "district": "Murshidabad",
    "state": "West Bengal",
    "pincode": "801662"
  },
  {
    "district": "Murshidabad",
    "state": "West Bengal",
    "pincode": "801657"
  },
  {
    "district": "Murshidabad",
    "state": "West Bengal",
    "pincode": "314695"
  },
  {
    "district": "Murshidabad",
    "state": "West Bengal",
    "pincode": "801658"
  },
  {
    "district": "Murshidabad",
    "state": "West Bengal",
    "pincode": "801659"
  },
  {
    "district": "Murshidabad",
    "state": "West Bengal",
    "pincode": "801661"
  },
  {
    "district": "Murshidabad",
    "state": "West Bengal",
    "pincode": "801660"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "801685"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "801688"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "801687"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "801690"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "322410"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "801689"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "801682"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "801681"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "801686"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "801683"
  },
  {
    "district": "Nadia",
    "state": "West Bengal",
    "pincode": "801684"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801698"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801706"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801712"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801707"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801702"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801718"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801695"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801716"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801691"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801713"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801699"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801696"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801697"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801692"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801711"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801693"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801704"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801708"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801694"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801709"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801700"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801710"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801705"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801714"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801719"
  },
  {
    "district": "North Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801703"
  },
  {
    "district": "Paschim Medinipur",
    "state": "West Bengal",
    "pincode": "801752"
  },
  {
    "district": "Paschim Medinipur",
    "state": "West Bengal",
    "pincode": "801754"
  },
  {
    "district": "Paschim Medinipur",
    "state": "West Bengal",
    "pincode": "801756"
  },
  {
    "district": "Paschim Medinipur",
    "state": "West Bengal",
    "pincode": "801757"
  },
  {
    "district": "Paschim Medinipur",
    "state": "West Bengal",
    "pincode": "801753"
  },
  {
    "district": "Paschim Medinipur",
    "state": "West Bengal",
    "pincode": "801751"
  },
  {
    "district": "Paschim Medinipur",
    "state": "West Bengal",
    "pincode": "801755"
  },
  {
    "district": "Paschim Medinipur",
    "state": "West Bengal",
    "pincode": "801750"
  },
  {
    "district": "Purba Medinipur",
    "state": "West Bengal",
    "pincode": "801762"
  },
  {
    "district": "Purba Medinipur",
    "state": "West Bengal",
    "pincode": "801761"
  },
  {
    "district": "Purba Medinipur",
    "state": "West Bengal",
    "pincode": "801760"
  },
  {
    "district": "Purba Medinipur",
    "state": "West Bengal",
    "pincode": "801758"
  },
  {
    "district": "Purba Medinipur",
    "state": "West Bengal",
    "pincode": "801759"
  },
  {
    "district": "Puruliya",
    "state": "West Bengal",
    "pincode": "801736"
  },
  {
    "district": "Puruliya",
    "state": "West Bengal",
    "pincode": "801738"
  },
  {
    "district": "Puruliya",
    "state": "West Bengal",
    "pincode": "801737"
  },
  {
    "district": "South Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801747"
  },
  {
    "district": "South Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801744"
  },
  {
    "district": "South Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801748"
  },
  {
    "district": "South Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801749"
  },
  {
    "district": "South Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801743"
  },
  {
    "district": "South Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801745"
  },
  {
    "district": "South Twenty Four Parganas",
    "state": "West Bengal",
    "pincode": "801746"
  },
  {
    "district": "Uttar Dinajpur",
    "state": "West Bengal",
    "pincode": "801650"
  },
  {
    "district": "Uttar Dinajpur",
    "state": "West Bengal",
    "pincode": "801649"
  },
  {
    "district": "Uttar Dinajpur",
    "state": "West Bengal",
    "pincode": "801652"
  },
  {
    "district": "Uttar Dinajpur",
    "state": "West Bengal",
    "pincode": "801651"
  }
 ]